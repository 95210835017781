import Cookie from '../base/cookie.js';
import Spinner from './spinner.js';
import { mapTierCode } from '../../base/utils.js';

class MemberLevelTracker {
  constructor() {
    if ($('.member-level-tracking-component').length > 0) {
      this.onUserUpdate();
      if (Cookie.isAuthenticated()) {
        Spinner.appendTo($('.member-level-tracking-component'));
      }
    }
  }

  onUserUpdate() {
    $(document).on('user:profile:updated', () => {
      if (window.User && Cookie.isAuthenticated()) {
        const tier = window.User.currentTier.description || mapTierCode(window.User.currentTier.tierCode);
        let level = tier.toLowerCase(),
          nightsNeeded = window.User.earningTier ? (window.User.earningTier.requiredAmount - window.User.earningTier.accruedAmount) : 0;
        this.setMemberLevelTracker(level, nightsNeeded);
      }
    });
  }

  setMemberLevelTracker(level, nights) {
    let $memberLevelTracker = $('.member-level-tracking-component'),
      $levelsContainer = $memberLevelTracker.find('.levels-tracker-container'),
      $trackerContainer = $memberLevelTracker.find('.tracker-view');

      $trackerContainer.hide();
      
    $memberLevelTracker.find('.active, .completed').removeClass('active completed');

    if (level !== 'titanium' && level !== 'diamond' && nights > 0) {
      $levelsContainer.addClass(level);
      $memberLevelTracker.find('.arrows-' + level).addClass('active')
        .find('.level-arrow-container:nth-last-child(n+' + (nights == 0 ? 1 : nights).toString() + ')')
        .addClass('completed');
    } else if (level === 'diamond' || level === 'titanium') {
      $trackerContainer.hide();
    } else if (nights == 0) {
      $memberLevelTracker.hide();
    }
    Spinner.remove($memberLevelTracker);
    $memberLevelTracker.find('.hidden:not(.accelerator-message)').removeClass('hidden');
  }

  showAcceleratorMessage(userProfileAccelerator) {
    let $memberLevelTracker = $('.member-level-tracking-component'),
      $acceleratorMessage = $memberLevelTracker.find('.accelerator-message'),
      authoredAcceleratorsContent = window.memberLvlTrackerAccelerators || [], // coming from component authoring
      acceleratorContent;

    // Look for Accelerators authored content
    if (authoredAcceleratorsContent.length) {

      // Search authored content matches user profile accelerator
      authoredAcceleratorsContent.forEach((e) => {
        if (e.acceleratorCode === userProfileAccelerator) {
          acceleratorContent = e;
          return;
        }
      });

      if (acceleratorContent) {
        let $imgContainer = $acceleratorMessage.find('.img-container'),
          $msgContainer = $acceleratorMessage.find('.message-container');

        // Accelerator content refresh
        $imgContainer.find('source').attr('srcset', acceleratorContent.iconPath);
        $imgContainer.find('img').attr('src', acceleratorContent.iconPath);
        $imgContainer.find('img').attr('alt', acceleratorContent.iconAlt);
        $msgContainer.html(acceleratorContent.accMessage);
      }
    }
  }
}

export default new MemberLevelTracker();
