import { scrollToElement } from '../base/animate.js';
import Form from '../base/form.js';
import Spinner from './spinner.js';
import {
  requestUrls,
  templateUrls
} from '../../base/vars.js';
import { UserHandler } from '../../base/session-handler.js';

class Logout {
  constructor() {
    // deprecated from trec -> okta
    // this.onLogoutClick();
  }
  logout() {
    if (requestUrls.logout) {
      $.ajax({
        url: requestUrls.logout,
        type: 'POST',
        contentType: 'application/json',
        dataType: 'json',
        success: (response) => {
          // No cookie deletion needed
          // Complete function will handle redirect
        },
        error: () => {
          console.log('logout error');
        },
        complete: () => {
          scrollToElement('html');
          if ($('.page').hasClass('requireAuth')) {
            window.location.assign(templateUrls.homePage);
          } else {
            $('form.fv-form').each((i, el) => Form.reset($(el)));
            window.location.reload();
          }
          UserHandler.logout();
        }
      });
    }
  }
  onLogoutClick() {
    $(document).on('click', '.sign-out-button, a[href*="#logout"]', (e) => {
      e.preventDefault();
      if ($(e.currentTarget).hasClass('sign-out-button')) {
        Spinner.appendTo($('.desktop-nav .account-dropdown-container .mm-right-col'));
        Spinner.appendTo($('.mobile-nav .dropdown[data-dropdown="account"]'));
        Form.disableFormField($(e.currentTarget));
      }
      this.logout();
    });
  }
}

export default new Logout();
