import { EventHandler, formatNumber } from '../../base/utils.js';
import Form from '../base/form.js';
import Spinner from './spinner.js';
import UserHandler from '../base/user.js';
import { requestUrls } from '../../base/vars.js';

class TransferPoints {
  constructor() {
    if ($('#caesarsTransferPoints').length > 0) {
      this.$form = $('#caesarsTransferPoints');
      this.$memberField = $('#caesarsMemberNumber');
      this.isCaesars = true;
      const partnerId = this.$form.data('claim-type') || 'CET-REWARDS';
      this.getPartners(partnerId);
      $('#pointsSelect').hide();
    } else if ($('#loyaltyPointTransfer').length > 0) {
      this.$form = $('#loyaltyPointTransfer');
      this.$memberField = $('#accountNumber');
      this.isCaesars = false;
      this.getPartners(this.$form.data('claim-type'));
    }
  }

  getPartners(targetPartner) {
    Spinner.appendTo(this.$form);
    if (requestUrls.loyaltyPartners) {
      $.ajax({
        url: requestUrls.loyaltyPartners,
        context: {
          onFail: {
            removeSpinnerFrom: this.$form
          }
        },
        success: (response) => {
          Spinner.remove(this.$form);
          if (response.data) {
            this.formValidation();
            const partnerObj = this.getPartnerObj(response.data, targetPartner);
            if (partnerObj) {
              this.setValues(partnerObj);
              this.changePointsValue();
            } else {
              console.error('Could not find matching redemptionItemCode for Transfer point.');
            }
          }
        }
      });
    } else {
      Spinner.remove(this.$form);
    }
  }
  formValidation() {
    let $firstNameError = $($('#firstName').parent()
        .find('.help-block')[0]),
      firstNameMessage = $firstNameError.text(),
      $lastNameError = $($('#lastName').parent()
        .find('.help-block')[0]),
      lastNameMessage = $lastNameError.text(),
      $memberNumberError = $(this.$memberField.parent().find('.help-block')[0]),
      memberNumberMessage = $memberNumberError.text(),
      $emailError = $($('#emailAddress').parent()
        .find('.help-block')[0]),
      emailErrorMessage = $emailError.text();

    $firstNameError.remove();
    $lastNameError.remove();
    $memberNumberError.remove();
    $emailError.remove();

    this.$form.formValidation({
      button: {
        selector: '.submit',
        disabled: ''
      },
      live: 'submitted',
      fields: {
        'firstName': {
          validators: {
            notEmpty: {
              message: firstNameMessage
            }
          }
        },
        'lastName': {
          validators: {
            notEmpty: {
              message: lastNameMessage
            }
          }
        },
        'caesarsMemberNumber': {
          validators: {
            notEmpty: {
              message: memberNumberMessage
            }
          }
        },
        'accountNumber': {
          validators: {
            notEmpty: {
              message: memberNumberMessage
            }
          }
        },
        'quantity': {
          validators: {
            notEmpty: {
              message: ''
            }
          }
        },
        'emailAddress': {
          validators: {
            regexp: {
              regexp: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
            },
            notEmpty: {
              enabled: true,
              message: emailErrorMessage
            },
            stringLength: {
              min: 6,
              max: 300
            }
          }
        }
      }
    })
      .on('success.form.fv', (e, data) => {
        e.preventDefault();
        this.postForm($(e.target));
      });
  }
  getPartnerObj(response, targetPartner) {
    return response[response.findIndex(obj => obj.redemptionItemCode === targetPartner)];
  }
  setValues(obj) {
    if (obj) {
      this.$form.data('partner-id', obj.redemptionItemCode);
      this.$form.find('#totalQuantity').data({
        'points': obj.currencyRequired,
        'miles': obj.faceValue
      });
      this.calculateTotalQuantity();
    }
  }
  changePointsValue() {
    $(document).on('change', '#quantitySelect', (e) => {
      this.calculateTotalQuantity();
    });
  }
  calculateTotalQuantity() {
    let value = parseFloat(this.$form.find('#quantitySelect select').val()) * parseFloat(this.$form.find('#totalQuantity').data('points')),
      miles = parseFloat(this.$form.find('#quantitySelect select').val()) * parseFloat(this.$form.find('#totalQuantity').data('miles'));
    $('#totalQuantity .value').eq(0)
      .text(value.toLocaleString());
    $('#totalQuantity .value').eq(1)
      .text(miles.toLocaleString());
  }
  getData() {
    const memberIdentifier = window.User.accountNumber || null;
    return JSON.stringify({
      'memberIdentifier': memberIdentifier,
      'redemptionItemCode': this.$form.data('partner-id'),
      'quantity': this.$form.find('#quantitySelect select').val(),
      'partnerAccountNumber': this.$memberField.val()
    });
  }
  postForm() {
    let data = this.getData();
    Form.disableFormField(this.$form.find('.submit'));
    Spinner.appendTo(this.$form);
    if (requestUrls.partnerRedeem) {
      $.ajax({
        type: 'POST',
        contentType: 'application/json',
        url: requestUrls.partnerRedeem,
        data: data,
        context: {
          onFail: {
            formElement: this.$form
          }
        },
        success: (response) => {
          Spinner.remove(this.$form);
          this.showConfirmation(response);
          // Generic event for non-Caesars. Have to check form type again
          if (this.isCaesars) {
            EventHandler.triggerEvent('caesars-transfer_success');
          } else {
            EventHandler.triggerEvent('points_transfer_success');
          }
        },
        error: (e) => {
          if (e.responseJSON && e.responseJSON.ErrorMessage) {
            const errorMessage = e.responseJSON.ErrorCode && e.responseJSON.ErrorCode === '0001' && e.responseJSON.InternalErrorMessage ? 
              e.responseJSON.InternalErrorMessage.replace('\"partnerAccountNumber\"', 'Partner Account Number') : e.responseJSON.ErrorMessage;
            
            setTimeout(() => {
              this.$form.find('.form-error.help-block').hide();
              this.$form.find('#form-error-message').html(errorMessage).show();
            }, 100);
          }
        }
      });
    } else {
      Spinner.remove(this.$form);
      Form.enableFormField(this.$form.find('.submit'));
    }
  }
  showConfirmation(response) {
    UserHandler.getProfile();
    $('.transferred-points').text(formatNumber(response.currencyValue));
    $(document).on('user:profile:updated', () => {
      Form.handleSuccessContainer();
      window.scrollTo(0, 0);
    });
  }
}

export default new TransferPoints();
