import Cookie from '../base/cookie.js';
import Form from '../base/form.js';
import Login from '../../components/login.js';
import Modal from './modal.js';
import Spinner from './spinner.js';
import UserHandler from '../base/user.js';
import { formatNumber, EventHandler, getJsonObject } from '../../base/utils.js';
import { requestUrls } from '../../base/vars.js';

class RegistrationContainer {
  constructor() {
    this.$form = $('.redemption-flow form');
    this.formType = null;
    if ($('.redemption-container').length > 0) {
      Spinner.appendTo('.redeemcontainer');
      this.formType = this.getFormType();
      this.getData();
      this.onSelectChange();
      this.onRedeemButtonClick();
      this.onModalHide();
      this.onCancelClick();
      this.onQuantityChange();
      switch (this.formType) {
        case 'airline':
          this.airlineFormInit();
          break;
        case 'maintenance':
          this.maintenanceFormInit();
          break;
      }
    }
  }
  // Reusable method to get form type from '-view' suffixed class in container
  getFormType() {
    let classList = $('.redemption-container').attr('class')
        .split(/\s+/),
      suffix = '-view',
      regexp = /-view$/,
      formType;
    $.each(classList, (index, className) => {
      if (regexp.test(className)) {
        formType = className.replace(suffix, '');
        return;
      }
    });
    return formType;
  }
  onRedeemButtonClick() {
    $(document).on('click', '.redeem-option-btn', (e) => {
      if (Cookie.isAuthenticated() === 'true') {
        $('#form-error-message').hide();
        $('#genericLightbox').addClass('redeem-option-modal');
        this.resetForm(this.$form);
        Form.removeFormError(this.$form);
        Modal.show();
        Modal.populateModalContent($('.redemption-flow'));
        this.populateRedemptionForm($(e.currentTarget));
      } else {
        Login.redirectToOktaLogin(null);
      }
    });
  }
  onSelectChange() {
    $('#partnerSelect').change((e) => {
      const data = $(e.currentTarget).val();
      if (data) {
        const button = $('.listings-container').find('#' + data + ' .redeem-option-btn');
        this.resetForm(this.$form);
        if (Cookie.isAuthenticated() === 'true') {
          this.populateRedemptionForm(button);
        } else {
          $('#partnerSelect').prop('selectedIndex', 0);
          Login.redirectToOktaLogin(null);
        }
      }
    });
  }
  onModalHide() {
    $('#genericLightbox').on('hide.bs.modal', (event) => {
      if ($(event.currentTarget).hasClass('redeem-option-modal')) {
        $('#genericLightbox').removeClass('redeem-option-modal');
        this.resetForm(this.$form);
        $('#partnerSelect').prop('selectedIndex', 0);
        $('.redemption-flow').hide();
        $('.mobile-redemption-container .component-wrapper').append($('#genericLightbox .redemption-flow'));
      }
    });
  }
  onCancelClick() {
    $(document).on('click', '.redemption-flow .cancel, .redeem-more-btn button', (e) => {
      e.stopPropagation();
      Modal.close();
      this.resetForm(this.$form);
      $('#partnerSelect').prop('selectedIndex', 0);
      $('.redemption-flow').hide();
    });
  }
  getData() {
    switch (this.formType) {
    case 'airline':
      this.getPartners();
      break;
    case 'maintenance':
      this.getMaintenance();
      break;
    }
  }
  getPartners() {
    if (requestUrls['loyaltyPartners']) {
      $.ajax({
        url: requestUrls['loyaltyPartners'],
        contentType: 'application/json',
        success: (response) => {
          if (response.data) {
            this.validatePartnerOptions(response.data,true);
          }
        }
      });
    } else {
      console.log('missing loyalPartners service url');
    }
  }
  getMaintenance() {
    if (requestUrls['loyaltyPartners']) {
      $.ajax({
        url: requestUrls['loyaltyPartners'],
        contentType: 'application/json',
        success: (response) => {
          if (response.data) {
            this.validatePartnerOptions(response.data,false);
          }
        }
      });
    } else {
      console.log('missing loyaltyPartners service url');
    }
  }
  // Hide Partner listings with invalid partner ID
  validatePartnerOptions(partners,isDropdown) {
    $('.redemption-option-listing').each((i, el) => {
      let $listing = $(el),
        $button = $listing.find('.redeem-option-btn'),
        listingId = $listing.attr('id'),
        partner = isDropdown ? 
          _.filter(partners, item => 
            item.redemptionItemCode.startsWith(listingId + '-') && item.redemptionItemCode !== listingId
          ) :
          _.find(partners, {
            redemptionItemCode: listingId
          });

      // Checking to make sure $button is actually a button element to ensure the listing
      // is authored correctly. If a button path is authored $button becomes an anchor element
      // which is for the earn miles container and causes issues here.
      if ($button.is('button') && isDropdown && partner.length > 1) {
        let currencies = [], miles = [], id = [];
        _.each(partner, (p) => {
          currencies.push(p.currencyRequired);
          id.push(p.redemptionItemCode);
          miles.push(p.faceValue);
        });
        $button.attr('data-currencies', currencies);
        $button.attr('data-partner-id', id);
        $button.attr('data-isdropdown', isDropdown);
        $button.attr('data-miles', miles);
      } else if ($button.is('button') && !isDropdown && partner) {
        $button.attr('data-currencies', partner.currencyRequired);
        $button.attr('data-partner-id', partner.redemptionItemCode);
        $button.attr('data-isdropdown', isDropdown);
        if (partner.faceValue) {
          $button.attr('data-miles', partner.faceValue);
        }
      } else {
        $listing.remove(); // Removing element so CSS nth-child alternates row colors correctly
        $('#partnerSelect').find('option[value="' + listingId + '"]')
          .remove();
      }
    });

    Spinner.remove('.redeemcontainer');
    $('.listings-container').show();
    $('.mobile-listing-container').show();
  }
  // Populate Form with Authored Listing data from button
  populateRedemptionForm(button) {
    $('.redemption-flow').show();

    let partnerName = button.data('id'),
      partnerId = button.data('partner-id'),
      mainHeadline = button.data('mainheadline') || '',
      subHeadline = button.data('subheadline') || '',
      disclaimer = button.data('disclaimer') || '',
      logoSrc = button.data('image') || '',
      pointsLabel = button.data('points-label') || '',
      pointsorderdesc = button.data('pointsorderdesc') || '',
      ownernumber,
      ownerfieldtext = $('.owner-number .owner-contact-text').data('owner-contact');

    if (button.data('ownernumber')) {
      ownernumber = button.data('ownernumber');
    } else if (ownerfieldtext) {
      ownernumber = ownerfieldtext;
    } else {
      ownernumber = '';
    }

    $('.redemption-form').data('partner-name', partnerName);
    $('.redemption-form').data('partner-id', partnerId);
    $('.redemption-form .logo-img').attr('src', logoSrc);
    $('.redemption-form .listing-subline').html(subHeadline);
    $('.redemption-form .main-headline').html(mainHeadline);
    $('.redemption-form .listing-form-disclaimer').html(disclaimer);
    $('.redemption-form .owner-contact-text').html(ownernumber);
    $('.redemption-confirmation .points-label').html(pointsLabel);
    $('.redemption-confirmation .points-order-disclaimer').html(pointsorderdesc);
    if(button.data('isdropdown')) {
      let miles = button && button.data('miles') || 0,
        minPoints = button && button.data('currencies') || 1,
        partnerIds = button && button.data('partner-id') || '';
      $('#totalQuantity').hide();
      minPoints = minPoints.toString().indexOf(',') > 0 ? minPoints.split(',') : JSON.parse('[' + minPoints + ']');
      miles = miles.toString().indexOf(',') > 0 ? miles.split(',') : JSON.parse('[' + miles + ']');
      partnerIds = partnerIds.toString().indexOf(',') > 0 ? partnerIds.split(',') : JSON.parse('[' + partnerIds + ']');
      var sel = $('#pointsSelect #points');
      sel.find('option').remove();
      $('.select-container').removeClass('empty');
      for (var a = 0; a < minPoints.length; a++) {
        sel.append($('<option />').val(minPoints[a])
          .attr('data-miles', miles[a])
          .attr('data-partner-id', partnerIds[a])
          .text(minPoints[a].toLocaleString() + ' ' + sel.data('unittext') + ' ' + miles[a].toLocaleString() + ' ' + sel.data('unittext2')));
      }
      this.updateFormPartnerId();
      $('#pointsSelect').show();
    } else {
      $('#pointsSelect').hide();
    }
    this.calculateTotalQuantity();
    EventHandler.triggerEvent('redeem-form', {
      partnerType: this.formType,
      partnerName: partnerName.toLowerCase()
    });
  }
  onQuantityChange() {
    $('#quantity').on('change', (e) => {
      this.calculateTotalQuantity();
    });
    $('#points').on('change', (e) => {
      this.calculateTotalQuantity();
      this.updateFormPartnerId();
    });
  }
  calculateTotalQuantity() {
    if ($('.redemption-form').data('partner-name')) {
      let button = $('.listings-container').find(`[id="${$('.redemption-form').data('partner-name')}"] .redeem-option-btn`),
        value = parseFloat($('#quantity').val()),
        miles = button && button.data('miles') || 0,
        minPoints = button && button.data('currencies') || 1,
        isDropdown = button.data('isdropdown');

      if (miles && isDropdown) {
        $('.totalMiles').text(($('#quantity option:selected').val() * $('#pointsSelect #points option:selected').data('miles')).toLocaleString());
        $('.totalMinPoints').text(($('#quantity option:selected').val() * $('#pointsSelect #points option:selected').val()).toLocaleString());
      } else {
        $('#totalQuantity').find('span')
          .show();
        $('#totalQuantity').find('.value')
          .eq(0)
          .text((minPoints * value).toLocaleString());
        $('#totalQuantity').find('span')
          .eq(2)
          .hide();
        $('#totalQuantity').find('span')
          .eq(3)
          .hide();
      }
    }
  }
  updateFormPartnerId() {
    let $form = $('.redemption-form');
    if ($form.data('partner-id')) {
      let sel = $form.find('#pointsSelect #points');
      if (sel.length) {
        let newPartnerId = sel.find('option:selected');
        if (newPartnerId.length) {
          $form.data('partner-id', newPartnerId.data('partner-id'));
        }
      }
    }
  }
  resetForm(form) {
    $(form).data('formValidation')
      .resetForm();
    $(form).trigger('reset');
    $(form).find('input')
      .change();
    $(form).data('formValidation')
      .resetField('acceptCheckbox', '');
    Form.removeFormError($(form));
    this.hideConfirmation();
  }
  airlineFormInit() {
    let $firstNameError = $($('#firstName').parent()
        .find('.help-block')[0]),
      firstNameMessage = $firstNameError.text(),
      $lastNameError = $($('#lastName').parent()
        .find('.help-block')[0]),
      lastNameMessage = $lastNameError.text(),
      $travelerNumberError = $($('#travelerNumber').parent()
        .find('.help-block')[0]),
      travelerNumberMessage = $travelerNumberError.text();

    $firstNameError.remove();
    $lastNameError.remove();
    $travelerNumberError.remove();

    this.$form.formValidation({
      button: {
        selector: '.submit-button',
        disabled: ''
      },
      live: 'submitted',
      fields: {
        'quantity': {
          validators: {
            notEmpty: {
              message: ''
            }
          }
        },
        'firstName': {
          validators: {
            notEmpty: {
              message: firstNameMessage
            }
          }
        },
        'lastName': {
          validators: {
            notEmpty: {
              message: lastNameMessage
            }
          }
        },
        'travelerNumber': {
          validators: {
            notEmpty: {
              message: travelerNumberMessage
            }
          }
        },
        'acceptCheckbox': {
          validators: {
            notEmpty: {
              message: ''
            }
          }
        }
      }
    })
      .on('success.form.fv', (e, data) => {
        e.preventDefault();
        this.postForm($(e.target));
      });
  }
  maintenanceFormInit() {
    let $ownerNumberError = $($('#ownerNumber').parent()
        .find('.help-block')[0]),
      ownerNumberMessage = $ownerNumberError.text();

    $ownerNumberError.remove();

    this.$form.formValidation({
      button: {
        selector: '.submit-button',
        disabled: ''
      },
      live: 'submitted',
      fields: {
        'quantity': {
          validators: {
            notEmpty: {
              message: ''
            }
          }
        },
        'ownerNumber': {
          validators: {
            notEmpty: {
              message: ownerNumberMessage
            }
          }
        },
        'acceptCheckbox': {
          validators: {
            notEmpty: {
              message: ''
            }
          }
        }
      }
    })
      .on('success.form.fv', (e, data) => {
        e.preventDefault();
        this.postForm($(e.target));
      });
  }
  postForm(form) {
    const currencyObj = window.User && window.User.currencies ? 
      window.User.currencies.find(item => item.typeCode === 'POINTS') : null;
    const currencies = currencyObj && currencyObj.available ? currencyObj.available : 0;
    const memberIdentifier = (window.User && window.User.accountNumber) ? window.User.accountNumber : null;
    let data = JSON.stringify({
        'memberIdentifier': memberIdentifier,
        'redemptionItemCode': $('.redemption-form').data('partner-id') + '',
        'quantity': $(form).find('#quantity').val(),
        'partnerAccountNumber': $(form).find('#travelerNumber')
          .val() || $(form).find('#ownerNumber')
          .val() || ''
      }),
      context = {
        pointsBalance: currencies,
        onFail: {
          formElement: this.$form
        }
      };
    Spinner.appendTo('.redemption-form');
    Form.disableFormField(this.$form.find('.submit-button'));
    Form.disableFormField(this.$form.find('.cancel'));
    $.ajax({
      url: requestUrls['partnerRedeem'],
      type: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      data: data,
      context: context,
      success: (responseData) => {
        const response = getJsonObject(responseData);
        this.showConfirmation(response, context);
        UserHandler.getProfile();
        EventHandler.triggerEvent('redeem-complete', {
          partnerType: this.formType,
          partnerName: $('.redemption-form').data('partner-name')
            .toLowerCase(),
          pointsRedeemed: response.totalPoints,
          accountNumber: JSON.parse(data)['accountNumber']
        });
      },
      error: (e) => {
        if (e.responseJSON && e.responseJSON.ErrorMessage) {
          const errorMessage = e.responseJSON.ErrorCode && e.responseJSON.ErrorCode === '0001' && e.responseJSON.InternalErrorMessage ? 
            e.responseJSON.InternalErrorMessage.replace('\"partnerAccountNumber\"', 'Partner Account Number') : e.responseJSON.ErrorMessage;
          
          setTimeout(() => {
            this.$form.find('.form-error.help-block').hide();
            this.$form.find('#form-error-message').html(errorMessage).show();
          }, 100);
        }
      },
      complete: () => {
        Form.enableFormField(this.$form.find('.cancel'));
        Form.enableFormField(this.$form.find('.submit-button'));
        Spinner.remove('.redemption-form');
      }
    });
  }
  showConfirmation(response, context) {
    let remainingPoints = parseFloat(context.pointsBalance) - parseFloat(response.currencyValue);
    $('.redemption-confirmation .order-number .value').html(DOMPurify.sanitize(response.memberRedemptionId));
    $('.redemption-confirmation .order-items .value').html(DOMPurify.sanitize(formatNumber(response.quantity)));
    $('.redemption-confirmation .points-redeemed .value').html(DOMPurify.sanitize(formatNumber(response.currencyValue)));
    $('.redemption-confirmation .points-balance .value').html(formatNumber(remainingPoints));
    $('.redemption-confirmation').show();
    $('.redemption-form').hide();
  }
  hideConfirmation() {
    $('.redemption-form').show();
    $('.redemption-confirmation').hide();
    this.$form.find('#form-error-message').html('').hide();
  }
}

export default new RegistrationContainer();
