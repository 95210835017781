import ConfigsUtils from '../../base/aem-configs/config-utils';
import { templateUrls, _LOCALE_ , ACCESS_TOKEN, REFRESH_TOKEN } from '../../base/vars';
import { exists } from '../../base/dom-utils';
import { isValidOktaPassword, mapBusinessTravelerType, getParameterByName } from '../../base/utils';
import Login from '../../components/login';
import Cookie from '../base/cookie';
import PhoneInput from './phone-input';

class Join {
  constructor() {
    const urlWRJoin = templateUrls.joinPage;
    this.isJoinPage = window.location.href.includes(urlWRJoin);
    if (this.isJoinPage) {
      this.clearCacheOnMobile();
    }

    $(window).on("load", () => {
      setTimeout(() => {
        if (this.isJoinPage) {
          this.resetCustomerTypeSelect();
          this.validatePasswordConfirmation();
          this.updateVerificationOptions();
          this.showPasswordRequirements();
          this.initViewPassword();
          this.initPasswordMatcher();
          this.initSMSCountryListener();
          this.checkSMSEligibility();
          this.attachJoinStyles();
          PhoneInput.initializePhoneInputs();
        } else{
         console.log('After delay, Join constructor document ready passwordDetails class NOT exists');
        }
      }, parseInt(ConfigsUtils.getJoinNowTimeout()) || 2000);
    });
  }

  clearCacheOnMobile() {
    const urlParams = new URLSearchParams(window.location.search),
      isPhApp = urlParams.has('platform') && urlParams.has('adobeID');
    if (isPhApp) {
      const refreshTokenCookie = Cookie.getCookie(REFRESH_TOKEN);
      if (refreshTokenCookie) {
        Login.callSignOutAPI();
      }
    }
  }

  getOktaAccessToken() {
    const lsOktaKey = Object.keys(window.localStorage).find((key) => key.match(/profile email offline_access/));
    let oktaUserVal = window.localStorage.getItem(lsOktaKey);
    const oktaUserJson = oktaUserVal ? JSON.parse(oktaUserVal) : null;

    if (!oktaUserJson) {
      return oktaUserJson;
    }

    if (!oktaUserJson['body']) {
      return oktaUserJson;
    }

    if (oktaUserJson['body'][ACCESS_TOKEN]) {
      return oktaUserJson.body.access_token;
    } else {
      return oktaUserJson;
    }
  }

  resetCustomerTypeSelect() {
    const businessEnrollmentCheckbox = document.querySelector('#businessEnrollmentCheckbox');
    if (businessEnrollmentCheckbox) {
      businessEnrollmentCheckbox.addEventListener('change', () => {
        document.querySelector('.business-enrollment-select select').value = '';
      });
    }
  }

  attachJoinStyles() {
    const liteEnrollForm = document.querySelector('#liteEnroll');
    const enrollmentDisclosures = document.querySelectorAll('#liteEnroll .genericheadline')[1];
    if (enrollmentDisclosures) {
      enrollmentDisclosures.querySelectorAll('.content p')[enrollmentDisclosures.querySelectorAll('.content p').length - 1].style.marginBottom = 0;
      enrollmentDisclosures.querySelector('.button-container').classList.add('hide');
    }
    if (liteEnrollForm) {
      liteEnrollForm.parentNode.style.paddingBottom = '60px';
      liteEnrollForm.querySelector('.flex-form-group').style.marginBottom = '30px';
    }
    this.updateMobileAppLinks();
  }

  updateMobileAppLinks() {
    const urlParams = new URLSearchParams(window.location.search),
      isPhApp = urlParams.has('platform') && urlParams.has('adobeID');
    if (isPhApp) {
      let termsConditionsLink = document.querySelector('#wr-terms-and-conditions').href;
      termsConditionsLink = termsConditionsLink + '?platform=' + urlParams.get('platform') + '&adobeID=' + urlParams.get('adobeID');
      document.querySelector('#wr-terms-and-conditions').href = termsConditionsLink;
    }
  }

  getSelectedPhoneTypeValue() {
    const phoneTypeIndex = document.querySelector('#phoneType0').selectedIndex;
    const phoneTypes = ['Work', 'Home', 'Mobile', 'Fax', 'None'];
    return phoneTypes[phoneTypeIndex];
  }

  getEnrollmentFormData() {
    let arrayData = $('form#liteEnroll').serializeArray();
    const formData = {};

    $.each(arrayData, function() {
      formData[this.name] = this.value || '';
    });

    const enrollmentSourceKey = getParameterByName('enrollmentSource') ? getParameterByName('enrollmentSource') : 'IPRO';
    const enrollmentCodeKey = getParameterByName('promotionTrackingCode') ? getParameterByName('promotionTrackingCode') : '';
    const promoCode = getParameterByName('promoCode') ? getParameterByName('promoCode') : '';
    const enrollmentLocationKey = getParameterByName('siteId') ? getParameterByName('siteId') : '';
    const updatedPhonNumber = PhoneInput.getPhoneNumberWithCountryCode(formData['phoneNumber0'], 0);

    const jsonData = {
      accountTypeCode: 'MBR',
      firstName: formData['firstName'],
      lastName: formData['lastName'],
      enrollmentLocation: enrollmentLocationKey,
      enrollmentSourceCode: enrollmentSourceKey,
      enrollmentCode: enrollmentCodeKey,
      promotionCode: promoCode,
      enrollmentDeviceTypeCode: 'Web',
      addresses: [
        {
          address1: '',
          address2: '',
          city: formData['city0'] || '',
          stateCode: '',
          zip: formData['zipCode0'] || '',
          typeCode: 'Home',
          countryCode: formData['country0'] || 'US',
          order: 0,
          validated: true,
        },
      ],
      emails: [
        {
          email: formData['emailAddress'],
          typeCode: 'Home',
          order: 1,
        },
      ],
      phones: [
        // issue is with the country phone code and the capitilization of typecode mobile vs Mobile
        {
          phoneNumber: updatedPhonNumber,
          typeCode: this.getSelectedPhoneTypeValue(),
        },
      ],
      preferences: [
        {
          preferenceRuleCode: 'CP',
          preferenceRuleDetailCode: 'CP_WR_MARKETING',
          value: (formData['emailLabel'] === 'on').toString(),
        },
        {
          preferenceRuleCode: 'CP',
          preferenceRuleDetailCode: 'CP_3RD_PARTY_MARKETING',
          value: (formData['emailLabel'] === 'on').toString()
        },
        {
          preferenceRuleCode: 'CP',
          preferenceRuleDetailCode: 'CP_WR_SMS',
          value: (formData['smsLabel'] === 'on').toString()
        },
        {
          preferenceRuleCode: 'CP',
          preferenceRuleDetailCode: 'CP_LANG_PREF',
          value: _LOCALE_,
        },
        {
          preferenceRuleCode: 'BUSTRAV',
          preferenceRuleDetailCode: 'BUSTRAVTYPE',
          value: mapBusinessTravelerType(formData['businessEnrollmentSelect']),
        },
      ],
      challengeType: 'phone',
      type: formData['verificationChoice'] === 'Text' ? 'sms' : 'voice',
      name: formData['firstName'],
      nickName: formData['firstName'],
      password: formData['password'],
      userMetadata: {
        tallyStatus: 'active',
        prefLang: _LOCALE_,
      },
      userName: formData['emailAddress'] + '.',
    };

    return JSON.stringify(jsonData);
  }

  validatePasswordConfirmation() {
    let passwordField = document.querySelector('.form-group #password');
    let passwordConfirmationField = document.querySelector('.form-group #confirmPassword');
    if (passwordField && passwordConfirmationField) {
      [passwordField, passwordConfirmationField].forEach(function(pwdInput) {
        pwdInput.addEventListener('blur', (e) => {
          if (e.currentTarget.value) {
            let paddingTop14px = '14px';
            pwdInput.classList.add('focused');
            pwdInput.style.paddingTop = paddingTop14px;
          } else {
            pwdInput.classList.remove('focused');
            pwdInput.style.paddingTop = '';
          }
        });
      });
    }
  }

  updateVerificationOptions() {
    let phoneTypeDropdown = document.getElementById('phoneType0');
    if (phoneTypeDropdown) {
      phoneTypeDropdown.addEventListener('change', () => {
        if (phoneTypeDropdown.value == 'mobile') {
          document.getElementById('textVerificationRadio').click();
        } else if (phoneTypeDropdown.value == 'home' || phoneTypeDropdown.value == 'business') {
          document.getElementById('voiceVerificationRadio').click();
        }
      });
    }
  }

  showPasswordRequirements() {
    const passwordConditions = $('.password-conditions'),
      passwordField = $('input#password'),
      confirmPasswordField = $($('#confirmPassword')),
      showConditions = passwordConditions.data('showconditions');

    if (showConditions) {
      passwordConditions.removeClass('hidden');
    }

    passwordField.on('focus', () => {
      passwordConditions.removeClass('hidden');
    });
    passwordField.on('focusout', () => {
      if (!showConditions) {
        passwordConditions.addClass('hidden');
      }
    });

    passwordField.on('input', () => {
      const hasSpecialCharacters = passwordField.val().match(/^(?=.*[!@#$%^&]).*$/);
      const hasUppercase = passwordField.val().match(/^(?=.*[A-Z]).*$/);
      const hasLowercase = passwordField.val().match(/^(?=.*[a-z]).*$/);
      const hasNumber = passwordField.val().match(/^(?=.*[0-9]).*$/);
      const hasCharacters =  passwordField.val().length >= 8;
      const hasThreeRequirements = [hasUppercase, hasLowercase, hasNumber, hasSpecialCharacters].filter((value) => value).length >= 3;

      this.toggleCondition($('#validate-characters'), hasCharacters);
      this.toggleCondition($('#validate-three'), hasThreeRequirements);
      this.toggleCondition($('#validate-low'), hasLowercase);
      this.toggleCondition($('#validate-up'), hasUppercase);
      this.toggleCondition($('#validate-num'), hasNumber);
      this.toggleCondition($('#validate-special'), hasSpecialCharacters);
    });

    passwordField.on('blur', () => {
      if (exists($('#createPassword'))) {
        $('.submit').prop('disabled', !this.isValidPassword());
      }
    });
    confirmPasswordField.on('blur', () => {
      if (exists($('#createPassword'))) {
        $('.submit').prop('disabled', !this.isValidPassword());
      }
    });
  }

  toggleCondition(condition, isCorrect) {
    const checkmark = condition.find('.wr-icon-check');
    isCorrect ? checkmark.addClass('green-check') : checkmark.removeClass('green-check');
  }

  initPasswordMatcher() {
    const newPassword = $('#password');
    const confirmNewPassword = $('#confirmPassword');

    confirmNewPassword.on('blur', (e) => {
      const password = newPassword.val();
      const confirmedPassword = e.currentTarget.value;
      this.toggleErrorMessage(password, confirmedPassword);
    });
  }

  toggleErrorMessage(pass, confirmPass) {
    const formLabel = $('label[for=confirmPassword]');
    const errorMessage = $('#confirm-error-message');

    if (pass !== confirmPass) {
      formLabel.parent().addClass('has-error');
      errorMessage.removeClass('hidden');
    } else {
      formLabel.parent().removeClass('has-error');
      errorMessage.addClass('hidden');
    }

    if (exists($('#createPassword'))) {
      $('.submit').prop('disabled', !this.isValidPassword());
    }
  }

  initViewPassword() {
    const passwordInput = $('#password'),
      confirmPasswordInput = $('#confirmPassword'),
      passwordEye = passwordInput.siblings('.eye'),
      passwordDashEye = passwordInput.siblings('.dash-eye'),
      confirmPasswordEye = confirmPasswordInput.siblings('.eye'),
      confirmPasswordDashEye = confirmPasswordInput.siblings('.dash-eye');

    this.addToggleViewEventListener(passwordInput, passwordEye, passwordDashEye);
    this.addToggleViewEventListener(confirmPasswordInput, confirmPasswordEye, confirmPasswordDashEye);
  }

  addToggleViewEventListener(input, eye, dashEye) {
    eye.on('click', () => {
      input.attr('type', 'text');
      eye.addClass('hidden');
      dashEye.removeClass('hidden');
    });
    dashEye.on('click', () => {
      input.attr('type', 'password');
      eye.removeClass('hidden');
      dashEye.addClass('hidden');
    });
  }

  initSMSCountryListener() {
    const countryDropdown = $('[data-binding="addresses.0.countryCode"]');
    let country = '';

    if (countryDropdown) {
      countryDropdown.on('change', (e) => {
        country = e.currentTarget.value;
        this.checkSMSEligibility(country);
      });
    }
  }

  checkSMSEligibility(country) {
    const smsMarketing = $('.sms-marketing'),
      smsPersonalizationConfigs = ConfigsUtils.getSMSPersonalizationDisabled();
    let eligibleCountries = [];

    if (!smsPersonalizationConfigs || !country) {
      return;
    }

    eligibleCountries = smsPersonalizationConfigs.attributes['sms-personalization-eligible-countries'];
    if (eligibleCountries.includes(country) && _LOCALE_ == 'en-us') {
      smsMarketing.removeClass('hidden');
    } else {
      smsMarketing.addClass('hidden');
    }
  }

  isValidPassword() {
    const passwordField = $('input#password');
    const passwordMatches = passwordField.val() === $('#confirmPassword').val();
    return isValidOktaPassword(passwordField.val()) && passwordMatches;
  }
}

export default new Join();
