
class PointsGreeting {
  constructor() {
    if ($('.points-greeting').length > 0) {
      this.handlePointsGreeting();
    }
  }
  handlePointsGreeting() {
    let hours = new Date().getHours(),
      $el = $('.points-greeting');

    if (hours >= 2 && hours <= 12) {
      $el.removeClass('afternoon').removeClass('evening')
        .addClass('morning');
    } else if (hours > 12 && hours <= 17) {
      $el.removeClass('morning').removeClass('evening')
        .addClass('afternoon');
    } else {
      $el.removeClass('morning').removeClass('afternoon')
        .addClass('evening');
    }
    $('.daytime-message').removeClass('hidden');
  }
}

export default new PointsGreeting();
