import { exists } from '../../base/dom-utils.js';
import { scrollToElement } from './animate.js';
import { convertTypeForService } from './phone.js';
import UserHandler from './user.js';

class Form {
  constructor() {
    this.removeFormErrorOnSubmit();
    this.errorHandler();
    $('form').one('init.form.fv', (e, data) => {
      // Valdiation doesn't seem to fully initialize until after this event
      setTimeout(() => {
        this.initLegalDiscalimerCheck(e.currentTarget);
      });
    });
    this.onCancelClick();
  }
  errorHandler() {
    $('body').on('input propertychange', '.form-control', (e) => {
      let $this = $(e.currentTarget);

      if ($this.hasClass('has-error')) {
        $this.find('label span').addClass('error-label');
      } else {
        $this.find('label span').removeClass('error-label');
      }
    });
  }
  displayCorrectStates(stateEl, countryEl, val) {
    let country = $(countryEl).val();

    if (!this.state0DropdownDefault) {
      this.state0DropdownDefault = $('#state0').find('option.default-option');
    }
    if (!this.state1DropdownDefault) {
      this.state1DropdownDefault = $('#state1').find('option.default-option');
    }
    if (!this.hotelStateDropdownDefault) {
      this.hotelStateDropdownDefault = $('#hotelState').find('option.default-option');
    }

    this.stateDropdownOptions = this.stateDropdownOptions || $(stateEl).find('option:not(.default-option)');

    $(stateEl).empty();

    if ($(stateEl).attr('id') === 'state0') {
      $(stateEl).append(this.state0DropdownDefault);
    } else if ($(stateEl).attr('id') === 'state1') {
      $(stateEl).append(this.state1DropdownDefault);
    } else if ($(stateEl).attr('id') === 'hotelState') {
      $(stateEl).append(this.hotelStateDropdownDefault);
    }
    // DAI-36223 state dropdown
    if (window.User && window.User.addresses && window.User.addresses[0] && window.User.addresses[0].countryCode) {
      country = window.User.addresses[0].countryCode;
    }

    if (country === 'US' || country === 'CA' || country === 'CN') {
      let naVar = '';
      $.each(this.stateDropdownOptions, (i, e) => {
        if ($(e).val()
          .indexOf(country + '_') > -1) {
          $(stateEl).append($(e).clone());
        }
        if (country === 'CN' && $(e).val() === 'NA') {
          naVar = $(e).clone();
        }
      });
      naVar != '' ? $(stateEl).append(naVar) : '';
      if (val) {
        $(stateEl).val(val);
      } else {
        $(stateEl).prop('selectedIndex', 0);
      }
      this.enableFormField(stateEl);
    } else {
      this.disableFormField(stateEl);
      $(stateEl).prop('selectedIndex', 0);
    }
  }
  removeStatePrefix(val) {
    return (val === 'NA') ? val : val.split('_')[1];
  }
  enableFormField(el) {
    $(el).attr('disabled', false);
  }
  disableFormField(el) {
    $(el).attr('disabled', 'disabled');
  }
  handleDisablingOrFields($option1, $option2) {
    let val1 = $option1.val();
    let val2 = $option2.val();

    if (val1 || val2) {
      if (val1) {
        this.disableFormField($option2);
      }
      if (val2) {
        this.disableFormField($option1);
      }
    } else {
      this.enableFormField($option1);
      this.enableFormField($option2);
    }
  }
  displayLockedCountry() {
    if (window.User && window.User.addresses && window.User.addresses[0] && window.User.addresses[0].countryCode) {
      $('#country0').parents('.no-existing-address')
        .hide();
      $('span[data-binding="addresses.0.countryCode"]').parents('.pre-existing-address')
        .removeClass('hidden');
      this.displayCorrectStates($('#state0'), $('#country0'),
        (window.User.addresses[0].countryCode.match(/(US|CA|CN)/) ? ((window.User.addresses[0].countryCode.match(/(CN)/) && window.User.addresses[0].stateCode === 'NA') ? '' : window.User.addresses[0].countryCode + '_') : '') + window.User.addresses[0].stateCode);
    }
    if (window.User && window.User.addresses && window.User.addresses.length > 1 && window.User.addresses[1] && window.User.addresses[1].countryCode) {
      $('#country1').parents('.no-existing-address')
        .hide();
      $('span[data-binding="addresses.1.countryCode"]').parents('.pre-existing-address')
        .removeClass('hidden');
      this.displayCorrectStates($('#state1'), $('#country1'),
        (window.User.addresses[1].countryCode.match(/(US|CA|CN)/) ? ((window.User.addresses[1].countryCode.match(/(CN)/) && window.User.addresses[1].stateCode === 'NA') ? '' : window.User.addresses[1].countryCode + '_') : '') + window.User.addresses[1].stateCode);
    }
  }
  displayLockedMemberName() {
    if (window.User && window.User.firstName && window.User.lastName) {
      $('.locked-text-input .user-firstname').text(window.User.firstName);
      $('.locked-text-input .user-lastname').text(window.User.lastName);
    }
  }
  onCountryChange() {
    $(document).on('change', '#country0, #country1, #hotelCountry', (e) => {
      let stateSelector;
      switch ($(e.currentTarget).attr('id')) {
      case 'country0':
        stateSelector = '#state0';
        break;
      case 'country1':
        stateSelector = '#state1';
        break;
      case 'hotelCountry':
        stateSelector = '#hotelState';
        break;
      }
      if (stateSelector) {
        this.displayCorrectStates($(stateSelector), $(e.currentTarget));
      }
      if ($('#profileUpdate').length > 0) {
        this.enableZipCodeValidation($('#profileUpdate'));
      }
    });
  }
  enableZipCodeValidation($form) {
    if (/^US|CA|MX|GB$/i.test($('#country0').val())) {
      $form.formValidation('enableFieldValidators', 'zipCode0', true);
    } else {
      $form.formValidation('enableFieldValidators', 'zipCode0', false);
    }

    if (!$('.address-group').eq(1)
      .hasClass('hidden') && /^US|CA|MX|GB$/i.test($('#country1').val())) {
      $form.formValidation('enableFieldValidators', 'zipCode1', true);
    } else {
      $form.formValidation('enableFieldValidators', 'zipCode1', false);
    }
  }
  changeAddressType(el) {
    let addressType = $(el).val(),
      otherType = addressType == 1 ? 2 : 1;

    if ($(el).attr('id') === 'addressType0') {
      $('#addressType1').val(otherType);
    } else if ($(el).attr('id') === 'addressType1') {
      $('#addressType0').val(otherType);
    }

    $(document).trigger('checkEmptyInput');
  }
  scrollToForm(form) {
    let top = $(form).offset().top - $('header:visible').height();
    $('html,body').animate({
      scrollTop: top
    });
  }
  onCancelClick() {
    $(document).on('click', 'form:not(.booking-bar-form) .cancel', (e) => {
      this.disableFormField($(e.currentTarget));
      $(e.currentTarget).parents('form')
        .data('formValidation')
        .resetForm();
      $(e.currentTarget).parents('form')[0].reset();
      if ($('#profileUpdate').length > 0) {
        this.showAdditionalAddresses();
        this.checkPrimaryCheckbox();
      }
      UserHandler.renderUserData();
      this.enableFormField($(e.currentTarget));
      this.disableFormField($(e.currentTarget).parents('form')
        .find('.submit'));
    });
  }
  showAdditionalAddresses(form) {
    let addresses = window.User.addresses;

    if (addresses.length > 1) {
      $('.address-group.hidden').removeClass('hidden');
      $('.add-address-btn').hide();
      this.enableFormField($(form).find('.primary-checkbox input'));
    }
  }
  checkPrimaryCheckbox() {
    $($('.address-group')[0]).find('input:checkbox')
      .prop('checked', true);
    $($('.address-group')[1]).find('input:checkbox')
      .prop('checked', false);
  }
  showSuccessMessage(form, type) {
    let $btn = $(form).find('.submit');
    if (type === 'update') {
      $btn = $(form).find('.submit-profile');
    } else if (type === 'updatePassword') {
      $btn = $('.password-conditions').parent().siblings('.submit');
    }
    $btn.text($btn.data('success-label')).attr('disabled', 'disabled');
    $(form).one('focusin focus', 'input, select', () => {
      this.onFormFocusAfterSuccess($(form), type);
    });
  }
  onFormFocusAfterSuccess(form, type) {
    let $btn = $(form).find('.submit');
    if (type === 'update') {
      $btn = $(form).find('.submit-profile');
    } else if (type === 'updatePassword') {
      $btn = $('.password-conditions').parent().siblings('.submit');
    }
    $btn.text($btn.data('save-label')).removeAttr('disabled');
  }
  reindexAddressBasedOnCountry(addresses) {
    let addressArray = [];
    if (window.addresses && addresses) {
      if (_.findIndex(window.addresses, {
        countryCode: addresses[0].countryCode
      }) !== 0 && addresses[1]) {
        addressArray.push(addresses[1]);
        addressArray.push(addresses[0]);
      } else {
        addressArray = addresses;
      }
    }
    return addressArray;
  }
  displayBusinessName() {
    if ($('#addressType0').val() == 2) {
      $('#businessName0').parents('.flex-form-row')
        .show();
      $('#businessName1').parents('.flex-form-row')
        .hide();
    } else {
      $('#businessName1').parents('.flex-form-row')
        .show();
      $('#businessName0').parents('.flex-form-row')
        .hide();
    }
  }
  handleMultipleErrorLabels(e, data) {
    if ($(data.element).parents(".form-group")
      .find('.help-block:visible').length > 1) {
      let $first = $($(data.element).parents(".form-group")
        .find('.help-block:visible')[0]);

      $(data.element).parents(".form-group")
        .find('.help-block:visible')
        .hide();
      $first.show();
    }
  }
  showFormError($el, error) {
    if (!$el.hasClass('form-error')) {
      $el.siblings().filter('.form-error')
        .remove();
      $el.before(`
          <div class="form-error help-block">
            ${error}
          </div>
        `).prev('.form-error')
        .show();
    } else {
      $el.text(error).show();
    }

    return $el;
  }
  removeFormError(form) {
    $(form).find('.form-error')
      .remove();
  }
  removeFormErrorOnSubmit() {
    $(document).on('submit', 'form:not(".not-ajax")', (e) => {
      e.preventDefault();
      this.removeFormError($(e.currentTarget).is('form') ? $(e.currentTarget) : $(e.currentTarget).parents('form'));
    });
  }
  getErrorMessage(response) {
    if (response.responseJSON && response.responseJSON.ErrorMessage) {
      return response.responseJSON.ErrorMessage;
    } else if (response.statusText) {
      return response.statusText;
    } else {
      return 'error';
    }
  }
  reset(form) {
    $(form)[0].reset();
    $(form).find('input')
      .change();
    $(form).find('input:checkbox')
      .prop('checked', false);
    this.removeFormError($(form));
  }
  serializePhoneData($form) {
    let $phoneGroups = $form.find('.phone-group'),
      phones = [];

    _.each($phoneGroups, (phoneGroup, i) => {
      let $phoneGroup = $(phoneGroup),
        phoneNumber = $phoneGroup.find('[name^=phoneNumber]').val();

      if (phoneNumber) {
        let phoneData = {
          DefaultInd: i == 0 // First phone number is always the default
        };

        phoneData.phoneNumber = phoneNumber;

        let $phoneType = $phoneGroup.find('[name^=phoneType]');
        _.extend(phoneData, convertTypeForService($phoneType.val()));

        phones.push(phoneData);
      }
    });

    return phones;
  }
  serialize($form, _dontStringify, selectedInputs) {
    selectedInputs = selectedInputs || '*';

    let unindexedArray = $(selectedInputs, $form).serializeArray(),
      indexedArray = {};

    $.map(unindexedArray, (n, i) => {
      indexedArray[n['name']] = n['value'];
    });

    for (let key in indexedArray) {
      if ($(`[name=${key}]`).is('[type=checkbox]')) {
        indexedArray[key] = true;
      }
    }

    return (_dontStringify !== true) ? JSON.stringify(indexedArray) : indexedArray;
  }
  scrollToInputError($form) {
    $form = $form ? $($form).find('.has-error') : $('.has-error');
    scrollToElement($form);
  }
  /***
  Options{}
    fadeOutDuration: timing for fadeOut event.
      Default: 333
    fadeInDuration: timing for fadeIn event.
      Default: fadeOutDuration
    preSuccessSelector: string list of selectors to hide.
      This will add standard container automatically and defaults to standard container if nothing is provided
    successSelector: string list of selectors to show.
      This will add success container automatically and defaults to success container if nothing is provided
    preHideCB: callback to fire before fadeOut event begins.
    preSuccessCB: callback to fire before fadeIn event begins.
    successShowCB: callback to fire once fadeIn event has ended.
    data: anything that needs to be passed to any of the callbacks.
  ***/
  handleSuccessContainer(opts) {
    opts = typeof opts === 'object' ? opts : {};

    opts.fadeOutDuration = opts.fadeOutDuration === 0 ? 0 : opts.fadeOutDuration || 333;
    opts.fadeInDuration = opts.fadeInDuration === 0 ? 0 : opts.fadeInDuration || opts.fadeOutDuration;
    opts.preSuccessSelector = !(opts.preSuccessSelector) ? '.standard-state-container' : `${opts.preSuccessSelector},.standard-state-container`;
    opts.successSelector = !(opts.successSelector) ? '.successful-action-container' : `${opts.successSelector},.successful-action-container`;
    opts.successShowCB = opts.successShowCB || function() {
      return;
    };

    let $preSuccess = $(opts.preSuccessSelector),
      $success = $(opts.successSelector);

    if ($preSuccess.length > 0) {
      if (typeof opts.preHideCB === 'function') {
        opts.preHideCB(opts.data);
      }

      $preSuccess.fadeOut(opts.fadeOutDuration, () => {
        if (typeof opts.preSuccessCB === 'function') {
          opts.preSuccessCB(opts.data);
        }

        $success.fadeIn(opts.fadeInDuration, opts.successShowCB.bind(opts.data));
      });
    }
  }
  getDisclaimer(form) {
    return $(form).find('[name="disclaimer"]');
  }
  initLegalDiscalimerCheck(form) {
    let $form = $(form);
    let $disclaimer = this.getDisclaimer(form);
    if ($disclaimer.length) {
      $form.formValidation('addField', $disclaimer, {
        validators: {
          notEmpty: {
            enabled: true,
            message: '&nbsp;'
          }
        }
      });
    }
  }
  toggleBussinessEnrollmentDropdown($btCheckbox) {
    let $btDropdown = $btCheckbox.closest('.business-enrollment-wrapper').find('select#businessEnrollmentSelect');
    let $btSelectWrapper = $btCheckbox.closest('.business-enrollment-wrapper').find('.business-enrollment-select');
    let enabledDisabledEl = $btCheckbox.closest('.checkbox.checkbox-wrapper').hasClass('toggleEnable');

    if (enabledDisabledEl) {
      ($btCheckbox.is(':checked')) ? $btDropdown.removeAttr('disabled') : $btDropdown.attr('disabled', 'disabled');
    } else {
      if (!exists($('.accountsetupcarousel'))) {
        ($btCheckbox.is(':checked')) ? $btSelectWrapper.removeClass('hide') : $btSelectWrapper.addClass('hide');
      }
    }
  }
}

export default new Form();
