import { PHONE_LOCATION_TYPE, PHONE_TECHNICAL_TYPE } from '../../base/vars.js';

export function removeNonNumeric(val) {
  return val.replace(/\D/g, '');
}

export function getTypeFromService(phoneLocationType, phoneTechType) {
  switch (phoneLocationType) {
  case PHONE_LOCATION_TYPE.BUSINESS:
    switch (phoneTechType) {
    case PHONE_TECHNICAL_TYPE.VOICE:
      return 'business';
    case PHONE_TECHNICAL_TYPE.MOBILE:
      return 'mobile';
    }
    break;
  case PHONE_LOCATION_TYPE.HOME:
    switch (phoneTechType) {
    case PHONE_TECHNICAL_TYPE.VOICE:
      return 'home';
    case PHONE_TECHNICAL_TYPE.MOBILE:
      return 'mobile';
    }
    break;
  default:
    return null;
  }
}

export function convertTypeForService(type) {
  switch (type) {
  case 'business':
    return {
      PhoneLocationType: PHONE_LOCATION_TYPE.BUSINESS,
      PhoneTechType: PHONE_TECHNICAL_TYPE.VOICE,
    };
  case 'home':
    return {
      PhoneLocationType: PHONE_LOCATION_TYPE.HOME,
      PhoneTechType: PHONE_TECHNICAL_TYPE.VOICE,
    };
  case 'mobile':
    return {
      PhoneLocationType: PHONE_LOCATION_TYPE.HOME,
      PhoneTechType: PHONE_TECHNICAL_TYPE.MOBILE,
    };
  default:
    return {
      PhoneLocationType: null,
      PhoneTechType: null
    };
  }
}
