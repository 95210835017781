
import Form from '../base/form.js';
import Modal from './modal.js';
import OffersFlow from './offers-flow.js';
import { isPublish } from '../../base/vars.js';

class RegistrationModal {
  constructor() {
    this.name = 'registration-modal';
    if (typeof isPublish !== 'undefined' && isPublish) {
      this._generalModal = $('#genericLightbox');
      this._modalElm = $('#registrationModal');
      this._modalForm = this._modalElm.find('form').detach();
      this.bindEvents();
    }
  }

  bindEvents() {
    $('a[href*="#modalRegister"]').on('click', (e) => this.showModal(e));
  }

  showModal(e) {
    e.preventDefault();
    Modal.show({}, this._generalModal);
    Modal.populateModalContent(this._modalForm, this._generalModal);
    this._generalModal.find('form.registration-form').formValidation({
      'live': 'submitted',
      'fields': {
        'memberNumber': {
          'validators': {
            'notEmpty': {
              'enabled': true,
              'message': $('[data-fv-validator="notEmpty"][data-fv-for="memberNumber"]').text()
            },
          }
        }
      }
    })
      .on('success.form.fv', (e) => this.submit(e));
  }

  getFormData() {
    let obj = {};
    const data = Form.serialize(this._generalModal.find('form'), true);
    obj.accountNumber = data.memberNumber;
    if (data.phoneNumber0) {
      obj.phone = data.phoneNumber0;
    }
    if (data.emailAddress) {
      obj.email = data.emailAddress;
    }
    return obj;
  }

  submit(e) {
    e.preventDefault();
    const data = {
      promotionCode: $('a[href="#register"]').data('promo-code'),
      memberIdentifier: this.getFormData().accountNumber ? this.getFormData().accountNumber : this.getFormData()
    };
    OffersFlow.enroll(this._generalModal, null, data, {
      fadeOutDuration: 0,
      fadeInDuration: 0,
      isMember: false
    }, this._generalModal.find('form.registration-form'));
  }
}

export default new RegistrationModal();
