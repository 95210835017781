import Spinner from './spinner.js';
import { formatNumber, getDateFromDateString, formatDateForPrinting } from '../../base/utils.js';
import { requestUrls } from '../../base/vars.js';

class RecentActivityComponent {
  constructor() {
    this.name = 'recent-activity-component';

    this.$component = $(`.${this.name}`);
    this.tableRowTemplate = null;
    this.mobileTableRowTemplate = null;
    this.allActivity = null;

    if (this.$component.length) {
      this.init();
    }
  }

  init() {
    Spinner.appendTo(this.$component);

    this.tableRowTemplate = this.initTemplate(this.$component.find('#recentActivityRowTemplate'));
    this.mobileTableRowTemplate = this.initTemplate(this.$component.find('#mobileRecentActivityRowTemplate'));

    this.fetchActivityData()
      .done(() => this.pageReady())
      .always(() => {
        Spinner.remove(this.$component);
      });
  }

  initTemplate($templateEl) {
    let templateString = $templateEl.text();
    $templateEl.remove();
    return _.template(templateString);
  }

  fetchActivityData() {
    return $.get({
      url: requestUrls.activity,
      data: {
        memberIdentifier: window.User.Channel.WR.MembershipID
      }
    }).then((res) => this.parseData(res));
  }

  parseData(response) {
    this.allActivity = response.data || [];
    _.each(this.allActivity, (activity) => {
      let activityDescription = activity.transactionGroupDescription || '';
      if (activity && activity.stay && activity.stay[0] && activity.stay[0].ace03Description) {
        activityDescription = activity.stay[0].ace03Description;
      }
  
      activity.description = activityDescription;
      activity.date = getDateFromDateString(activity.transactionGroupDateTime);

      //activity.points = formatNumber(activity.transactionGroupEarn[0].amount || 0);
      //activity.miles = formatNumber(activity.transactionGroupEarn[0].amount || 0);
      for (var key in activity.transactionGroupEarn) {
        var value = activity.transactionGroupEarn[key];
        if( value.currencyTypeCode === 'POINTS') {
          activity.points = formatNumber(activity.transactionGroupEarn[0].amount || 0);
        } else {
          activity.miles = formatNumber(activity.transactionGroupEarn[0].amount || 0);
        }
      }
      activity.nights = 0;
      activity.quantity = 0;

      if (activity && activity.transactions && activity.transactions[0] && activity.transactions[0].spend && activity.transactions[0].spend.quantity) {
        activity.quantity = activity.transactions[0].spend.quantity;
      }
      if (activity.stay && activity.stay[0].eligibleNights) {
        activity.nights = activity.stay[0].eligibleNights;
      }

      if (activity.redemption && activity.redemption[0].eligibleNights) {
        activity.nights = activity.redemption[0].eligibleNights;
      }
    });

    return this.allActivity;
  }

  pageReady() {
    this.renderActivityData(this.allActivity);
  }

  renderActivityData(activityData) {
    let $activityTables = this.$component.find('.activity-tables');
    let $noActivity = this.$component.find('.no-activity');
    if (activityData.length) {
      let templateData = {
        allActivity: activityData,
        formatDateForPrinting: formatDateForPrinting
      };
      $activityTables.find('.mobile-table-body').html(this.mobileTableRowTemplate(templateData));
      $activityTables.find('tbody').html(this.tableRowTemplate(templateData));
      $activityTables.removeClass('hidden');
      $noActivity.addClass('hidden');
    } else {
      $activityTables.addClass('hidden');
      $noActivity.removeClass('hidden');
    }
  }
}

export default new RecentActivityComponent();
