import { EventHandler } from '../../base/utils.js';
import Form from '../base/form.js';
import ModifiedPromoCarousel from './modified-promo-carousel.js';
import Spinner from './spinner.js';
import UserHandler from '../base/user.js';
import LazyLoad from '../../base/lazy-load.js';

// Constructor
class CityMemberLevelDropdown {
  constructor() {
    this.$component = $('.city-member-level-dropdown');

    if (this.$component.length) {
      this.$contentContainer = this.$component.find('.city-member-content');

      let $memberLevelDropdown = this.$component.find('.member-level-dropdown');
      let $memberLevelOptions = $memberLevelDropdown.find('.category-list');
      let $titaniumOption = $memberLevelOptions.find('[data-value="Titanium"]').detach();

      UserHandler.getSessionProfile()
        .then((sessionProfile) => {
          if (sessionProfile.level.toLowerCase() === 'titanium') {
            $memberLevelOptions.append($titaniumOption);
          }
          $memberLevelDropdown.trigger('set', sessionProfile.level);
        });

      this.$component.find('form').change(e => {
        let formData = Form.serialize($(e.currentTarget), true);
        if (formData.city && formData.memberLevel) {
          this.updateCityMemberInfo(formData.city, formData.memberLevel);
        }
      });
    }
  }

  generateContainerLink(path, memberLevel) {
    return path + '.content.' + memberLevel + '.html';
  }

  updateCityMemberInfo(cityPath, memberLevel) {
    Spinner.appendTo(this.$contentContainer);
    return $.get(this.generateContainerLink(cityPath, memberLevel))
      .done(containerHtml => {
        let isOtherContent = false,
          isTagContent = false,
          taggedContent = $(containerHtml).find('.taggingcontainer');

        this.$contentContainer.html(containerHtml);
        var elements = [].slice.call(document.querySelectorAll(".pll-background:not(.pll-background-ready)"));
        LazyLoad.addElements(elements, LazyLoad.lazyLoadBackground.handleBackground);
        LazyLoad.bindNewElement();
        if ($(containerHtml).find('> div')
          .not('.taggingcontainer').length) {
          isOtherContent = $(containerHtml).find('> div')
            .not('.taggingcontainer')
            .html() !== '';
        }

        isTagContent = _.some(taggedContent, (o) => {
          return $.trim($(o).html());
        });

        if (isTagContent || isOtherContent) {
          let city = cityPath.split('/');
          city = city[city.length - 1];
          EventHandler.triggerEvent('city-member-level', {
            filter: 'city:' + city.toLowerCase() + '|level:' + memberLevel.toLowerCase()
          });
        } else {
          EventHandler.triggerEvent('city-member-level_no-results');
        }
      })
      .always(() => {
        Spinner.remove(this.$contentContainer);
        // re-Initialize content components
        ModifiedPromoCarousel.initCarousel();
      });
  }

}

export default new CityMemberLevelDropdown();
