import DataBinding from '../base/data-binding.js';
import { _LOCALE_ } from '../../base/vars.js';

class DateFields {
  constructor() {
    this.init();
  }

  init() {
    this.$0 = $('.date-formfield-container');
    this.$birthMonth = $('#birthMonth');
    this.$birthDay = $('#birthDay');
    this.$birthYear = $('#birthYear');
    this.$arrivalYear = $('#arrivalYear');
    this.$departureYear = $('#departureYear');
    this.submitSelector = '.iso-date-hidden';
    this._documentBound = false;

    if (this.$0.length > 0) {
      this.handleDropdowns();
      this.handleYear();
      this.handleDataBinding();
      this.bindDocument();
    }
  }
  birthdateExists(u) {
    u = u || window.User;
    return (u && u.birthDate && u.birthDate.length > 0);
  }
  populateYearSelect($el, start, end) {
    for (start; start <= end; start++) {
      let $opt = $(document.createElement('option'));

      $opt.attr('value', start);
      $opt.append(start);

      $el.append($opt);
    }
  }
  handleYear() {
    const currentYear = new Date().getFullYear();

    if (this.$birthYear.length > 0) {
      this.populateYearSelect(this.$birthYear, 1900, (currentYear - 1));
    }

    if (this.$arrivalYear.length > 0) {
      this.populateYearSelect(this.$arrivalYear, (currentYear - 5), (currentYear + 1));
    }

    if (this.$departureYear.length > 0) {
      this.populateYearSelect(this.$departureYear, (currentYear - 5), (currentYear + 1));
    }
  }
  disableInvalidMonthsOnDayChange(e) {
    let $formContainer = $(e.currentTarget).closest('.date-formfield-container');
    $formContainer.find('select.date-month-dropdown option').removeAttr('disabled');

    for (let month of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]) {
      let newMonth = new Date(2000, month, $(e.currentTarget).val()).getMonth();

      if (month !== newMonth) {
        newMonth = (newMonth < 10) ? '0' + newMonth : newMonth;

        $formContainer.find(`select.date-month-dropdown option[value=${newMonth}]`).attr('disabled', 'disabled');
      }
    }
  }
  disableInvalidDaysOnMonthChange(e) {
    let $formContainer = $(e.currentTarget).closest('.date-formfield-container');
    $formContainer.find('select.date-day-dropdown option').removeAttr('disabled');

    for(let day = 1; day <= 31; day++) {
      let newDay = new Date(2000, parseInt($(e.currentTarget).val()) - 1, day);
      if(day !== newDay.getDate()) {
        day = (day < 10) ? '0' + day : day;
        $formContainer.find(`select.date-day-dropdown option[value=${day}]`).attr('disabled', 'disabled');
      }
    }
  }
  updateSubmitDate(e) {
    this.$0 = $(e.target).parents('.date-formfield-container');
    const $date = this.$0.find(this.submitSelector);

    if ($date.length > 0) {
      let month = this.$0.find('.date-month-dropdown').val() * 1,
        day = this.$0.find('.date-day-dropdown').val() * 1,
        year = this.$0.find('.date-year-dropdown').val();

      if (!year) {
        if (this.birthdateExists()) {
          year = window.User.birthDate.split('-')[0];
        } else {
          year = (new Date()).getFullYear();
        }
      }

      month = (month < 10) ? `0${month}` : month;
      day = (day > 9) ? day : `0${day}`;

      $date.val(`${year}-${month}-${day}`);
    }
  }
  parseBirthdateAndBindData(e, u) {
    u = u ? JSON.parse(u) : window.User;

    const dateArray = this.birthdateExists(u) ? u.birthDate.split('-') : [],
      birthdate = (dateArray.length === 3) ? new Date(parseInt(dateArray[0]), (parseInt(dateArray[1]) - 1), parseInt(dateArray[2])) : null,
      month = birthdate ? (birthdate.getMonth() + 1) : '',
      day = birthdate ? birthdate.getDate() : '',
      year = birthdate ? birthdate.getFullYear() : '';

    DataBinding.renderData({
      birthDate: {
        month: (month < 10) ? 0 + ('' + month) : month,
        day: (day >= 10) ? day : 0 + ('' + day),
        year: year
      }
    }, '[data-binding^=birthDate]');
  }
  handleDataBinding() {
    if (this.$birthMonth.length > 0 && this.$birthDay.length > 0) {
      $(document).on('user:profile:update', () => this.parseBirthdateAndBindData())
        .on('user:binding:complete', (e) => this.updateSubmitDate(e));
    }
  }
  handleDropdowns() {
    this.$0.find('select.date-day-dropdown option').each((i, el) => {
      let val = $(el).val() * 1;

      if ($(el).is('.default-option')) {
        $(el).val('');
      } else if (_.isNumber(val) && val < 10) {
        let newVal = `0${$(el).val()}`;

        $(el).val(newVal)
          .text(newVal);
      }
    });

    this.$0.find('select.date-month-dropdown option').each((i, el) => {
      let val = $(el).val() * 1;

      if ($(el).is('.default-option')) {
        $(el).val('');
      } else if (_.isNumber(val)) {
        let newVal = (val < 10) ? `0${val}` : val,
          month = new Date(new Date().getFullYear(), `${val - 1}`, 1).toLocaleString(_LOCALE_, {
            month: 'long'
          });

        // Fix old Safari toLocaleString implementation.
        if (month && month.length > 0) {
          month = month.split(' ')[0];
        }

        $(el).val(newVal)
          .text(month);
      }
    });
  }
  bindDocument() {
    $(document)
      .on('change', this.$0.find('select'), (e) => this.updateSubmitDate(e))
      .on('change', 'select.date-day-dropdown', (e) => this.disableInvalidMonthsOnDayChange(e))
      .on('change', 'select.date-month-dropdown', (e) => this.disableInvalidDaysOnMonthChange(e));

    this._documentBound = true;
  }
}

export default new DateFields();
