
class QuickLinksMenu {
  constructor() {
    if ($('.quick-links-menu').length > 0) {
      this.bindDropdownToggles();
    }
  }

  bindDropdownToggles() {
    $(document).on('click', '.quick-links-menu .dropdown-toggle', (e) => {
      let quickLinksMenu = $('.quick-links-menu'),
        mobile_list = quickLinksMenu.find('#quick-links-menu'),
        down_arrow = quickLinksMenu.find('.quick-links-toggle-arrow');

      mobile_list.slideToggle();
      down_arrow.toggleClass('arrow-rotate');

      e.preventDefault();
    });
  }
}

export default new QuickLinksMenu();
