import { isiOS } from '../../base/utils.js';

class SelectGroup {
  constructor() {
    this.checkEmptySelect();
    this.onCheckEmptyInput();
    this.onSelectFocus();
    this.onSelectFocusOut();
  }
  addOptGroup($el) {
    if (isiOS() && $el.find('optgroup[class="ios-wa"]').length === 0) {
      $el.append('<optgroup label="" class="ios-wa"></optgroup>');
    }
  }
  checkEmptySelect() {
    if ($('select').length > 0) {
      $.each($('select'), (i, el) => {
        this.addOptGroup($(el));
        this.handleEmptySelect($(el));
      });
    }
  }
  handleEmptySelect($el) {
    if (!$el.val() || $el.val() === '') {
      $el.parent('.select-container').addClass('empty');
    } else {
      $el.parent('.select-container').removeClass('empty');
    }
  }
  onCheckEmptyInput() {
    $(document).on('checkEmptyInput', () => {
      this.checkEmptySelect();
    });
  }
  onSelectFocus() {
    // Apply focus for inputs that don't have an id
    $(document).on('click', 'label', (e) => {
      $(e.currentTarget).siblings('.select-container')
        .find('select')
        .focus();
    });
    $(document).on('focusin', 'select', (e) => {
      $(e.currentTarget).parent('.select-container')
        .addClass('focused');
    });
  }
  onSelectFocusOut() {
    $(document).on('change focusout', 'select', (e) => {
      this.handleEmptySelect($(e.currentTarget));
      $(e.currentTarget).parent('.select-container')
        .removeClass('focused');
    });
  }
}

export default new SelectGroup();
