import CustomValidation from '../base/custom-validation.js';
import Form from '../base/form.js';
import Spinner from './spinner.js';
import UserHandler from '../base/user.js';
import { requestUrls } from '../../base/vars.js';

class SecurityPasswordForm {
  constructor() {
    this.formSelector = '#securityUpdatePassword';
    this.$form = $(this.formSelector);

    if (this.$form.length > 0) {
      $(document).one('user:profile:updated', () => {
        this.$form.show();
        this.formInit();
      });
    }
  }

  formInit() {
    let $currentPasswordError = $($('#currentPassword').parent()
        .find('.help-block')[0]),
      currentPasswordErrorMessage = $currentPasswordError.text(),
      $passwordError = $($('#password').parent()
        .find('.help-block')[0]),
      passwordErrorMessage = $passwordError.text(),
      $confirmPasswordError = $($('#confirmPassword').parent()
        .find('.help-block')[0]),
      confirmPasswordErrorMessage = $confirmPasswordError.text();

    $currentPasswordError.remove();
    $passwordError.remove();
    $confirmPasswordError.remove();

    this.$form
      .on('init.form.fv', (e, data) => {
        Form.removeFormError($(e.target));
        Form.disableFormField(this.$form.find('.submit'));
      })
      .formValidation({
        button: {
          selector: '.submit',
          disabled: ''
        },
        live: 'disabled',
        fields: {
          currentPassword: {
            validators: {
              notEmpty: {
                message: currentPasswordErrorMessage
              },
              stringLength: {
                min: 8,
                message: currentPasswordErrorMessage
              }
            }
          },
          password: {
            validators: {
              notEmpty: {
                message: passwordErrorMessage
              },
              different: {
                field: 'currentPassword',
                message: passwordErrorMessage
              },
              callback: {
                callback: (e, validator, $field) => this.passwordValidation(e, validator, $field),
                message: passwordErrorMessage
              }
            }
          },
          confirmPassword: {
            validators: {
              notEmpty: {
                message: confirmPasswordErrorMessage
              },
              identical: {
                field: 'password',
                message: confirmPasswordErrorMessage
              }
            }
          }
        }
      })
      .on('keyup', '#password', (e) => {
        this.passwordValidation(null, null, $(e.currentTarget));
      })
      .on('keyup', '#confirmPassword', (e) => {
        if(CustomValidation.passwordMatchRule($('#password').val(),$(e.currentTarget).val())) {
          $(e.currentTarget).parent().find('.validator.pw-length i').removeClass('validator-circle').addClass('whg-core-icon-check');
        } else {
          $(e.currentTarget).parent().find('.validator.pw-length i').addClass('validator-circle').removeClass('whg-core-icon-check');
        }
      })
      .on('success.form.fv', (e, data) => {
        e.preventDefault();
        this.postForm(this.createAccountData());
      })
      .on('success.field.fv', (e, data) => {
        if (!data.fv.isValid()) {
          Form.disableFormField($(e.currentTarget).find('.submit'));
        }
      })
      .on('err.field.fv', Form.handleMultipleErrorLabels);
  }

  passwordValidation(e, validator, $field) {
    return CustomValidation.passwordValidation($field);
  }

  createAccountData() {
    let obj = {
      'currentPassword': $('#currentPassword').val(),
      'newPassword': $('#password').val(),
      'confirmNewPassword': $('#confirmPassword').val()
    };

    return obj;
  }

  postForm(account) {
    if (account) {
      Spinner.appendTo(this.$form);
      Form.disableFormField(this.$form.find('.submit'));
      Form.disableFormField(this.$form.find('.cancel'));

      let data = JSON.stringify(account);

      $.ajax({
        type: 'POST',
        contentType: 'application/json',
        url: requestUrls['onlineAccountSetup'],
        data: data,
        context: {
          onFail: {
            scrollToElement: this.$form,
            formElement: this.$form
          }
        },
        success: () => {
          Spinner.remove(this.$form);
          Form.showSuccessMessage(this.$form);
          UserHandler.getProfile(() => Form.disableFormField(this.$form.find('.submit')));
        },
        complete: () => {
          Form.enableFormField(this.$form.find('.cancel'));
        }
      });
    }
  }
}

export default new SecurityPasswordForm();
