import Reservations from './reservations.js';
import Spinner from './spinner.js';
import {
  formatDateForPrinting,
  getHotelLocaleUrlToken,
  getISOFormatDate,
  getXDays,
  EventHandler
} from '../../base/utils.js';

class FullReservations {
  constructor() {
    this.name = 'full-reservations';
    if ($('.full-reservations-component').length > 0) {
      this.init();
    }
  }

  init() {
    this.onSelectChange();

    Spinner.appendTo($('.full-reservations-component'));

    $(document).one('user:profile:updated', () => {
      let dates = this.getDatesToDisplay();

      Reservations.getReservations(dates.selectedPastDate, dates.futureDate)
        .done((response) => {
          if (response.body) {
            Reservations.renderUpcomingReservationData(response.body);
            this.populateAllReservations(response.body);
          }
        })
        .always(() => {
          Spinner.remove($('.full-reservations-component'));
        });

    });
  }

  // LISTENER FOR TIME FILTER CHANGE
  onSelectChange() {
    $('#timeFilter').change(() => {
      $('#past-res-listings').empty();
      $('.none-past-message').hide();
      Spinner.appendTo($('#past-res-listings'));
      let dates = this.getDatesToDisplay();

      Reservations.getReservations(dates.selectedPastDate, dates.futureDate)
        .done((data) => {
          if (data.body) {
            this.getPastRes(data.body);
          }
        })
        .always(() => {
          Spinner.remove($('#past-res-listings'));
        });
    });
  }

  // GET DATES TO SEARCH BETWEEN
  getDatesToDisplay() {
    // Set dateTo (futureDate) to a year from today to show future dates- can't book more than a year out
    let futureDate = getISOFormatDate(getXDays(366, new Date())),
      todayDate = new Date(),
      selectedPastDate,
      filterValue = $('#timeFilter').val();

    // Get date based on selection
    if (filterValue == 'd30') {
      selectedPastDate = getISOFormatDate(getXDays(-30, todayDate));
    } else if (filterValue == 'd90') {
      selectedPastDate = getISOFormatDate(getXDays(-90, todayDate));
    } else if (filterValue == 'm12') {
      // Change calendar year -1
      let d = todayDate,
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = (d.getFullYear() - 1);
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      selectedPastDate = [year, month, day].join('-');
    } else {
      // If m18 or null, then show all (max stored is 18 months)
      let d = todayDate,
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = (d.getFullYear() - 2);
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      selectedPastDate = [year, month, day].join('-');
    }

    let dates = {
      selectedPastDate: selectedPastDate,
      futureDate: futureDate
    };
    return (dates);
  }

  // POPULATE BOTH CATEGORIES OR DISPLAY NONE MESSAGE
  populateAllReservations(resData) {
    if (resData.reservations) {
      if (resData.reservations.future || resData.reservations.current || resData.reservations.past) {
        this.getFutureRes(resData);
        this.getPastRes(resData);
      }
    } else {
      this.noReservationsFound();
    }
  }

  // GET FUTURE & CURRENT RESERVATIONS OR DISPLAY NONE MESSAGE
  getFutureRes(resData) {
    if (resData.reservations && (resData.reservations.future || resData.reservations.current)) {
      let futureData = resData.reservations.future,
        currentData = resData.reservations.current,
        displayContainer = $('#upcoming-res-listings');
      if(currentData) {
        this.populateCategory(currentData, displayContainer, resData.property);
      }
      if(futureData) {
        this.populateCategory(futureData, displayContainer, resData.property);
      }
      EventHandler.triggerEvent('upcoming-reservation', {
        upcomingReservation: 'yes'
      });
    } else {
      $('.none-upcoming-message').show();
      EventHandler.triggerEvent('upcoming-reservation', {
        upcomingReservation: 'no'
      });
    }
  }

  // GET PAST RESERVATIONS OR DISPLAY NONE MESSAGE
  getPastRes(resData) {
    if (resData.reservations && resData.reservations.past) {
      let pastData = resData.reservations.past,
        displayContainer = $('#past-res-listings');

      this.populateCategory(pastData, displayContainer, resData.property);
    } else {
      $('.none-past-message').show();
    }
  }

  noReservationsFound() {
    /* $('.full-reservations-component .upcoming-container').hide();
    $('.full-reservations-component .rule-line-component').hide();
    $('.full-reservations-component .past-container').hide();
    $('.none-on-record-message').show();*/
    $('.full-reservations-component .none-upcoming-message').show();
    EventHandler.triggerEvent('upcoming-reservation', {
      upcomingReservation: 'no'
    });
  }

  // POPULATE LISTINGS FOR THE CATEGORY
  populateCategory(categoryData, listingContainer, propertiesData) {
    let resTemplate = _.template($('#reservationTemplate').html());

    if(typeof categoryData === 'object' && !Array.isArray(categoryData)) {
      categoryData = [categoryData];
    }

    // Populate each listing
    $.each(categoryData, (i, val) => {
      let res = {},
        propertyId = val.rooms.brandId + val.rooms.propertyCode,
        propData = propertiesData[propertyId];

      res.brandId = val.rooms.brandId,
      res.propertyCode = val.rooms.propertyCode,
      res.propertyUrl = getHotelLocaleUrlToken() + '/hotels/' + val.rooms.propertyCode,
      res.roomsRatesUrl = getHotelLocaleUrlToken() + '/hotels/rooms-rates?hotel_id=' + val.rooms.propertyCode,
      res.confirmationNumber = val.confirmationNumber,
      res.channelId = val && val.channelId || '',
      res.firstName = val.rooms.firstName,
      res.lastName = val.rooms.lastName,
      res.checkInDate = val.rooms.checkInDate,
      res.checkOutDate = val.rooms.checkOutDate,
      res.checkInFormatted = formatDateForPrinting(res.checkInDate, 'weekdayCompactAlt'),
      res.checkOutFormatted = formatDateForPrinting(res.checkOutDate, 'weekdayCompactAlt');

      // HANLDING FALLBACK FOR IF PROPERTY DOESN'T HAVE DATA- SHOULDN'T BE RELEVANT IN PROD
      if (propData) {
        res.propertyName = propData.propertyName,
        res.propertyImage = propData.imgurl || '',
        res.propertyPhone = propData.phone || '',
        res.propertyAddress = propData.propertyAddress,
        res.propertyCity = propData.propertyCity,
        res.propertyState = propData.propertyState,
        res.propertyPostalCode = propData.propertyPostalCode,
        res.fullPropertyAddress = [res.propertyAddress, res.propertyCity, res.propertyState, res.propertyPostalCode].join(', ');

        if (propData.latitude && propData.longitude) {
          let propertyMapUrl = 'https://maps.apple.com/maps';
          propertyMapUrl += '?ll=' + propData.latitude + ',' + propData.longitude;
          propertyMapUrl += '&q=' + encodeURIComponent(res.fullPropertyAddress);

          res.propertyMapUrl = propertyMapUrl;
        }
      } else {
        res.propertyName = 'Property Data Missing for ' + propertyId,
        res.propertyImage = '',
        res.propertyPhone = '',
        res.propertyAddress = '',
        res.propertyCity = '',
        res.propertyState = '',
        res.propertyPostalCode = '',
        res.propertyBingUrl = '';
      }

      var compiledTemplate = resTemplate({
        res: res
      });

      // Add listing to correct container
      $(listingContainer).append(compiledTemplate);

      let $imgCheck = $(listingContainer).find('.reservation-listing:last-child .prop-image');
      Reservations.handleFallbackImage($imgCheck, val.rooms.brandId, res.propertyName);

    });
  }
}

export default new FullReservations();
