import Cookie from '../base/cookie.js';
import { getParameterByName, EventHandler } from '../../base/utils.js';
import Spinner from './spinner.js';
import { requestUrls, templateUrls } from '../../base/vars.js';

class SSODelegate {
  constructor() {
    this.linkClicked = null;
    this.onPointsClick();
  }
  onPointsClick() {
    $(document).on('click', 'a[href*="#points"], a[href*="#sso"]', (e) => {
      let href = $(e.currentTarget).attr('href'),
          preHash = href.split('#') ? href.split('#')[1] : '',
          hash = preHash.split('?') ? preHash.split('?')[0] : preHash;

      if(!(hash === 'sso' || hash === 'points') || (href.match('#sso') && getParameterByName('ssoType', href) !== '1')) {
        // Link is for another SSO type
        return;
      }
      e.preventDefault();
      if (Cookie.isAuthenticated()) {
        this.linkClicked = e.currentTarget;
        this.getPoints(href);
      } else {
        EventHandler.send('open-login-modal');
        if(this.linkClicked !== e.currentTarget) {
          this.linkClicked = e.currentTarget;
          $(document).one('user:profile:updated', () => this.getPoints(href, true));
        }
      }
    });
  }
  getPoints(url, useSameTab, fromThirdParty) {
    let baseUrl = getParameterByName('url', url),
      windowOpenRef,
      clientId = getParameterByName('clientId', url) ||
        getParameterByName('clientID', url) || getParameterByName('clientid', url);

    if($('.main-login-form').find('.spinner').length == 0){
      Spinner.appendTo($('body'), true, true);
    }
    
    if(fromThirdParty || url === $(this.linkClicked).attr('href')) {
      if(!fromThirdParty && !($('body').hasClass('safari') && useSameTab)) {
        windowOpenRef = window.open();
      }
    }
    $.ajax({
      type: 'GET',
      url: requestUrls.ssoDelegate,
      data: {
        'clientID': clientId || 'PIN'
      },
      contentType: 'application/json',
      context: {
        onFail: {
          removeSpinnerFrom: $('body')
        }
      }
    }).done((r) => {
      let locationHref = '';
      if(clientId === "CLN"){
        let token = btoa(unescape(encodeURIComponent(r.links.self.href)));
        locationHref = `${baseUrl}?mv=${token}`;
      } else {
        locationHref = `${baseUrl}?token=${r.links.self.href}`;
      }
      if(fromThirdParty || url === $(this.linkClicked).attr('href')) {
        if(fromThirdParty || ($('body').hasClass('safari') && useSameTab)) {
          window.location.assign(locationHref);
        } else {
          windowOpenRef.location = locationHref;
          if(templateUrls.redirect){
            window.location.assign(templateUrls.redirect);
          }
        }
      }

      Spinner.remove();
    });
  }
}

export default new SSODelegate();
