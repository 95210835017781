import Form from '../base/form.js';
import HeaderMobile from './header-mobile.js';
import SSO from './sso.js';
import Modal from './modal.js';
import Redirect from '../base/redirect.js';
import { isMobileWidth } from '../../base/dom-utils.js';
import Spinner from './spinner.js';
import UserHandler from '../base/user.js';
import {
  isiOS,
  checkVulnerableUrl,
  getParameterByName,
  EventHandler
} from '../../base/utils.js';
import { requestUrls, templateUrls } from '../../base/vars.js';
import Analytics from '../../base/wyn-analytics-module.js';
import SSODelegate from './sso-delegate.js';

class SecuritySetup {
  constructor() {
    this.redirectUrl = null;
  }
  openSecurityQuestions(modal, redirect, options) {
    this.redirectUrl = redirect || templateUrls.redirect;

    if (modal) {
      this.getSecurityQuestionsTemplate($('#genericLightbox .modal-content'), options);
    } else {
      this.getSecurityQuestionsTemplate($('.centered-container-content .center'), false);
    }
  }
  getSecurityQuestionsTemplate(form, options) {
    let context = {
      el: form,
      options: options,
      onFail: {
        formElement: $('#securitySetup')
      }
    };
    // Get security questions template html to be placed within modal content
    Form.removeFormError($('#securitySetup'));
    $.ajax({
      contentType: 'application/json',
      url: templateUrls['securitySetup'],
      context: context,
      success: (response) => {
        EventHandler.triggerEvent('security_questions', {
          pageName: 'security-questions'
        });
        this.getSecurityQuestions(context.el, response, context.options);
      }
    });
  }
  getSecurityQuestions(form, template, options) {
    let context = {
      el: form,
      template: template,
      options: options,
      onFail: {
        removeSpinnerFrom: $('.sign-in-form'),
        formElement: $(form).hasClass('modal-content') ? $('#securitySetup') : $(form)
      }
    };
    // Get security questions to be injected into each select
    Form.disableFormField($(form).find('.submit'));
    Form.removeFormError($(form));
    $.ajax({
      url: requestUrls['getSecurityQuestions'],
      contentType: 'application/json',
      context: context,
      success: (response) => {
        $(context.el).html(context.template);
        Spinner.remove($('.sign-in-form'));
        Form.enableFormField($('.sign-in-form').find('.submit'));
        this.addFormValidation();
        this.onQuestionChange();
        this.createSelectOptions($(context.el), response.data);
        $(document).trigger('checkEmptyInput');
        Spinner.remove($('#securitySetup'));
        if ($(context.el).hasClass('modal-content')) {
          if (isMobileWidth()) {
            HeaderMobile.closeDropdowns();
          }
          Modal.show(context.options);
          Form.enableFormField($('.sign-in-form').find('.submit'));
        }
      }
    });
  }
  createSelectOptions(form, questions) {
    // Create options for each select based on the questions retreived
    let selects = $(form).find('select');

    selects.find('optgroup.ios-wa').remove();
    $.each(selects, (i, e) => {
      let select = $(e);
      $.each(questions, (i, e) => {
        $(select).append('<option value="' + e.id + '">' + e.question + '</option>');
      });
    });
    if (isiOS()) {
      selects.append('<optgroup label="" class="ios-wa"></optgroup>');
    }
  }
  updateAvailableOptions(form) {
    // Validate each select to have the correct available options to avoid duplicate security questions
    let selects = $(form).find('select'),
      selectedValues = [];

    selectedValues = $(form).find('select')
      .map((i, el) => {
        return el.value;
      })
      .get();

    $(form).find('select option')
      .attr('disabled', false);

    $.each(selectedValues, (i, e) => {
      if (e.length > 0) {
        selects = $(form).find('select option:selected[value!=' + e + ']')
          .parent();
        $(selects).find('option[value="' + e + '"]')
          .attr('disabled', true);
      }
    });
  }
  onQuestionChange() {
    $(document).on('change', $('#securitySetup').find('select'), (e) => {
      this.updateAvailableOptions($('#securitySetup'));
    });
  }
  postSecurityQuestions(form) {
    let data = JSON.stringify({
      data: [{
        'id': $('#question0').val(),
        'answer': $('#answer0').val()
      }, {
        'id': $('#question1').val(),
        'answer': $('#answer1').val()
      }, {
        'id': $('#question2').val(),
        'answer': $('#answer2').val()
      }]
    });
    Spinner.appendTo($(form));
    Form.disableFormField($(form).find('.submit'));
    $.ajax({
      url: requestUrls['postSecurityQuestions'],
      type: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      data: data,
      context: {
        onFail: {
          formElement: $('#securitySetup')
        }
      },
      success: (response) => {
        UserHandler.getProfile((userProfile) => {
          EventHandler.triggerEvent('signin-complete', Analytics.getUserData(userProfile));

          if (getParameterByName('catalogOfOrigin') || getParameterByName('destinationLocation') || getParameterByName('ssoType') === '2') {
            SSO.handleOutbound(window.location.href, false, true);
          } else if(getParameterByName('ssoType') === '1') {
            SSODelegate.getPoints(window.location.href, false, true);
          } else if (checkVulnerableUrl(getParameterByName('redirect'))) {
            Redirect.deeplinkRedirect();
          } else if (this.redirectUrl) {
            window.location.assign(this.redirectUrl);
          } else {
            $(document).trigger('securityquestion:setup:complete');
            if ($('#securitySetup').parents('.modal-content').length > 0) {
              Modal.close();
            }
            Spinner.remove($('#securitySecurity'));
          }
        });
      }
    });
  }
  addFormValidation() {
    let $question1Error = $($('#question0').parent()
        .find('.help-block')[0]),
      question1ErrorMessage = $question1Error.text(),
      $question2Error = $($('#question1').parent()
        .find('.help-block')[0]),
      question2ErrorMessage = $question2Error.text(),
      $question3Error = $($('#question2').parent()
        .find('.help-block')[0]),
      question3ErrorMessage = $question3Error.text(),
      $answer1Error = $($('#answer0').parent()
        .find('.help-block')[0]),
      answer1ErrorMessage = $answer1Error.text(),
      $answer2Error = $($('#answer1').parent()
        .find('.help-block')[0]),
      answer2ErrorMessage = $answer2Error.text(),
      $answer3Error = $($('#answer2').parent()
        .find('.help-block')[0]),
      answer3ErrorMessage = $answer3Error.text();

    $question1Error.remove();
    $question2Error.remove();
    $question3Error.remove();
    $answer1Error.remove();
    $answer2Error.remove();
    $answer3Error.remove();

    $('#securitySetup').formValidation({
      button: {
        selector: '.submit',
        disabled: ''
      },
      live: 'submitted',
      fields: {
        'question0': {
          validators: {
            notEmpty: {
              enabled: true,
              message: question1ErrorMessage
            }
          }
        },
        'question1': {
          validators: {
            notEmpty: {
              enabled: true,
              message: question2ErrorMessage
            }
          }
        },
        'question2': {
          validators: {
            notEmpty: {
              enabled: true,
              message: question3ErrorMessage
            }
          }
        },
        'answer0': {
          validators: {
            notEmpty: {
              enabled: true,
              message: answer1ErrorMessage
            },
            regexp: {
              regexp: /[^\*]/,
              message: answer1ErrorMessage
            }
          }
        },
        'answer1': {
          validators: {
            notEmpty: {
              enabled: true,
              message: answer2ErrorMessage
            },
            regexp: {
              regexp: /[^\*]/,
              message: answer2ErrorMessage
            }
          }
        },
        'answer2': {
          validators: {
            notEmpty: {
              enabled: true,
              message: answer3ErrorMessage
            },
            regexp: {
              regexp: /[^\*]/,
              message: answer3ErrorMessage
            }
          }
        }
      }
    })
      .on('success.form.fv', (e, data) => {
        e.preventDefault();
        this.postSecurityQuestions($(e.target));
      });
  }
}

export default new SecuritySetup();
