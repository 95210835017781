import Cookie from '../base/cookie.js';
import { _LOCALE_, requestUrls } from '../../base/vars.js';

class LanguageList {
  constructor() {
    this.name = 'language-list';
    this.$component = $('.' + this.name);
    if (this.$component.length > 0) {
      this.triggerLanguageSelect();
    }
    if (_LOCALE_ && _LOCALE_ !== 'en-us') {
      Cookie.createCookie('wrUserPreferredLanguage', _LOCALE_, 365);
    }
  }
  getLanguagePaths() {
    $.ajax({
      type: 'GET',
      url: requestUrls.languagePaths,
      data: {
        path: window.digitalData.page.pageInfo.pagePath
      },
      contentType: 'application/json'
    }).done((r) => {
      this.populateLanguagePaths(r);
    })
      .fail((r) => {
        console.log('there was an error');
      });
  }
  triggerLanguageSelect() {
    $('.top-nav-item[data-dropdown="language"]').one('click', () => {
      this.getLanguagePaths();
    });
    $('.header-mobile-button[data-panel="language"]').one('click', () => {
      this.getLanguagePaths();
    });
  }
  populateLanguagePaths(res) {
    if (res && res.length > 0) {
      $.each(res, (index, obj) => {
        $('.language-list li[data-locale=' + obj.locale + '] a').attr('href', obj.url)
          .click((e) => {
            e.preventDefault();
            e.stopPropagation();
            // Save user lang preference before redirecting
            Cookie.createCookie('wrUserPreferredLanguage', obj.locale, 365);
            window.location.assign($(e.currentTarget).attr('href'));
          });
      });
    }
  }
}

export default new LanguageList();
