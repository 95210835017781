import { isDesktopWidth } from '../../base/dom-utils.js';
import Spinner from './spinner.js';
import './flip-tile.js';
import { shuffle, EventHandler } from '../../base/utils.js';
import LazyLoad from '../../base/lazy-load.js';

// Constructor
class RedeemFilter {
  constructor() {
    this.$component = $('.redeem-filter-component');

    if (this.$component.length) {
      this.$resultsTotal = this.$component.find('.results-total');
      this.$resultsContainer = $('.tile-results');

      this.$tiles = this.$resultsContainer.find('.tile');
      shuffle(this.$tiles);
      this.$resultsContainer.empty();
      this.$resultsContainer.removeClass('hidden');

      this.$showMoreBtn = $('.show-more');
      this.$noResultsMsg = $('.no-results-message');

      this.filteredTiles = null;
      this.displayIndex = 0;
      this.displayIncrement = isDesktopWidth() ? 12 : 3; // number of tiles displayed
      this.onFilterInited = false;

      this.$showMoreBtn.click(e => this.showMoreHandler(e));

      this.$component.find('form')
        .on('change', e => this.filterInputChange(e.target))
        .on('reset', e => this.reset());

      this.filter();
    }
  }

  showMoreHandler(e) {
    this.$showMoreBtn.prop('disabled', true);
    Spinner.appendTo(this.$showMoreBtn);
    this.showTiles().always(() => {
      this.$showMoreBtn.prop('disabled', false);
      Spinner.remove(this.$showMoreBtn);
      LazyLoad.bindNewElement();
    });
  }

  reset() {
    // Workaround to run filter after inputs have been reset
    setTimeout(_.bind(this.filter, this));
  }


  filterInputChange(changedInput) {
    let $changedInput = $(changedInput), event;

    switch ($changedInput.attr('name')) {
    case 'activity':
      // Temporary fix for issue with JQuery events not always firing properly
      // this.$component.find('.select-menu-dropdown').trigger('reset');
      event = document.createEvent('Event');
      event.initEvent('reset', false, true);
      this.$component.find('.select-menu-dropdown')[0].dispatchEvent(event);
      break;
    case 'destination':
      this.$component.find('input[name="activity"]').each((i, el) => {
        let $activity = $(el);
        $activity.prop('checked', false);
      });
      break;
    }

    this.filter();
  }

  getFilteredTilesCount() {
    return this.filteredTiles ? this.filteredTiles.length : 0;
  }

  updateTotalResults() {
    this.$resultsTotal.text(this.getFilteredTilesCount());
  }

  handleShowMoreButton() {
    if (this.displayIndex < this.getFilteredTilesCount()) {
      this.$showMoreBtn.removeClass('hidden');
    } else {
      this.$showMoreBtn.addClass('hidden');
    }
  }

  handleErrorMessage() {
    if (this.getFilteredTilesCount() > 0) {
      this.$noResultsMsg.addClass('hidden');
    } else {
      this.$noResultsMsg.removeClass('hidden');
    }
  }

  showTiles() {
    let endIndex = this.displayIndex + this.displayIncrement;
    let $displayTiles = $(this.filteredTiles.slice(this.displayIndex, endIndex));

    // If the user changes the filters before the previous request has been resolved the
    // old request needs to be cancelled otherwise filters and tiles can get out of sync.
    if (this.pictureRequest && this.pictureRequest.state() === 'pending') {
      this.pictureRequest.reject(new Error('Stale Request'));
    }

    this.pictureRequest = new $.Deferred()
      .done(() => {
        if (this.displayIndex > 0) {
          this.$resultsContainer.append($displayTiles);
        } else {
          this.$resultsContainer.html($displayTiles);
        }

        this.displayIndex = endIndex;

        this.handleShowMoreButton();
        this.handleErrorMessage();
      });

    this.pictureRequest.resolve();

    return this.pictureRequest.promise();
  }

  getDropdownTxt(value) {
    let dropdownOptions = this.$component.find('.dropdown-content .select-menuitem'),
      optionTxt = '';

    dropdownOptions.each((index, elm) => {
      let option = $(elm);
      if (option.data('value') === value) {
        optionTxt = option.text();
        return;
      }
    });
    return optionTxt;
  }

  filterAnalytics(filters, status) {
    let searchName = 'search',
      search = 'none',
      miscSearch,
      types = 'none',
      miscTypes;

    if (filters.activity && filters.activity.length) {
      searchName = 'activity';
      miscSearch = _.sortBy(filters.activity, 'value');
      search = _.map(miscSearch, (obj) => {
        return obj.value;
      }).join(',');
    } else if (filters.destination && filters.destination[0].value !== '') {
      searchName = 'destination';
      search = this.getDropdownTxt(filters.destination[0].value).toLowerCase();
    }

    if (filters.type && filters.type.length) {
      miscTypes = _.sortBy(filters.type, 'value');
      types = _.map(miscTypes, (obj) => {
        return obj.value;
      }).join(',');
    }

    EventHandler.triggerEvent(status, {
      filters: searchName + ':' + search + '|' + 'filter:' + types
    });
  }

  // Tile filter function
  _filter(filters) {
    return _.filter(this.$tiles, (tile) => {
      let $tile = $(tile);

      let activityMatch = true; // No filter specified = match
      if (filters.activity) {
        activityMatch = _.find(filters.activity, function(activity) {
          return _.contains($tile.data('activities'), activity.value);
        });
      }

      let destinationMatch = true; // No filter specified = match
      if (filters.destination) {
        destinationMatch = _.find(filters.destination, function(dest) {
          return dest.value === '' || dest.value === $tile.data('location');
        });
      }

      let typeMatch = true; // No filter specified = match
      if (filters.type) {
        typeMatch = _.find(filters.type, {
          value: $tile.data('type')
        });
      }

      return activityMatch && destinationMatch && typeMatch;
    });
  }

  // Run filter process and updates UI
  filter() {
    this.$resultsContainer.empty();
    this.$showMoreBtn.addClass('hidden');
    this.$noResultsMsg.addClass('hidden');

    Spinner.appendTo(this.$resultsContainer);

    this.displayIndex = 0;

    let $form = this.$component.find('form');
    let inputValues = $form.serializeArray();
    let filters = _.groupBy(inputValues, 'name');

    this.filteredTiles = this._filter(filters);

    // Reset tiles back to front view
    this.$tiles.each((i, el) => {
      let $tile = $(el);
      $tile.removeClass('flip');
      $tile.removeClass('unflip');
    });

    this.updateTotalResults();
    this.showTiles().done(() => {
      Spinner.remove(this.$resultsContainer);
    });

    if (this.getFilteredTilesCount() && this.onFilterInited) {
      this.filterAnalytics(filters, 'filter-results');
    } else if (!this.getFilteredTilesCount()) {
      this.filterAnalytics(filters, 'zero-filter-results');
    }
    this.onFilterInited = true;
    EventHandler.send(EventHandler.lazyLoad.rebind);
  }
}

export default new RedeemFilter();
