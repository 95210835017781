
import { BBForm } from '../../components/booking-bar.js';
import { EventHandler } from '../../base/utils.js';

class SearchResultHeading {
  constructor() {
    if ($('.search-result-heading .search-location').length > 0) {
      this.bindDestinationPopulate();
    }
  }

  bindDestinationPopulate() {
    EventHandler.one(EventHandler.criteria.init, () => {
      $('.search-result-heading .search-location').text(BBForm.getDestination());
    });
  }
}
export default new SearchResultHeading();
