import { FTSI, templateUrls, _LOCALE_ } from '../../base/vars';
import { setLocalStorage, getLocalStorage } from '../../base/session-handler';
import { getServiceUrl, getJsonObject } from '../../base/utils.js';
import PhoneInput from '../components/phone-input';
import Spinner from '../components/spinner.js';

class VerificationMethods {
  constructor() {
    this.ftsiEmail = 'ftsi-email';
    const urlWRVerificationMethods = templateUrls.verificationMethods;
    this.isVerificationMethodsPage = window.location.href.includes(urlWRVerificationMethods);

    if (this.isVerificationMethodsPage) {
      this.initFormContainer();
      this.initSubmitButton();
      this.initSendCodeText();
      Spinner.remove($('.verificationMethod'));
    }
  }

  initFormContainer() {
    document.querySelector('.app-step-container').style.width = '75%';
  }

  initSendCodeText() {
    const verificationMethodRadios = document.querySelectorAll('input[name = "verificationChoice"]'),
      ftsiInfo = getLocalStorage(FTSI);
    const sendCodeText = document.querySelector('.send-code');
    const sendCodePhone = sendCodeText.querySelector('#phoneNumber'),
      sendCodeEmail = sendCodeText.querySelector('#emailAddress');

    sendCodeText.classList += ' form-legal';

    sendCodeEmail.textContent = ftsiInfo.emails[0].email;

    if (ftsiInfo.phones){
      sendCodePhone.textContent = ftsiInfo.phones[0].phoneNumber;
    } else {
      this.createOktaAccount(ftsiInfo, this.ftsiEmail);
    }

    verificationMethodRadios.forEach(verificationMethodRadio => {
      verificationMethodRadio.addEventListener('click', () => {
        if (verificationMethodRadio.checked && verificationMethodRadio.value === 'Email') {
          sendCodePhone.classList.add('hidden');
          sendCodeEmail.classList.remove('hidden');
        } else if (verificationMethodRadio.checked && verificationMethodRadio.value !== 'Email') {
          sendCodeEmail.classList.add('hidden');
          sendCodePhone.classList.remove('hidden');
        }
      });
    });
  }

  initSubmitButton() {
    const ftsiInfo = getLocalStorage(FTSI);
    const submitButton = document.querySelector('.submit');
    //if (ftsiWithVerificationCodes && ftsiWithVerificationCodes.phones && ftsiWithVerificationCodes.phones[0] && ftsiWithVerificationCodes.phones[0].phoneNumber) {
    submitButton.addEventListener('click', () => {
      Spinner.appendTo($('.formcontainer'));
      const selectedVerificationMethod = document.querySelector('input[name = "verificationChoice"]:checked').value;
      if (selectedVerificationMethod !== 'Email') {
        const flow = selectedVerificationMethod === 'Text' ? 'sms' : 'voice';
        const ftsiWithSms = {
          ...ftsiInfo,
          type: flow,
        };
        setLocalStorage(FTSI, ftsiWithSms);
        this.createOktaAccount(ftsiInfo, flow);
      } else if (selectedVerificationMethod === 'Email') {
        this.createOktaAccount(ftsiInfo, this.ftsiEmail);
      }
    });
  }

  createEnrollRequest(data, flow) {
    const tallyStatusCodeMap = {
      'A': 'active',
      'INACTIVE': 'inactive',
      'I': 'suspended',
      'P': 'pending',
      'C': 'closed',
      'S': 'shell'
    };
    const phoneNumber = data.phones && data.phones.length > 0 && data.phones[0].phoneNumber ? 
      this.getValidPhone(data.phones[0].phoneNumber, 0) : '';
    const oktaEnrollRequestTemplate = {
      // challengeType and type required but not used when flow is set to ftsi-email
      challengeType: 'phone',
      phoneNumber: phoneNumber,
      email: data.emails[0].email,
      name: data.firstName,
      nickName: data.firstName,
      userName: `${data.emails[0].email}.`,
      userMetadata: {
        membershipNumber: data.memberIdentifier,
        tallyStatus: data.accountStatusCode ? 
          tallyStatusCodeMap[data.accountStatusCode.toUpperCase()] : 'inactive',
        prefLang: _LOCALE_,
      }
    };

    const oktaEnrollRequest = flow === this.ftsiEmail ?
      {
        ...oktaEnrollRequestTemplate,
        type: 'sms',
        userMetadata: {
          ...oktaEnrollRequestTemplate.userMetadata,
          flow
        }
      } :
      {
        ...oktaEnrollRequestTemplate,
        type: flow,
        password: 'Sample@123'
      };

    return oktaEnrollRequest;
  }

  createOktaAccount(data, flow) {
    const ftsiInfo = getLocalStorage(FTSI);
    const oktaEnrollRequest = this.createEnrollRequest(data, flow);
    const urlParams = new URLSearchParams(window.location.search),
      isPhApp = DOMPurify.sanitize(urlParams.has('platform')) && DOMPurify.sanitize(urlParams.has('adobeID')),
      platform = (isPhApp && urlParams.get('platform')) || '';
    const ftsiWithPreferredVerificationMethod = {
      ...ftsiInfo,
      ...data,
      isPhApp,
      platform
    };
    setLocalStorage(FTSI, ftsiWithPreferredVerificationMethod);
    if (flow === this.ftsiEmail) {
      Spinner.appendTo($('.formcontainer'));
      $.ajax({
        url: getServiceUrl('onlineAccountSetup'),
        contentType: 'application/json',
        data: JSON.stringify(oktaEnrollRequest),
        type: 'POST',
        success: (res) => {
          const ftsiWithVerificationCodes = {
            ...ftsiWithPreferredVerificationMethod,
            ...res
          };
          setLocalStorage(FTSI, ftsiWithVerificationCodes);
          const localEmailConfirmationPage = '/' + _LOCALE_ + templateUrls.emailConfirmation;
          if (isPhApp){
            window.location.assign(localEmailConfirmationPage + '?' + urlParams);
          } else {
            window.location.assign(localEmailConfirmationPage);
          }
        },
        error: responseErr => {
          const res = getJsonObject(JSON.stringify(responseErr));
          console.error(res);
          Spinner.remove($('.formcontainer'));
          if ($('#profile-error-message').length && res && res.responseJSON && res.responseJSON.ErrorMessage){
            $('#profile-error-message').html(DOMPurify.sanitize(res.responseJSON.ErrorMessage));
          }
        },
      });
    } else {
      const localCreatePasswordPage = '/' + _LOCALE_ + DOMPurify.sanitize(templateUrls.firstTimeSignInOkta);
      if (isPhApp){
        window.location.assign(DOMPurify.sanitize(localCreatePasswordPage) + '?' + DOMPurify.sanitize(urlParams));
      } else {
        window.location.assign(DOMPurify.sanitize(localCreatePasswordPage));
      }
    }
  }

  getValidPhone(phoneNumber, index) {
    return PhoneInput.getPhoneNumberWithCountryCode(phoneNumber, index);
  }

}

export default new VerificationMethods();
