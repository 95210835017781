
import { ReservationHandler } from '../../base/session-handler.js';

class ViewResConfirmationLink {
  constructor() {
    this.bindResConfirmationLinkClick();
  }
  bindResConfirmationLinkClick() {
    $(document).on('click', '.view-res-confirmation-link', (e) => {
      e.preventDefault();

      let trigger = $(e.currentTarget),
        firstName = trigger.data('first'),
        lastName = trigger.data('last'),
        conf = trigger.data('conf');

      console.log('Conf & name: ' + conf + '- ' + firstName + ' ' + lastName);

      let $tempForm = document.createElement('form'),
        $nop = document.createElement('input');

      $nop.setAttribute('type', 'hidden');
      $nop.setAttribute('name', ':operation');
      $nop.setAttribute('value', 'nop');

      $tempForm.setAttribute('method', 'POST');
      $tempForm.setAttribute('id', 'revReservForm');
      $tempForm.appendChild($nop);

      $('body').append($tempForm);

      ReservationHandler.setReviewReservation({
        firstName: firstName,
        lastName: lastName,
        confNum: conf + ''
      });

      ReservationHandler.retrieveReservation();
    });
  }
}

export default new ViewResConfirmationLink();
