import Cookie from './cookie.js';
import {
  formatDateForPrinting,
  getDateFromDateString,
  formatNumber,
  isValidNumber,
  mapBusinessTravelerString
} from '../../base/utils.js';
import { _SEO_LOCALE_, requestUrls, templateUrls } from '../../base/vars.js';
import { hasMilitaryStatus } from './status.js';
class DataBinding {
  constructor() {
    window.DataBinding = {
      LoyaltyLevelProgress: this.loyaltyLevelProgress(),
      MemberServices: this.memberServices(),
      UserLoyalty: this.userLoyalty(),
      UserPreferences: this.userPreferences(),
      Aliases: this.userAliases()
    };

    // On lightbox open, bind data to modal contents as needed
    $(document).on('wr.modal.open', () => {
      if (window.User && typeof window.User === 'object') {
        this.renderData(this.gatherAllData(), '.modal [data-binding]');
      } else {
        this.renderData(window.DataBinding, '.modal [data-binding]');
      }
    });
  }
  gatherAllData() {
    return _.assign({}, window.User, window.DataBinding.LoyaltyLevelProgress, window.DataBinding.MemberServices, window.DataBinding.UserLoyalty, window.DataBinding.UserPreferences);
  }
  renderData(obj, selectors, omittedKeys) {
    obj = obj || window.User;
    selectors = selectors || '[data-binding]';

    $(selectors).each((i, el) => {
      let $el = $(el);

      if ($el.parents('.no-recognized').length && !Cookie.isAuthenticated()) {
        return;
      }

      let bindingPath = $el.data('binding'),
        parts = [];

      if(omittedKeys && (omittedKeys.indexOf(bindingPath) > -1)){
        return;
      }

      if (bindingPath) {
        parts = bindingPath.indexOf('.') > -1 ? bindingPath.split('.') : bindingPath;
      }
      if (bindingPath && parts.length > 0) {
        let val = obj,
          startIndex = 0,
          found = true;

        if (!(val)) {
          val = window[parts[0]];
          startIndex = 1;
        }

        if (val && typeof val !== 'undefined') {
          for (let i = startIndex; i < parts.length; i++) {
            let key = parts[i];
            if (key) {
              if (!_.isUndefined(val[key])) {
                val = val[key];
                continue;
              }
            }
            found = false;
            break;
          }

          if (found) {
            if (typeof val === 'string' || typeof val === 'number') {
              if ($el.is('input[type="text"]')) {
                $el.val(val).trigger('change');
              } else if ($el.is('select')) {
                let country = null;

                if ($el.attr('id') === 'state0') {
                  country = obj.addresses[0].countryCode;
                  if (window.location.href.includes(templateUrls.profilePage)) {
                    $el.find('option')[0].text = obj.addresses[0].stateCode;
                    $el.parent().removeClass('empty');
                  }
                } else if ($el.attr('id') === 'state1') {
                  country = obj.addresses[1].countryCode;
                  if (window.location.href.includes(templateUrls.profilePage)) {
                    $el.find('option')[0].text = obj.addresses[1].stateCode;
                    $el.parent().removeClass('empty');
                  }
                }
                if (country && (country === 'CA' || country === 'US' || (country === 'CN' && val != 'NA'))) {
                  val = country + '_' + val;
                }
                let match = $el.find('option').filter((i,el) => {
                  return el.value.toString().toLowerCase() == val.toString().toLowerCase();
                })
                  .attr('value');
                if (match) {
                  $el.val(match).change();
                } else {
                  $el.prop('selectedIndex', 0);
                }
                if ($el.attr('id') === 'addressType0') {
                  $el.val(this.getAddressTypeVal(val));
                  $el.parent().removeClass('empty');
                }
                if ($el.attr('id') === 'preferredLanguage') {
                  const optionKeys = [];
                  $el.find('option').each((k,v) => {
                    if (v.value != '') {
                      optionKeys.push(v.value);
                    }
                  });
                  $el.val(optionKeys.includes(val) ? val : 'en-us');
                  $el.parent().removeClass('empty');
                }
                if ($el.attr('id') === 'businessEnrollmentSelect' && mapBusinessTravelerString(val)) {
                  $el.closest('.business-enrollment-wrapper').find('#businessEnrollmentCheckbox').prop('checked', true);
                  $el.val(mapBusinessTravelerString(val));
                  $el.parent().removeClass('empty');
                }
                if ($el.attr('id') === 'hotelBrand') {
                  $el.val(val);
                  $el.parent().removeClass('empty');
                }
              } else if ($el.is('input[type="checkbox"], input[type="radio"]')) {
                const marketingPrefIds = ['preferred', 'preferredThirdParty', 'preferredSMSMarketing'];
                if (marketingPrefIds.includes($el.attr('id'))) {
                  $el.prop('checked', val === 'true');
                } else if ($el.attr('id') === 'businessEnrollmentCheckbox') {
                  $el.prop('checked', val != '');
                } else if ($el.is('textarea')) {
                  $el.html(val);
                } else {
                  $el.text(isValidNumber(val) ? formatNumber(val) : val);
                }
              } else if ($el.is('textarea')) {
                $el.html(val);
              } else {
                $el.text(isValidNumber(val) ? formatNumber(val) : val);
              }
            } else if (typeof val === 'object') {
              if (val && val.phoneNumber && $el.is('a')) {
                this.updateMemberServicesBinding($el, val);
              }
            }
          }
        }
      }
    });

    $(document).trigger('user:binding:complete');
  }
  userPreferences() {
    let UserPreferences = {
      preferences: {}
    };

    $(document).on('user:profile:update', (e, user) => {
      user = (typeof user === 'string') ? JSON.parse(user) : '';
      if (user && user.preferences) {
        let prefs = user.preferences;

        for (let i = 0; i < prefs.length; i++) {
          UserPreferences.preferences[prefs[i].preferenceRuleDetailCode] = prefs[i].value;
        }
        this.renderData(UserPreferences, '[data-binding^=preferences]');
      }
    });

    return UserPreferences;
  }
  userAliases() {
    let UserAliases = {
      aliases: {}
    };

    $(document).on('user:profile:update', (e, user) => {
      user = (typeof user === 'string') ? JSON.parse(user) : '';
      if (user && user.aliases) {
        let aliases = user.aliases;
        // DAI-36811: reset UserAliases that was stored so that it would get properly refreshed. 
        UserAliases = {
          aliases: {}
        };

        for (let i = 0; i < aliases.length; i++) {
          UserAliases.aliases[aliases[i].typeCode] = {
            partnerProgramCode: aliases[i].partnerProgramCode,
            alias: aliases[i].alias
          };
        }
      }

      if (UserAliases.aliases.PartnerPreferred && $('#earnPoints') && $('#earnAirfare')) {
        let prefPointsMiles = localStorage.getItem('PREF_POINTS_MILES') ? localStorage.getItem('PREF_POINTS_MILES') : '';
        if(prefPointsMiles === 'earnAirfare') {
          $('#earnPoints').prop('checked', false);
          $('#earnAirfare').prop('checked', true);
        } else {
          $('#earnAirfare').prop('checked', false);
          $('#earnPoints').prop('checked', true);
        }
        $('#earnAirfare').trigger('change');
      }

      this.renderData(UserAliases, '[data-binding^=aliases]');
    });

    return UserAliases;
  }
  loyaltyLevelProgress() {
    let obj = {
      LoyaltyLevelProgress: {
        nextLevel: {}
      }
    };

    $(document).on('user:profile:update', (e, user) => {
      user = (typeof user === 'string') ? JSON.parse(user) : '';

      if (user && user.loyaltyLevelProgress) {
        obj.projectedTier.endDate = formatDateForPrinting(user.projectedTier.endDate);
        this.renderData(obj, '[data-binding^=LoyaltyLevelProgress]');
      }
    });

    return obj;
  }
  userLoyalty() {
    let UserLoyalty = {
      Channel: {
        WR: {}
      }
    };

    $(document).on('user:profile:update', (e, user) => {
      user = (typeof user === 'string') ? JSON.parse(user) : '';
      if (user && user.CustLoyalty) {
        let loyalty = user.CustLoyalty;

        for (let key in loyalty) {
          UserLoyalty.Channel[loyalty[key].ProgramID] = loyalty[key];
        }
      }

      if ('SignupDate' in UserLoyalty.Channel.WR) {
        UserLoyalty.Channel.WR.SignupDate = formatDateForPrinting(UserLoyalty.Channel.WR.SignupDate, 'textMonthLong');
      }
      if (user && user.enrollmentDateTime) {
        UserLoyalty.Channel.WR.SignupDate = formatDateForPrinting(user.enrollmentDateTime);
      }
      if ('ExpireDate' in UserLoyalty.Channel.WR) {
        UserLoyalty.Channel.WR.ExpireDate = formatDateForPrinting(UserLoyalty.Channel.WR.ExpireDate);
        UserLoyalty.Channel.WR.RedeemBy = UserLoyalty.Channel.WR.ExpireDate;
      }
      const currencyObj = user && user.currencies ? 
          user.currencies.find(item => item.typeCode === 'POINTS') : null,
        forfeitureDate = currencyObj && currencyObj.pointForfeitureDate ? currencyObj.pointForfeitureDate : '';
      if (forfeitureDate) {
        UserLoyalty.Channel.WR.ExpireDate = formatDateForPrinting(forfeitureDate);
        UserLoyalty.Channel.WR.RedeemBy = forfeitureDate;
      }
      if (this.hasPointBucketObject(user) && this.hasCustLoyaltyObject(user)) {
        let pointBucket = getDateFromDateString(forfeitureDate),
          custLoyalty = new Date(UserLoyalty.Channel.WR.ExpireDate);
        UserLoyalty.Channel.WR.PointBucket = {};
        UserLoyalty.Channel.WR.PointBucket.ExpirationDate = formatDateForPrinting(pointBucket);
        if (pointBucket < custLoyalty) {
          UserLoyalty.Channel.WR.RedeemBy = UserLoyalty.Channel.WR.PointBucket.ExpirationDate;
        }
      }

      this.renderData(UserLoyalty, '[data-binding^=Channel]');
    });

    return UserLoyalty;
  }
  memberServices() {
    let MemberServices = {
      MemberServices: {
        Telephones: []
      }
    };

    if ($('.member-services-tel').length && !$('.member-services-tel').parents('.talk-to-us').length) {
      $(document).on('user:profile:update', (e, user) => {
        user = (typeof user === 'string') ? JSON.parse(user) : '';
        // re-integrate 8/15/23
        //MemberServices.MemberServices.Telephones = this.setMemberServicesNumber(user);
      });
    }

    return MemberServices;
  }
  setMemberServicesNumber(userData) {
    let user = userData || window.User;
    let MemberServices = {
      MemberServices: {
        Telephones: []
      }
    };

    if (typeof user !== 'undefined' &&
        $('.member-services-tel').length && !$('.member-services-tel').parents('.talk-to-us').length) {
      let memberLevel = 'blue';
      if (user.currentTier) {
            memberLevel = user.currentTier.description;
      }
      /******************************************************************************************************
       * Defaulting country to the SEO specific country based on locale.                                    *
       * This really shouldn't be needed, but it should protect from the off chance a user doesn't have a   *
       * usable country.                                                                                    *
       ******************************************************************************************************/
      let country = 'US';
      if (typeof _SEO_LOCALE_ !== 'undefined' && _SEO_LOCALE_) {
        if (_SEO_LOCALE_ === 'es-xl') {
          country = 'MX';
        } else if (_SEO_LOCALE_ === 'en-ap') {
          country = 'AU';
        } else if (_SEO_LOCALE_ === 'en-uk') {
          country = 'GB';
        }
      }

      if (user.addresses) {
        for (let address of user.addresses) {
          if (address.DefaultInd) {
            country = address.countryCode;
          }
        }
      }

      let military = '';
      if(typeof user !== 'undefined' && user && user.CustLoyalty){
        military = hasMilitaryStatus() ? 'military' : '';
      }

      this.getMemberServicesNumber(memberLevel, country, military)
        .done((data) => {
          MemberServices.MemberServices.Telephones = data.data;
          this.renderData(MemberServices, '[data-binding^=MemberServices]');
        });
    }
    return MemberServices.MemberServices.Telephones;
  }
  getMemberServicesNumber(memberLevel, country, military) {
    return $.get(requestUrls.memberServices, {
      level: memberLevel,
      country: country,
      status: military
    });
  }
  updateMemberServicesBinding(elm, val) {
    $(elm).attr('href', 'tel://' + val.phoneNumber)
      .text(val.description || val.phoneNumber);
  }
  hasCustLoyaltyObject(obj) {
    obj = obj || window.User;

    return (
      obj && obj.CustLoyalty && obj.CustLoyalty.length > 0
    );
  }
  hasPointBucketObject(obj) {
    obj = obj || window.User;
    const currencyObj = obj && obj.currencies ? 
      obj.currencies.find(item => item.typeCode === 'POINTS') : null;
    return currencyObj && currencyObj.available;
  }
  getAddressTypeVal(val) {
    switch (val) {
    case 'Home':
      return '1';
    case 'Business':
      return '2';
    default:
      return '0';
    }
  }
}

export default new DataBinding();
