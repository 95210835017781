import Handlebars from 'handlebars';
import { exists } from '../../base/dom-utils';
import { getLocalStorage } from '../../base/session-handler';
import { ENROLLMENT, FTSI } from '../../base/vars';

class VerificationComplete {
  constructor() {
    if (exists($('.verificationComponent'))) {
      this.initHandleBars();
      this.initMobileButton();
    }
  }

  initHandleBars() {
    const userData = this.getUserDataFromLocalStorage();
    if (!userData) {
      return;
    }
    const source = $('.verificationComponent').html();
    const template = Handlebars.compile(source);
    let handlebars = {};
    let modifiedHtml = {};
    if (userData.phones.length > 0) {
      handlebars = { phoneNumber: userData.phones[0].phoneNumber, email: userData.emails[0].email };
    } else if (userData.emails.length > 0) {
      handlebars = { email: userData.emails[0].email };
    }

    const html = template(handlebars);
    if (userData.emails && userData.emails.length > 0) {
      var searchText = '${email}';
      var replacementText = userData.emails[0].email;
      modifiedHtml = html.replace(searchText, replacementText);
    } else {
      modifiedHtml = html;
    }
    $('.verificationComponent').html(modifiedHtml);
  }

  initMobileButton() {
    const urlParams = new URLSearchParams(window.location.search),
      hasPhAppParams = urlParams.has('platform') && urlParams.has('adobeID'),
      isStatusMatch = urlParams.has('status-match'),
      ftsiInfo = getLocalStorage(FTSI),
      enrollmentInfo = getLocalStorage(ENROLLMENT),
      mobileButton = $('#mobileVerificationButton'),
      siteButton = $('#verificationButton');
    let hasPhAppFlag = false;

    if (ftsiInfo && ftsiInfo.isPhApp) {
      hasPhAppFlag = true;
    }
    if (enrollmentInfo && enrollmentInfo.isPhApp) {
      hasPhAppFlag = true;
    }
    if ((hasPhAppParams || hasPhAppFlag) && mobileButton.length > 0) {
      mobileButton.removeClass('hidden');
      siteButton.addClass('hidden');
    }
    if (!(hasPhAppParams || hasPhAppFlag) && isStatusMatch && siteButton.length > 0) {
      const href = siteButton.attr('href') + '?status-match';
      siteButton.attr('href', href);
    }
  }

  getUserDataFromLocalStorage() {
    const enrollmentInfo = getLocalStorage(ENROLLMENT);
    const ftsiInfo = getLocalStorage(FTSI);
    if (ftsiInfo && ftsiInfo.phones) {
      return ftsiInfo;
    } else if (enrollmentInfo && enrollmentInfo.phones) {
      return enrollmentInfo;
    }
    return null;
  }
}

export default new VerificationComplete();
