import { getLocaleUrlToken, getServiceUrl, isValidOktaPassword } from '../base/utils.js';
import { FTSI, _LOCALE_, templateUrls } from '../base/vars.js';
import { exists } from '../base/dom-utils.js';
import { getLocalStorage, setLocalStorage } from '../base/session-handler.js';
import Join from '../wr/components/join.js';
import Spinner from '../wr/components/spinner.js';
class CreatePassword {
  constructor() {
    if (exists($('#createPassword'))) {
      this.disableSubmitButton();
      this.initVerifyBy();
      this.initSubmitFunction();
      this.initFormSpacing();

      this.initPasswordMatcher();
      Join.validatePasswordConfirmation();
      Join.initViewPassword();
      Join.showPasswordRequirements();
    }
  }

  disableSubmitButton() {
    $('.submit').prop('disabled', !this.isValidPassword());
  }

  initSubmitFunction() {
    const form = $('#createPassword');
    if (form) {
      $('button.submit').on('click', () => {
        if (this.isValidPassword()) {
          Spinner.appendTo(form);
          const urlParams = new URLSearchParams(window.location.search),
            isPhApp = urlParams.has('platform') && urlParams.has('adobeID');
          const verifyUrl = `${getLocaleUrlToken()}${templateUrls.verifyIdentityPage}`;
          const passwordInput = $('input#password');
          const ftsiInfo = getLocalStorage(FTSI);
          if (!ftsiInfo) {
            return;
          }

          const tallyStatusCodeMap = {
            'A': 'active',
            'INACTIVE': 'inactive',
            'I': 'suspended',
            'P': 'pending',
            'C': 'closed',
            'S': 'shell'
          };

          const data = {
            'phoneNumber': this.getValidPhone(ftsiInfo.phones[0].phoneNumber),
            'challengeType': 'phone',
            'type': ftsiInfo.type,
            'email': ftsiInfo.emails[0].email,
            'name': ftsiInfo.firstName,
            'nickName': ftsiInfo.firstName,
            'password': passwordInput.val(),
            'userMetadata': {
              'membershipNumber': ftsiInfo.memberIdentifier,
              'tallyStatus': ftsiInfo.accountStatusCode ? 
                tallyStatusCodeMap[ftsiInfo.accountStatusCode.toUpperCase()] : 'inactive',
              'prefLang': _LOCALE_
            },
            'userName': `${ftsiInfo.emails[0].email}.`
          };
          // ajax call, if success move on
          $.ajax({
            url: getServiceUrl('onlineAccountSetup'),
            contentType: 'application/json',
            data: JSON.stringify(data),
            type: 'POST',
            success: (res) => {
              console.log(res);
              const ftsiInfo = getLocalStorage(FTSI);
              const ftsiWithVerificationCodes = {
                ...ftsiInfo,
                ...res,
              };
              setLocalStorage(FTSI, ftsiWithVerificationCodes);
              if (isPhApp){
                window.location.assign(verifyUrl + '?' + urlParams);
              } else {
                window.location.assign(verifyUrl);
              }
            },
            error: res => {
              console.log(res);
            },
            complete: res => {
              Spinner.remove(form);
            }
          });
        }
      });
    }
  }

  isValidPassword() {
    const passwordField = $('input#password');
    const passwordMatches = passwordField.val() === $('#confirmPassword').val();
    return isValidOktaPassword(passwordField.val()) && passwordMatches;
  }

  initPasswordMatcher() {
    const newPassword = $('#password');
    const confirmNewPassword = $('#confirmPassword');
    let showError = false;

    confirmNewPassword.on('input', (e) => {
      const password = newPassword.val();
      const confirmedPassword = e.currentTarget.value;

      if (password.length == confirmedPassword.length && !showError) {
        showError = true;
      }
      if (showError) {
        Join.toggleErrorMessage(password, confirmedPassword);
      }
    });

    confirmNewPassword.on('blur', (e) => {
      const password = newPassword.val();
      const confirmedPassword = e.currentTarget.value;
      showError = true;
      Join.toggleErrorMessage(password, confirmedPassword);
      $(this).off(e);
    });
  }

  initVerifyBy() {
    const ftsiInfo = getLocalStorage(FTSI);
    const verifyByText = $('#textVerificationRadio'),
      verifyByVoice = $('#voiceVerificationRadio');
    let verifyBy = 'text';

    if (ftsiInfo) {
      verifyBy = ftsiInfo['verifyBy'] || 'text';
    }

    if (verifyBy === 'voice') {
      verifyByText.prop('checked', false);
      verifyByVoice.prop('checked', true);
    }
  }

  initFormSpacing() {
    $('.main-container').addClass('mobile-form-margin');
  }

  getValidPhone(phonenumber) {
    return phonenumber.includes('+') ? phonenumber : `+${phonenumber}`;
  }
}

export default new CreatePassword();
