import {CookieHandler} from '../../base/utils.js';

class FooterListDropdown {
  constructor() {
    if ($('footer .footer-list-dropdown').length > 0) {
      this.bindDropdowns();
    }
    if ($('#android-app-download').length > 0) {
      if(CookieHandler.readCookie('country_code') && CookieHandler.readCookie('country_code').toString() != 'CN') {
        $('#android-app-download').css('background-image',$('#android-app-download').attr('data-src')).removeAttr('data-src');
      } else {
        $('#android-app-download').css('display','none').removeAttr('data-src');
      }
    }
  }
  bindDropdowns() {
    $(document).on('click', 'footer .footer-list-dropdown .dropdown-toggle', (e) => {
      let width = $(window).width();
      if (width < 720) {
        $(e.currentTarget).parent()
          .find('.dropdown-toggle')
          .toggleClass('open');
        $(e.currentTarget).parent()
          .find('.dropdown')
          .slideToggle();
      }
    });
	$(document).on('click', '.website-feedback', (e) => {
      let kampyleId = 24378;
      if(window.KAMPYLE_ONSITE_SDK) {
        if (window.KAMPYLE_ONSITE_SDK.showForm(kampyleId) === false) {
          console.error(`KAMPYLE_ONSITE_SDK was not able to execute the showForm call using id ${kampyleId}`);
        }
      } else {
          console.error("KAMPYLE_ONSITE_SDK is not available.");
      }
      e.preventDefault();
      e.stopPropagation();
    });
  }
}

export default new FooterListDropdown();
