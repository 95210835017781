
class PreferencesComponent {
  constructor() {
    if ($('.preferences-component').length > 0) {
      this.onRadioOptionClick();
      this.switchPreferences();
    }
  }
  onRadioOptionClick() {
    $('#rewards-options input[name="preferredrewards"]').on('change', () => this.switchPreferences());
  }
  switchPreferences() {
    let divKey = $('.preferences-component input[name=\'preferredrewards\']:checked').val();
    $('.preferences-component .more-options').hide();
    $(`#${divKey}`).show();
  }
}

export default new PreferencesComponent();
