
class FlipTile {
  constructor() {
    this.bindFlipTileClick();
  }
  bindFlipTileClick() {
    $(document).on('click', '.flip-tile:not(.no-flip) .flip-control', (e) => {
      e.preventDefault();
      let $tile = $(e.currentTarget).closest('.flip-tile');
      if ($tile.hasClass('flip') || $tile.hasClass('unflip')) {
        $tile.toggleClass('flip').toggleClass('unflip');
      } else {
        $tile.addClass('flip');
      }
    });
  }
}

export default new FlipTile();
