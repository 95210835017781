
import Modal from './modal.js';

class PromoTout {
  constructor() {
    if ($('.promo-tout-component').length > 0) {
      this.bindOpenBookingModal();
    }
  }
  bindOpenBookingModal() {
    $(document).on('click', '.open-booking-modal', (e) => {
      e.preventDefault();
      Modal.show();
    });
  }
}

export default new PromoTout();
