import Cookie from '../base/cookie.js';
import DataBinding from '../base/data-binding.js';
import Spinner from './spinner.js';
import { formatDateForPrinting, _isNull, mapTierCode, getJsonObject } from '../../base/utils.js';
import {
  requestUrls
} from '../../base/vars.js';
import MemberLevelTracking from './member-level-tracking.js';

class PromotionOffers {
  constructor() {
    if ($('.promo-offers-component').length > 0) {
      $(document).on('user:profile:updated', () => {
        this.getPromotions();
      });
    }
  }
  getPromotions() {
    if (requestUrls.getPromotions) {
      $.ajax({
        url: requestUrls.getPromotions,
        type: 'GET',
        success: (responseData) => {
          const response = getJsonObject(responseData);
            let accelerators = [];
            const LEVELs = ['GOLDCHAL', 'PLATCHAL', 'DIACHAL']; 
            let isLevelNotRegistered = true;
            let program = '';
            if(window.User && window.User.aspirationTiers) {
             let currentLevelWithoutStar = window.User && window.User.currentTier && window.User.currentTier.description.split('*').join('');
              program = window.User.aspirationTiers.find(item => item.description.toLowerCase() === currentLevelWithoutStar.toLowerCase()); 
            }
            for ( let i = 0 ; i < response.data.length; i++) {
              if (response.data && response.data[i] && response.data[i].counters 
                  && LEVELs.includes(response.data[i].promotionCode) && (response.data[i].registered === true)) {
                accelerators[accelerators.length] = {
                  "level": window.User && window.User.currentTier && window.User.currentTier.description.toUpperCase(), 
                  "totalNights": response.data[i].counters.target,
                  "nightsNeeded": response.data[i].counters.remaining,
                  "expirationDate": formatDateForPrinting(response.data[i].registerEndDateTime),
                  "programNightsNeeded": program && program.requiredAmount ? program.requiredAmount : response.data[i].counters.target
                };
                MemberLevelTracking.showAcceleratorMessage(response.data[i].promotionCode);
                if (response.data[i].registerEndDateTime != '') {
                  const registerEndDateTime = new Date(response.data[i].registerEndDateTime);
                  const currentDate = new Date();
                  const currentLEVELs = ['DIAMOND', 'TITANIUM']; 
                  if (!currentLEVELs.includes(window.User.currentTier.description.toUpperCase().split('*').join(''))) {
                    if (registerEndDateTime.getTime() < currentDate.getTime()) {
                      $('.tracker-view').show();
                      $('.accelerator-message').hide();
                      $('.member-information-introduction-message').show();
                      $('.member-information-accelerator-message').addClass('hidden');
                      $('.details-nights').show();
                      $('.details-nights-account').show();
                    } else {
                      $('.tracker-view').hide();
                      $('.accelerator-message').show();
                      $('.member-information-introduction-message').hide();
                      $('.member-information-accelerator-message').removeClass('hidden');
                      $('.details-nights').hide();
                      $('.details-nights-account').hide();
                
                    }  
                  } 
                }
                isLevelNotRegistered = false;
                break;
              }
            }
            if(isLevelNotRegistered){
              $('.tracker-view').show();
              $('.accelerator-message').hide();
              $('.member-information-introduction-message').show();
              $('.member-information-accelerator-message').addClass('hidden');
            }
          window.User.loyaltyLevelProgress.accelerators = accelerators;
          if (window.User && window.User.loyaltyLevelProgress && window.User.loyaltyLevelProgress.accelerators && window.User.loyaltyLevelProgress.accelerators[0]) {
            $('.accelerator-level').html(window.User.loyaltyLevelProgress.accelerators[0].level);
            $('.program-nights-needed').html(DOMPurify.sanitize(window.User.loyaltyLevelProgress.accelerators[0].programNightsNeeded));
            $('.accelerator-nights-needed').html(DOMPurify.sanitize(window.User.loyaltyLevelProgress.accelerators[0].nightsNeeded));
            $('.accelerator-expiration-date').html(DOMPurify.sanitize(formatDateForPrinting(window.User.loyaltyLevelProgress.accelerators[0].expirationDate)));
          }
          this.displayPromotionOffers(response.data);
        }
      });
    }
  }
  updatePromoFields() {
    let $cards = $('.promo-offers-component .text-offer-card');
    $cards.each((i, e) => {
      let card = $cards[i];
      if (window.User && window.User.loyaltyLevelProgress &&
        window.User.currentTier && window.User.currentTier.description &&
        Cookie.isAuthenticated()) {
        const tier = window.User.currentTier.description || mapTierCode(window.User.currentTier.tierCode);
        let currentLevel = tier.toLowerCase();
        let currentLevelBar = currentLevel.split('*').join('');
        $(card).find('.progressBar')
          .addClass(currentLevelBar);
        $(card).find('.progressBubble')
          .addClass(currentLevelBar);
      }
    });
  }
  updatePromoAttribute(object, searchValue, replaceValue) {
    if (object) {
      return object.replace(searchValue, replaceValue);
    }
    return object;
  }
  displayPromotionOffers(data) {
    let promotions = window.promotions || [],
      $cards = $('.promo-offers-component .text-offer-card');
    if (promotions && promotions.length > 0) {
      let promotionCodes = _.pluck(data, 'promotionCode') || [];
      let filteredCodes = promotions.filter(obj => obj.useStartwith === 'true');
      let everGreenPromo = promotions.filter(obj => obj.promoCode === '');
      if (data && filteredCodes && filteredCodes.length > 0) {
        let now = new Date();
        now.setDate(now.getDate() + 1);
        filteredCodes.forEach((promo, i) => {
          if (promo.promoCode) {
            let results = promotionCodes.filter(str => str.startsWith(promo.promoCode));
            let closestMatch = data
              .filter(item => results.includes(item.promotionCode) && item.registerStartDateTime && new Date(item.registerStartDateTime) <= now)
              .sort((a, b) => {
                let regA = a.registered === true,
                  regB = b.registered === true;
                if (regA !== regB) {
                  return regA ? -1 : 1;
                }
                let dateA = new Date(a.registerStartDateTime), 
                  dateB = new Date(b.registerStartDateTime);
                if (dateA.getTime() !== dateB.getTime()) {
                  return dateB - dateA;
                }
                return a.promotionCode.localeCompare(b.promotionCode);
              })[0];
            if (closestMatch && closestMatch.promotionCode) {
              const matchedCode = closestMatch.promotionCode;
              promotions.forEach((obj) => { 
                if (obj.promoCode === promo.promoCode) {
                  const regex = new RegExp(`\\b${promo.promoCode}\\w*`, 'g');
                  obj.completedMsg = this.updatePromoAttribute(obj.completedMsg, regex, matchedCode);
                  obj.headlineText = this.updatePromoAttribute(obj.headlineText, regex, matchedCode);
                  obj.initialStateButton = this.updatePromoAttribute(obj.initialStateButton, regex, matchedCode);
                  obj.alternativeStateButton = this.updatePromoAttribute(obj.alternativeStateButton, regex, matchedCode);
                  obj.subHeadlineCompletedState = this.updatePromoAttribute(obj.subHeadlineCompletedState, regex, matchedCode);
                  obj.subheadlineAltState = this.updatePromoAttribute(obj.subheadlineAltState, regex, matchedCode);
                  obj.subheadlineInitialState = this.updatePromoAttribute(obj.subheadlineInitialState, regex, matchedCode);
                  obj.promoCode = this.updatePromoAttribute(obj.promoCode, promo.promoCode, matchedCode);
                  return;
                }
              });
            }
          }
        });
      }
      let codes = _.pluck(promotions, 'promoCode') || [],
        codesFound = [],
        promotionsFound = [],
        numExtra = 0;
      //DAI-1205: Adding comma separated Offer Promotion codes while authoring
      let dest = [];
      for (let j = 0; j < codes.length; j++) {
        if (codes[j]) {
          let promoCodes = codes[j].split(',');
          for (let m = 0; m < promoCodes.length; m++) {
            dest.push(promoCodes[m]);
          }
        }
      }
      codes = dest;
      //DAI-1205: Adding comma separated Offer Promotion codes while authoring
      if (data && codes.length > 0) {
        // Find promo codes from response
        codes.forEach((val, i) => {
          if (_.findWhere(data, {
            'promotionCode': val // DAI-35744: code replaced with promotionCode
          })) {
            codesFound.push(val);
          }
        });
        // For each promotion see if it matches a promo code found,
        // if not and theres less than 3 add promotion with null promo code
        numExtra = 3 - codesFound.length;
        promotions.forEach((val, i) => {
          let promotion = {};
          //DAI-1205
          let singlePromoCode = val.promoCode.split(',');
          if (singlePromoCode.length >= 1) {
            for (let i = 0; i < singlePromoCode.length; i++) {
              if (_.contains(codesFound, singlePromoCode[i])) {
                const dataObj = _.first(_.where(data, {
                  'promotionCode': singlePromoCode[i] // DAI-35744: code replaced with promotionCode
                }));
                promotion = {...val, ...dataObj};
                if (_.keys(promotion).length > 0) {
                    if (promotion && (promotion.promotionCode === 'GOLDCHAL' || promotion.promotionCode === 'PLATCHAL' || promotion.promotionCode === 'DIACHAL') && (promotion.registered === false || promotion.autoRegister === false)) {
                      console.log('Registered false promotion');
                    } else {
                      promotionsFound.push(promotion);
                    }
                   //promotionsFound.push(promotion);
                }
              }
            }
          } else if (_.contains(codesFound, val.promoCode)) {
            const dataObj = _.first(_.where(data, {
              'promotionCode': singlePromoCode[i] // DAI-35744: code replaced with promotionCode
            }));
            promotion = {...val, ...dataObj};
            (_.keys(promotion).length > 0) ? promotionsFound.push(promotion) : '';
          } else {
            if (numExtra > 0 && !val.promoCode) {
              promotion = val;
              numExtra--;
            }
            (_.keys(promotion).length > 0) ? promotionsFound.push(promotion) : '';
          }
        });
      }
      const promoToAdd = 3 - promotionsFound.length;
      if (promoToAdd > 0) {
        const everGreenCards = everGreenPromo.slice(0, promoToAdd);
        promotionsFound = [...promotionsFound, ...everGreenCards];
      }
      if (promotionsFound.length > 0) {
        let promoRegistered = null;
        $cards.each((i, e) => {
          if (promotionsFound[i]) {
            let card = $cards[i],
              promoCode = promotionsFound[i].promoCode,
              iconPath = promotionsFound[i].iconPath,
              iconAlt = promotionsFound[i].iconAlt,
              headlineText = promotionsFound[i].headlineText,
              headlinePath = promotionsFound[i].headlinePath,
              subheadlineInitialState = promotionsFound[i].subheadlineInitialState,
              subheadlineAltState = promotionsFound[i].subheadlineAltState,
              subheadlinePath = promotionsFound[i].subheadlinePath,
              initialStateButton = promotionsFound[i].initialStateButton,
              alternativeStateButton = promotionsFound[i].alternativeStateButton,
              promoButtonPath = promotionsFound[i].promoButtonPath,
              activeState = promotionsFound[i].registered, // DAI-35744: memberRegistered replaced with registered
              subheadlineText = activeState ? subheadlineAltState : subheadlineInitialState,
              isMemRegistered = promotionsFound[i].registered; //DAI-35744: memberRegistered replaced with registered
            if (iconPath && iconPath.length > 0) {
              $(card).find('.card-img img')
                .attr('src', iconPath)
                .attr('alt', iconAlt);
            } else {
              $(card).find('.card-img')
                .remove();
            }
            if (headlineText && headlineText.length > 0) {
              let html = $.parseHTML(headlineText);
              if (headlinePath && headlinePath.length > 0) {
                if (!activeState && promoCode && promoCode.length > 0) {
                  $(card).find('.title-link a')
                  .html(html)
                  .attr('href', '#register')
                  .attr("data-promo-code", promoCode);
                } else {
                  $(card).find('.title-link a')
                  .html(html)
                  .attr('href', headlinePath);
                }
              } else {
                $(card).find('.title-link')
                  .html(html);
              }
            } else {
              $(card).find('.title-link')
                .remove();
            }

            if (subheadlineText && subheadlineText.length > 0) {
              let html = $.parseHTML(subheadlineText);
              if (subheadlinePath && subheadlinePath.length > 0) {
                $(card).find('.description p')
                  .remove();
                $(card).find('.description a')
                  .html(html)
                  .attr('href', subheadlinePath);
              } else {
                $(card).find('.description a')
                  .remove();
                $(card).find('.description p')
                  .html(html);
              }
            } else {
              $(card).find('.description')
                .remove();
            }

            if (isMemRegistered) {
                let $promotionFound = promotionsFound[i];
                if (_isNull(promoRegistered)) {
                    promoRegistered = $promotionFound;
                }
                // Check if promotion tracker is suppressed; determined by ICF/Business
                if ($promotionFound.counters.progress || $promotionFound.counters.progress == 0) { // DAI-35744: $promotionFound.progress replaced with $promotionFound.counters.progress

                    this.processPromotionProgress(card, $promotionFound);
                } else {
                    // DAI-35744: replace $promotionFound.complete
                    let isCompletedPromotion = ($promotionFound.counters.progress === $promotionFound.counters.target) ? true : false;
                    if (!_isNull(isCompletedPromotion) && (isCompletedPromotion === true)) {
                        this.processCompletedState(card, $promotionFound);
                    }
                }
            } 
            DataBinding.renderData(window.User, '[data-binding]');
            if ((initialStateButton && initialStateButton.length > 0) || (alternativeStateButton && alternativeStateButton.length > 0)) {
              if (activeState) {
                $(card).find('.card-button')
                  .text(alternativeStateButton)
                  .attr('disabled', false)
                  .attr('href', promoButtonPath);
              } else if (promoButtonPath && promoButtonPath.length > 0) {
                if (promoCode && promoCode.length > 0) {
                  $(card).find('.card-button')
                    .text(initialStateButton)
                    .attr('href', '#register')
                    .attr('disabled', false)
                    .attr("data-promo-code", promoCode);
                } else {
                  $(card).find('.card-button')
                    .text(initialStateButton)
                    .attr('disabled', false)
                    .attr('href', promoButtonPath);
                }
              } else {
                $(card).find('.card-button')
                  .addClass('hidden');
              }
            } else {
              $(card).find('.card-button')
                .addClass('hidden');
            }
            $(card).find('.card-content')
              .removeClass('hidden');
          }
        });        
        $( "span[class^='endDate']" ).each( function(i,e) {
              let promoCode = $(e).attr('class').split("endDate");
              if (_.contains(promotionCodes,promoCode[1])){
                let promotion = _.first(_.where(data, {'promotionCode': promoCode[1]})); // DAI-35744: code replaced with promotionCode
                if(promotion.registerEndDateTime) { // DAI-35744: endDate replaced with registerEndDateTime
                  let endDate = formatDateForPrinting(promotion.registerEndDateTime, 'weekdayCompact'); // DAI-35744: endDate replaced with registerEndDateTime
                  $(e).text(endDate.split(", ")[1]);
                }
              }
        });
        $('.promo-offers-component .centered-button-container').removeClass('hidden');
        DataBinding.renderData(promoRegistered, '[data-binding^=progress]');
        DataBinding.renderData(window.User, '[data-binding^=loyaltyLevelProgress]');
        DataBinding.renderData(window.DataBinding.UserLoyalty, '[data-binding^=Channel]');
        let $accExpDate = $(document).find('.accelerator-expiration-date'),
          accelerator = undefined,
          expirationFormatted = accelerator ? formatDateForPrinting(accelerator.expirationDate) : undefined;
        if ($accExpDate && expirationFormatted && expirationFormatted.length) {
          $accExpDate.text(expirationFormatted);
        }
        Spinner.remove($('.promo-offers-component'));
        this.updatePromoFields();
        // set register button click for registering for promotion
        $(document).off('click', 'a[href*="#register"]:not([disabled])')
          .on('click', 'a[href*="#register"]:not([disabled])', (e) => this.submitRegister(e));
      }
    }
  }
  processPromotionProgress(currentPromoCard, promotionFound) {
      // DAI-35744: promotionFound.progress.complete replaced with promotionFound.counters.progress
      // DAI-35744: promotionFound.progress.total replaced with promotionFound.counters.target
      let completedStays = promotionFound.counters.progress,
      totalStays = promotionFound.counters.target,
      currentLevel = document.querySelectorAll('.page')[0].className.match(/member\-level\-(\w+)/)[1];
      if (totalStays) {
          this.computeStayProgressIndicator(currentPromoCard, currentLevel, completedStays, totalStays);
          if (completedStays === totalStays) {
              this.processCompletedState(currentPromoCard, promotionFound);
          }
      }
      // Below class is not referenced in other components; leaving for now in case its used
      $(currentPromoCard).find('.promo-nights-stayed')
          .removeAttr('data-binding')
          .text(completedStays);
  }
  computeStayProgressIndicator(currentPromoCard, currentLevel, completedStays, totalStays) {
      let $card = $(currentPromoCard);
      let completedPercent = Math.round(completedStays / totalStays * 87);
      // DAI-37127: progressBubble and progressBar color based on current user level
      if (window.User && window.User.currentTier) {
        if (window.User.currentTier.description) {
          currentLevel = window.User.currentTier.description;
        } else if (window.User.currentTier.tierCode) {
          currentLevel = mapTierCode(window.User.currentTier.tierCode);
        }
      }
     
      currentLevel = currentLevel.toLowerCase();
      $card.find('.progress-indicator')
          .removeClass('hidden');
      $card.find('.completedBubble')
          .text(totalStays);
      $card.find('.progressBubble')
          .text(completedStays)
          .css('left', completedPercent + '%')
          .addClass(currentLevel);
      $card.find('.completedBar')
          .css('width', (98 - completedPercent) + '%');
      $card.find('.progressBar')
          .css('width', completedPercent + '%')
          .addClass(currentLevel);
  }
  processCompletedState(currentPromoCard, promotionFound) {
      let $card = $(currentPromoCard);
      $card.find('.progressBubble')
          .css({
              left: 'auto',
              right: 0
              });
      $card.find('.progressBar')
          .css({
              width: '93%'
              });
      $card.find('.completedBar')
          .css({
              width: '0%'
              });
      if (promotionFound.counters && promotionFound.counters.complete === true) {
        $card.find('.title-link')
            .remove();
        $card.find('.description:first')
            .remove();
        $card.find('.complete-msg')
            .removeClass('hidden')
            .html(promotionFound.completedMsg);
        $card.find('.complete-description')
            .removeClass('hidden')
            .html(promotionFound.subHeadlineCompletedState);
      }
  }
  submitRegister(e) {
    e.preventDefault();
    // disable card button
    $(e.target).attr('disabled', true);
    let accountNumber = '';
    if(window.User.accountNumber) {
      accountNumber = window.User.accountNumber;
    } else if (window.User && window.User.membershipId) {
      accountNumber = window.User.membershipId;
    } else {
      console.error('Account Number not found in user or user sessionInfo while calling registerPromotion api');
    }
    const data = {
      promotionCode: $(e.target).attr('data-promo-code'),
      memberIdentifier: accountNumber  
    };
    // trigger register promotion when promotion code and member identifier is not undefined
    if (data.promotionCode && data.memberIdentifier) {
      this.register(data, e.target);
    } else {
      // enabled card button
      $(e.target).attr('disabled', false);
    }
  }
  register(data, element) {
    // add spinner on the page for registering promotion
    Spinner.appendTo($('html'), true, true);
    $.ajax({
      type: 'post',
      url: requestUrls.registerPromotion,
      data: JSON.stringify(data),
      contentType: 'application/json',
      dataType: 'json',
      success: (response) => {
        Spinner.remove($('html'));
       if (response.success) {
        // add a spinner to show that content is still loading for getPromotions
        Spinner.appendTo($(element), false, false);
        this.getPromotions();
       } else {
        // enabled card button
        $(element).attr('disabled', false);
       }
      },
      error: (err) => {
        console.error(err);
        // enabled card button 
        $(element).attr('disabled', false);
        Spinner.remove($('html'));
      }
    });
  }
}
//export default new PromotionOffers();
let PromoHandler = new PromotionOffers();
export { PromoHandler };
