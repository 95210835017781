
import Cookie from './cookie.js';

export function getLevel() {
  return (window.User && window.User.currentTier && window.User.currentTier.description.split('*').join('')) ||
    (window.DataBinding && window.DataBinding.UserLoyalty && window.DataBinding.UserLoyalty.Channel && window.DataBinding.UserLoyalty.Channel.WR && window.DataBinding.UserLoyalty.Channel.WR.LoyaltyLevel) ||
    '';
}
export function handleLoyaltyLevelStyles() {
  let level = getLevel();

  if(level && !$('.page').hasClass('member-level-' + level.toLowerCase())){
    $('.page').removeClass((i, css) => {
      return (css.match(/\bmember-level\S+/g) || []).join(' ');
    });
  }

  if(hasMilitaryStatus()){
    $('.page').addClass('member-level-military');
  } else {
    $('.page').removeClass('member-level-military');
  }

  switch (level && level.toLowerCase()) {
  case 'blue':
    $('.page').addClass('member-level-blue');
    break;
  case 'gold':
    $('.page').addClass('member-level-gold');
    break;
  case 'platinum':
    $('.page').addClass('member-level-platinum');
    break;
  case 'diamond':
    $('.page').addClass('member-level-diamond');
    break;
  case 'titanium':
    $('.page').addClass('member-level-titanium');
    break;
  default:
    $('.page').addClass('member-level-none');
  }

  if (level) {
    Cookie.updateState();
  }
}

export function hasMilitaryStatus() {
  return window.User && window.User.preferences && window.User.preferences.filter(preference => preference === 'MIL');
}
