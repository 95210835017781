import { scrollToElement } from '../base/animate.js';
import { isMobileWidth } from '../../base/dom-utils.js';

class EarnMilesContainer {
  constructor() {
    this.$component = $('.earn-miles-container');
    this.$selects = this.$component.find('[name="partner"]');
    this.$listings = this.$component.find('.listings-container');
    this.$mobileListing = this.$component.find('.mobile-option-listing');

    this.onSelectChange();
  }

  onSelectChange() {
    this.$selects.on('change', (e) => {
      let newVal = $(e.currentTarget).val();
      this.$selects.val(newVal); // keep both selects in sync
      // Programmatically setting val doesn't trigger the SelectGroup change
      // handler so manually removing empty class from both selects
      this.$selects.closest('.select-container').removeClass('empty');
      this.updateMobileListing(newVal);

      if (!isMobileWidth()) {
        this.scrollToListing(newVal);
      }
    });
  }

  getListing(id) {
    return this.$listings.find(`#${id}`);
  }

  getListingData(id) {
    let $listing = this.getListing(id);
    return {
      $picEl: $listing.find('picture').clone(),
      text1: $listing.find('.text-1-column').text(),
      text2: $listing.find('.text-2-column').text(),
      linkUrl: $listing.find('.redeem-option-btn').attr('href'),
      linkText: $listing.find('.redeem-option-btn').text()
    };
  }

  renderMobileListing(listingData) {
    this.$mobileListing.find('.brand-logo').html(listingData.$picEl);
    this.$mobileListing.find('.offer-text-1').text(listingData.text1);
    this.$mobileListing.find('.offer-text-2').text(listingData.text2);
    this.$mobileListing.find('.redeem-option-btn').attr('href', listingData.linkUrl)
      .text(listingData.linkText);
  }

  updateMobileListing(id) {
    let listingData = this.getListingData(id);
    this.renderMobileListing(listingData);
    this.$mobileListing.removeClass('hidden');
  }

  scrollToListing(id) {
    let $listing = this.getListing(id);

    scrollToElement($listing)
      .done(() => {
        $listing.find('.partner-image').focus(); // move focus to listing for keyboard users
      });
  }
}

export default new EarnMilesContainer();
