import mycheckV3 from './mycheck-v3.js';
import ConfigsUtils from '../../base/aem-configs/config-utils.js';

class MyCheck {
  constructor() {
    if ($('.mycheck-component').length) {
      if(ConfigsUtils.getMychecktV3Enabled()){
        mycheckV3.loadMyCheckScript();
      }
    }
  }
}

export default new MyCheck();
