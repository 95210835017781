import { exists } from '../base/dom-utils';
import { getLocaleUrlToken } from "../base/utils.js";

class MemberLookupForm {
  constructor() {
    if (exists('#memberLookup')) {
      this.formSelector = '#memberLookup';
      this.$form = $(this.formSelector);
      this.disableSubmitButton();
      this.setEmailValidation();
      this.initSubmitFunction();
    }
  }

  disableSubmitButton() {
    const submitButton = $('.submit');
    submitButton.prop('disabled', true);
  }

  setEmailValidation() {
    const submitButton = $('.submit'),
      emailAddress = $('#emailAddress'),
      errorMessage = $('.error-msg-two'),
      emailRegex = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;  

    emailAddress.on('blur', e => {
      const email = e.currentTarget.value;
      const isValidEmail = emailRegex.test(email) || email === '';
      submitButton.prop('disabled', !isValidEmail);
      if (!isValidEmail) {
        emailAddress.parent().addClass('form-input-error');
        errorMessage.removeClass('hidden');
      } else {
        emailAddress.parent().removeClass('form-input-error');
        errorMessage.addClass('hidden');
      }
    });
  }

  initSubmitFunction() {
    const lookupForm = $('#memberLookup');
    lookupForm.on('submit', () => {
      // TALLY-FE SERVICE INTEGRATION: gather data and send ajax submit call
      window.location = getLocaleUrlToken() + "/wyndham-rewards/first-time-sign-in/base/okta/confirmation-page";
    });
  }
}

export default new MemberLookupForm();
