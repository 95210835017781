import Form from '../base/form.js';
import HeaderMobile from './header-mobile.js';
import Header from './header.js';
import SSO from './sso.js';
import Modal from './modal.js';
import {
  checkVulnerableUrl,
  getParameterByName,
  EventHandler
} from '../../base/utils.js';
import Redirect from '../base/redirect.js';
import ResetPassword from './reset-password.js';
import { isMobileWidth } from '../../base/dom-utils.js';
import SecuritySetup from './security-setup.js';
import SecurityVerify from './security-verify.js';
import Spinner from './spinner.js';
import UserHandler from '../base/user.js';
import { requestUrls, templateUrls } from '../../base/vars.js';
import Analytics from '../../base/wyn-analytics-module.js';
import SSODelegate from './sso-delegate.js';
import { default as WTHeader } from '../../components/header.js';
import LoginHandler from '../../components/login.js';
import { bookDataBeforeSSOLogin } from '../../base/session-handler.js';


class Login {
  constructor() {
    console.log('wyndham-version-20240117-alpha');
    this.redirectUrl = null;
    this.openLoginModalOnClick();
    this.openLoginModalOnEvent();
    this.addFormValidation();
    if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
      $(".open-login-modal").removeAttr('href').css("cursor","pointer").addClass('signin-button');
      $("a[href='#sign-in-link']").removeAttr('href').css("cursor","pointer").addClass('signin-button');
    }
    $('.open-login-modal').on('click', async(e) => {
      bookDataBeforeSSOLogin();
      e.preventDefault();
      LoginHandler.redirectToOktaLogin(null);
    });  
  }
  openLoginModalOnClick() {
    /* $(document).on('click', 'a[href*="#login"]', (e) => {
      if ($(e.currentTarget).attr('href')
        .indexOf('login') > 0) {
        e.preventDefault();
        let parsedUrl = checkVulnerableUrl(getParameterByName('redirect', $(e.currentTarget).attr('href'), true));
        if (parsedUrl) {
          if (!CookieHandler.isAuthenticated()) {
            this.redirectUrl = parsedUrl;
            this.openLoginModal();
          } else {
            window.location.assign(parsedUrl);
          }
        } else {
          this.openLoginModal();
        }
      }
    }); */
  }
  openLoginModalOnEvent() {
    EventHandler.on('open-login-modal', () => {
      this.openLoginModal();
    });
  }
  openLoginModal(options) {
    Modal.show(options);
    Modal.showModalSpinner();
    this.getLoginTemplate();
  }
  getLoginTemplate(redirect) {
    // Get login html to be placed within modal content
    $.ajax({
      contentType: 'application/json',
      url: templateUrls['login'],
      success: (response) => {
        Modal.populateModalContent(response);
        this.addFormValidation();
      }
    });
  }
  authenticate(form, showModal) {
    let data = JSON.stringify({
        'Username': $(form).find('.login-username')
          .val(),
        'Password': $(form).find('.login-password')
          .val(),
        'rememberMe': $(form).find('.remember-me-checkbox input')
          .is(':checked')
      }),
      $form = $(form),
      context = {
        showModal: showModal,
        onFail: {
          formElement: $form
        }
      };

    Spinner.appendTo($form);
    Form.disableFormField($form.find('.submit'));

    // POST username and password before prompting security questions/verify
    $.ajax({
      type: 'POST',
      contentType: 'application/json',
      url: requestUrls['authenticate'],
      data: data,
      context: context,
      success: (response) => {
        let verifyExists = $('#securityVerify').length > 0,
          setupExists = $('#securitySetup').length > 0;

        if (verifyExists || setupExists) {
          context.showModal = false;
          Header.closeDropdowns();
          HeaderMobile.closeDropdowns();
          Spinner.remove($form);
          Form.enableFormField($form.find('.submit'));
          if (verifyExists) {
            Spinner.appendTo($('#securityVerify'));
          } else if (setupExists) {
            Spinner.appendTo($('#securitySetup'));
          }
        }
        if (response['TwoFormActionCode'] === 'change') {
          new ResetPassword().openResetPassword(context.showModal, this.redirectUrl, {
            resetPwToken: response.resetPwToken,
            username: $form.find('.login-username').val()
          });
        } else if (response['TwoFormActionCode'] === 'verify') {
          SecurityVerify.openSecurityVerify(context.showModal, this.redirectUrl);
        } else if (response['TwoFormActionCode'] === 'setup') {
          SecuritySetup.openSecurityQuestions(context.showModal, this.redirectUrl);
        } else if (response['TwoFormActionCode'] === '') {
          let url = this.redirectUrl || templateUrls.redirect;

          UserHandler.getProfile((userProfile) => {
            WTHeader.tollFreeNumberNav();
            EventHandler.triggerEvent('signin-complete', Analytics.getUserData(userProfile));
            if (getParameterByName('catalogOfOrigin') || getParameterByName('destinationLocation') || getParameterByName('ssoType') === '2') {
              SSO.handleOutbound(window.location.href, false, true);
            } else if(getParameterByName('ssoType') === '1') {
              SSODelegate.getPoints(window.location.href, false, true);
            } else if (checkVulnerableUrl(getParameterByName('redirect'))) {
              Redirect.deeplinkRedirect();
            } else if (url) {
              window.location.assign(url);
            } else {
              if (isMobileWidth()) {
                HeaderMobile.closeDropdowns();
              }
              Spinner.remove($form);
              Form.enableFormField($form.find('.submit'));
              Header.closeDropdowns();
              Modal.close();
            }
          });
        }
      }
    });
  }
  addFormValidation() {
    let $usernameError = $($('.login-username').parent()
        .find('.help-block')[0]),
      usernameErrorMessage = $usernameError.text(),
      $passwordError = $($('.login-password').parent()
        .find('.help-block')[0]),
      passwordErrorMessage = $passwordError.text();

    $usernameError.remove();
    $passwordError.remove();

    $('.sign-in-form').formValidation({
      button: {
        selector: '.submit',
        disabled: ''
      },
      live: 'submitted',
      fields: {
        'login-username': {
          validators: {
            stringLength: {
              min: 8,
              max: 16,
              message: usernameErrorMessage
            },
            notEmpty: {
              enabled: true,
              message: usernameErrorMessage
            },
            regexp: {
              regexp: /^[^(<">'!%\)]+$/,
              message: usernameErrorMessage
            }
          }
        },
        'login-password': {
          validators: {
            notEmpty: {
              enabled: true,
              message: passwordErrorMessage
            },
            stringLength: {
              min: 8,
              message: passwordErrorMessage
            }
          }
        }
      }
    })
      .on('success.form.fv', (e, data) => {
        e.preventDefault();
        let showModal = $(e.target).parents('header').length > 0 || $(e.target).parents('#genericLightbox').length > 0;
        this.authenticate($(e.target), showModal, true);
      });
  }
}

export default new Login();
