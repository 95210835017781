import DataBinding from '../base/data-binding.js';
import { getLevel, hasMilitaryStatus } from '../base/status.js';

class SoftNotificationCallout {
  constructor() {
    let component = $('.soft-notification-callout-component');
    if (component.length > 0) {
      $(document).on('user:profile:updated', () => {
        this.setInitialNotificationContent();
        this.handleExpiringPointsNotification();
        this.handleMemberLevelNotification();
        // Show notification content
        component.each((i,e)=> {
          let $el = $(e);
          if($el.data('contentReady')) {
            $el.removeClass('hidden');
          }
        });
      });
    }
  }

  applyNotificationContent(notificationID, contentObj) {
    let $el = $('[data-notificationId=\'' + notificationID + '\']'),
      linkTarget = contentObj.newWindow ? '_blank' : '_self';

    $el.find('.sn-headline').html(contentObj.headerLabel);
    $el.find('.sn-instruction.description').html(contentObj.description)
      .attr('data-military-attribute-label', contentObj.militaryAttributeLabel);
    $el.find('.sn-instruction a.learn-more-link').html(contentObj.learnMoreLabel)
      .attr('href', contentObj.learnMoreLink)
      .attr('target', linkTarget);
  }

  setInitialNotificationContent() {
    let level = this.getUpdatedLevel(),
      component = $('.soft-notification-callout-component');
    if (level && window.notificationCallout) {
      component.each((i, e) => {
        let $el = $(e),
          id = $el.attr('data-notificationId');

        if (id in window.notificationCallout && window.notificationCallout[id].length) {
          let contentArray = window.notificationCallout[id],
            allLevelsContent = [],
            memberLevelContent = [];

          contentArray.forEach((v) => {
            if (v.memberLevel === 'all') {
              allLevelsContent.push(v);
            } else if (v.memberLevel === level) {
              memberLevelContent.push(v);
            }
          });

          if (memberLevelContent.length) {
            this.applyNotificationContent(id, memberLevelContent[memberLevelContent.length - 1]);
          } else if (allLevelsContent.length) {
            this.applyNotificationContent(id, allLevelsContent[allLevelsContent.length - 1]);
          }
        }
      });
      DataBinding.setMemberServicesNumber();
      DataBinding.renderData(DataBinding.gatherAllData(), '.soft-notification-callout-component [data-binding]');
      component.data('contentReady', true);
    }
  }

  handleExpiringPointsNotification() {      // Refreshing data-binding after content is added
    let $el = $('.soft-notification-callout-component').find('.user-expiringpoints')
      .parents('.soft-notification-callout-component');
    $el.data('contentReady', true);
    if (!DataBinding.hasPointBucketObject() ||
      !(window.User.pointExpiryInfo && window.User.pointExpiryInfo.available > 0)) {
      $el.removeData('contentReady');
    }
  }

  handleMemberLevelNotification() {
    let level = this.getUpdatedLevel(),
      component = $('.soft-notification-callout-component'),
      snMemberLevel = component.find('.user-memberlevel'),
      snCalloutComponent = snMemberLevel.parents('.soft-notification-callout-component');

    if (hasMilitaryStatus() && (level === 'blue' || level === 'gold')) {
      snCalloutComponent.removeData('contentReady');
      snCalloutComponent.each((i, elm) => {
        let $elm = $(elm),
          snMemberLevelTitle = $elm.find('.sn-instruction'),
          militaryInfoLabel = snMemberLevelTitle.data('military-attribute-label'),
          militaryMemberLevel = $elm.find('.user-memberlevel');
        if (militaryInfoLabel && militaryInfoLabel !== '') {
          militaryMemberLevel.text(militaryInfoLabel);
          militaryMemberLevel.removeAttr('data-binding');
        }
        $elm.data('contentReady', true);
      });
    }
  }

  getUpdatedLevel() {
    return window.User && getLevel().toLowerCase();
  }
}

export default new SoftNotificationCallout();
