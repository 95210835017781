
class Tabs {
  constructor() {
    if ($('.tabs-component').length > 0) {
      this.onSwitchTabs();
    }
  }
  onSwitchTabs() {
    $('a[data-toggle="tab"]').on('shown.bs.tab', (e) => {
      e.preventDefault();
      let nowActiveTab = e.target,
        previousActiveTab = e.relatedTarget;

      $(nowActiveTab).attr({
        'aria-selected': 'true'
      });
      $(previousActiveTab).attr({
        'aria-selected': 'false'
      });
    });
  }
}

export default new Tabs();
