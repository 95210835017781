import { MASKED_PASSWORD_PLACEHOLDER } from '../../base/vars.js';

class TextInput {
  constructor() {
    this.checkEmptyInputOnload();
    this.onCheckEmptyInput();
    this.handleInputChange();
    this.handleInputFocus();
    this.handleInputFocusOut();
    this.handleMaskedInputs();
  }
  handleMaskedInputs() {
    if ($('.security-answer').length > 0) {
      $('.security-answer').focusin((e) => {
        if ($(e.currentTarget).val() === MASKED_PASSWORD_PLACEHOLDER) {
          $(e.currentTarget).val('');
        }
      })
        .focusout((e) => {
          if (!($(e.currentTarget).val())) {
            $(e.currentTarget).val(MASKED_PASSWORD_PLACEHOLDER);
          }
        });
    }
  }
  handleEmptyInput(e) {
    let isMaskedInput = $(e).hasClass('.security-answer');
    if ($(e).val().length === 0 && !isMaskedInput) {
      $(e).addClass('empty');
    } else {
      $(e).removeClass('empty');
    }
  }
  checkEmptyInputOnload() {
    if ($('input,textarea').length > 0) {
      $.each($('input,textarea'), (i, e) => {
        this.handleEmptyInput(e);
      });
    }
  }
  handleInputChange() {
    $(document).on('keyup change focusout', 'input,textarea', (e) => {
      this.handleEmptyInput(e.currentTarget);
    });
  }
  onCheckEmptyInput() {
    $(document).on('checkEmptyInput', () => {
      this.checkEmptyInputOnload();
    });
  }
  handleInputFocus() {
    // Apply focus for inputs that don't have an id
    $(document).on('click', 'label, input', (e) => {
      let $modal = $(e.currentTarget).parents('.modal');
      $(e.currentTarget).siblings('input')
        .focus();
      // Brands inputs don't have the same focus state
      if ($modal.length > 0 && !($modal.hasClass('booknow_widget') || $modal.hasClass('locations-filter-modal'))) {
        $modal.css({
          'position': 'absolute',
          'top': $modal.offset().top,
          'height': '100%'
        });
      }
    });
  }
  handleInputFocusOut() {
    // Apply focus for inputs that don't have an id
    $(document).on('focusout', 'input', (e) => {
      let $modal = $(e.currentTarget).parents('.modal');
      // Brands inputs don't have the same focus state
      if ($modal.length > 0 && !$modal.hasClass('locations-filter-modal')) {
        $modal.css({
          'position': 'fixed',
          'top': 0,
          'height': 'auto'
        });
      }
    });
  }
}

export default new TextInput();
