import Cookie from './cookie.js';
import DataBinding from './data-binding.js';
import { handleLoyaltyLevelStyles } from './status.js';
import {
  CID_COOKIE,
  SESSION_PROFILE,
  templateUrls
} from '../../base/vars.js';
import {UserHandler, getSessionStorage, setSessionStorage, setWRCCCodes, getLocalStorage} from '../../base/session-handler';
import spinner from '../components/spinner.js';
import Join from '../components/join.js';
import OktaClient from '../../components/okta-client.js';
import ProfileHandler from '../../components/profile-handler.js';
import { formatNumber, formatDateForPrinting, getJsonObject, EventHandler } from '../../base/utils.js';
import { PromoHandler } from '../components/promotion-offers.js';

class User {
  constructor() {
    this.onUpdateUserData();
    this.checkSessionCookie();
    this.profileCheck();
    this.omitKeys = null;
  }
  emptyUser() {
    window.User = {};
  }
  updateUserData(response) {
    $(document).trigger('user:profile:update', response);
  }
  renderUserData(obj) {
    let data = obj || window.User;

    DataBinding.renderData(data, false, this.omitKeys);
  }
  async getProfile(callback, omitKeys, getNewProfile) {
    const newProfile = getNewProfile ? getNewProfile : true;
    this.omitKeys = omitKeys ? omitKeys : null;
    await OktaClient.getOktaClient();
    const isValid = await OktaClient.validateOktaToken();
    if (!isValid) {
      return;
    }
    spinner.appendTo($('body'));
    $('.tracker-view').hide();
    $('.accelerator-message').hide();
    $('.member-information-introduction-message').hide();
    $('.member-information-accelerator-message').addClass('hidden');
    $('.details-nights').hide();
    $('.details-nights-account').hide();
    try {
      const responseData = await ProfileHandler.getProfile(newProfile);
      const response = getJsonObject(JSON.stringify(responseData));
      UserHandler.updateCurrencyExpiration(response);
      UserHandler.createSessionInformation(response);
      if (response.preferences) {
        const preferencesList = response.preferences;
        for (let i = 0; i < preferencesList.length; i++) {
          if(preferencesList[i].preferenceRuleDetailCode == 'CP_LANG_PREF'
            && preferencesList[i].value == 'es-es'){
              preferencesList[i].value = 'es-xl';
          }
        }
      }
      setWRCCCodes(response);
      this.updateUserData(JSON.stringify(response));
      setSessionStorage(CID_COOKIE, response);
      const navBarFirstName = document.querySelector('.main-nav-login .account-tab-text.signed-in .user-firstname');
      if (navBarFirstName && !navBarFirstName.innerText) {
        this.checkSessionCookie();
      }
      if (callback && typeof callback === 'function') {
        callback(response);
      }
      const uriWRProfileSetup = templateUrls.profileSetup;
      const stateField = document.querySelector('#state0'),
        countryField = document.querySelector('[data-binding="addresses.0.countryCode"]');
      if (window.location.href.includes(uriWRProfileSetup) && countryField && countryField.innerText !== 'US') {
        stateField.setAttribute('disabled', 'disabled');
      }
      const currencyObj = response && response.currencies ? 
        response.currencies.find(item => item.typeCode === 'POINTS') : null;
      let points = currencyObj && currencyObj.available ? currencyObj.available : 0;
      const sanitizedUserMemberPoints = DOMPurify.sanitize(formatNumber(points));
      $('.user-memberpoints').html(sanitizedUserMemberPoints);
      if (response.earningTier) {
        const sanitizedNightsToNextLevel = DOMPurify.sanitize(response.earningTier.requiredAmount - response.earningTier.accruedAmount);
        $('.nights-to-next-level').html(sanitizedNightsToNextLevel);
        const sanitizedNextLevel = DOMPurify.sanitize(response.earningTier.description.toUpperCase());
        $('.next-level').html(sanitizedNextLevel);
        const sanitizedAccruedAmount = DOMPurify.sanitize(response.earningTier.accruedAmount);
        $('.qualified-nights').html(sanitizedAccruedAmount);
      }
      const sanitizedMemberlevel = DOMPurify.sanitize(response.currentTier.description.toUpperCase());
      $('.user-memberlevel').html(sanitizedMemberlevel);
      const sanitizedDateForPrinting = DOMPurify.sanitize(formatDateForPrinting(response.projectedTier.endDate));
      $('.next-level-expiration').html(sanitizedDateForPrinting);
      
      if (points > 0  && response.redemptionTierInfo) {
        let redemptionTierInfo = response.redemptionTierInfo;
        for(let i = 0; i < redemptionTierInfo.length; i++ ){
          let redemptionItemCode = redemptionTierInfo[i].redemptionItemCode;
          let goFast = '';
          let goFree = '';

          if( redemptionItemCode === 'GOFAST-TIER101') {
            goFast = redemptionTierInfo[i].nightsRequired;
          }

          if( goFast > 0 ) {
            $(".gofast-accrued .wr-icon-lock").hide();
            const sanitizedGoFast = DOMPurify.sanitize(goFast);
            $(".gofast-accrued .status-icon").html(sanitizedGoFast);
          }

          if( redemptionItemCode === 'GOFREE-TIER101') {
            goFree = redemptionTierInfo[i].nightsRequired;
          }

          if( goFree > 0 ) {
            $(".gofree-accrued .wr-icon-lock").hide();
            const sanitizedGoFree = DOMPurify.sanitize(goFree);
            $(".gofree-accrued .status-icon").html(sanitizedGoFree);
          }
        }
      }
      PromoHandler.getPromotions(); 
    } catch (err) {
      console.error('Failed to get profile with error:', err);
      EventHandler.send(EventHandler.profile.oktaSignOut);
    } finally {
      spinner.remove($('body'));
    }
  }
  // Returns promise with limited set of profile data from session cookie.
  // Values: membershipId, givenName, username, userLocalePref, points, level, nightsNeeded, state, expiration, cookieExpiration
  getSessionProfile() {
    let deferred = new $.Deferred();
    let sessionProfile = getLocalStorage(SESSION_PROFILE);

    if (sessionProfile) {
      deferred.resolve(sessionProfile);
    } else {
      $(document).one('user:profile:updated', () => {
        deferred.resolve(getLocalStorage(SESSION_PROFILE));
      });
    }

    return deferred.promise();
  }
  parseSessionCookie(obj) {
    let parseSessionCookieData = {
      AccountInfo: {
        Username: obj.username,
        PointExpirationInfo: {
          PointExpirationBuckets: [{
            Points: obj.points
          }]
        },
        // PointBalance: obj.points // DAI-37253 as old point balance is showing for fraction of seconds.
      },
      Channel: {
        WR: {
          MembershipID: obj.membershipId,
          LoyaltyLevel: obj.level
        }
      },
      Customer: {
        PersonName: {
          GivenName: decodeURIComponent(obj.givenName)
        },
        Addresses: window.addresses
      },
      loyaltyLevelProgress: {
        currentLevel: {
          level: obj.level
        },
        nextLevel: {
          nightsNeeded: this.getNightsNeededToNextLevel(obj.aspirationTiers),
          level: obj.aspirationTiers && obj.aspirationTiers[0].description.toUpperCase(),
          expirationDate: formatDateForPrinting(obj.projectedTier && obj.projectedTier.endDate)
        },
        accrual: {
          qualifiedNights: obj.earningTier && obj.earningTier.accruedAmount
        }
      }
    };
    return parseSessionCookieData;
  }
  getNightsNeededToNextLevel(aspirationTiers) {

    if (!aspirationTiers || !aspirationTiers.length) {
      return 0;
    }
    const nextTier = aspirationTiers[0];
    return nextTier && (nextTier.requiredAmount - nextTier.accruedAmount);
  }
  onUpdateUserData() {
    $(document).on('user:profile:update', (e, userObject) => {
      this.emptyUser();
      if (typeof (userObject) === 'string') {
        window.User = JSON.parse(userObject);
        if(window.User && window.User.currencies && window.User.currencies.length > 0) {
          const pointsCurrency = window.User.currencies.find(item => item.typeCode === 'POINTS');
          if (pointsCurrency && pointsCurrency.pointForfeitureDate) {
            let srcDate = new Date(pointsCurrency.pointForfeitureDate);
            let targetDate = this.converDateWithDDMMYYYY(srcDate);
            pointsCurrency.pointForfeitureDate = targetDate;
          }
          const pointExpiryInfo = window.User.pointExpiryInfo;
          if (pointExpiryInfo && pointExpiryInfo.expirationDate) {
            let srcDate = new Date(pointExpiryInfo.expirationDate);
            let targetDate = this.converDateWithDDMMYYYY(srcDate);
            pointExpiryInfo.expirationDate = targetDate;
          }
        }
      } else {
        window.User = userObject;
      }
      if (window.User.addresses) {
        window.addresses = window.User.addresses;
      }
      let program = '';
      if(window.User && window.User.aspirationTiers) {
       let currentLevelWithoutStar = window.User && window.User.currentTier && window.User.currentTier.description.split('*').join('');
        program = window.User.aspirationTiers.find(item => item.description.toLowerCase() === currentLevelWithoutStar.toLowerCase()); 
      }
      if(window.User && window.User.currentTier) {
        let accelerators = {accelerators:[
          {
            "level": window.User.currentTier.description.toUpperCase(),
            "programNightsNeeded": program && program.requiredAmount ? program.requiredAmount : 0
          }
        ]
      };
      window.User.loyaltyLevelProgress = accelerators;
    }
    if(window.User && window.User.earningTier) {
      let nextLevel = {nextLevel:[
        {
          "level": window.User.earningTier.description.toUpperCase(),
          "nightsNeeded": (window.User.earningTier.requiredAmount - window.User.earningTier.accruedAmount),
          "expirationDate": window.User && window.User.projectedTier && window.User.projectedTier.endDate && formatDateForPrinting(window.User.projectedTier.endDate),
        }
      ]
    };
    window.User.loyaltyLevelProgress = nextLevel;
  }

  if(window.User && window.User.earningTier) {
    let accrual = {accrual:[
      {
        "qualifiedNights": window.User.earningTier.accruedAmount
      }
    ]
  };
  window.User.loyaltyLevelProgress = accrual;
  }
      if (!_.isObject(getLocalStorage(SESSION_PROFILE))) {
        UserHandler.createSessionInformation(JSON.parse(userObject));
        UserHandler.populateEliteMemberDetails(getLocalStorage(SESSION_PROFILE));
      }

      handleLoyaltyLevelStyles(window.User);

      this.renderUserData(window.User);
      $(document).trigger('user:profile:updated');
    });
  }
  // DAI-36929 - point expire date formatting DD/MM/YYYY
  converDateWithDDMMYYYY(srcDate) {
    let dateSrc = srcDate.toJSON().slice(0, 10);
    let lang = window._LOCALE_;
    let langDate = '';
  
    const langList = ['en-us', 'en-ap', 'en-ca', 'en-uk', 'en-gb'];
    if ( langList.includes(lang) ) {
      langDate = dateSrc.slice(5, 7) + '/'
        + dateSrc.slice(8, 10) + '/'
        + dateSrc.slice(0, 4);
    } else {
      langDate = dateSrc.slice(8, 10) + '/'
        + dateSrc.slice(5, 7) + '/'
        + dateSrc.slice(0, 4);
    }
   
    return langDate;
  }
  checkSessionCookie() {
  
    if (_.isObject(getSessionStorage(CID_COOKIE)) &&
      _.isObject(getLocalStorage(SESSION_PROFILE))) {
      window.User = {...window.User, ...this.parseSessionCookie(getLocalStorage(SESSION_PROFILE))};
      handleLoyaltyLevelStyles(window.User);

      this.renderUserData(window.User);
    } else {
      sessionStorage.removeItem(SESSION_PROFILE);
      handleLoyaltyLevelStyles(window.User);
    }
  }
  async profileCheck() {
    if (($('.page').hasClass('requireAuth') || $('.contact-us-form, .signed-in-container, .signed-out-container').length > 0) && Cookie.isAuthenticated()) {
      this.getProfile(null, null, false);
    } else if (getLocalStorage(SESSION_PROFILE)) {
      $(document).trigger('user:profile:updated');
    }
    const urlWRJoin = templateUrls.joinPage;
    if (window.location.href.includes(urlWRJoin)) {
      Join.clearCacheOnMobile();
    }
  }
}

export default new User();
