
class GDPR {
  constructor() {
    $(document).on('gdpr:init', this.gdprInitialize);
    this.gdprInitialize();
  }

  gdprInitialize() {
    $('.gdpr-component').each((i, el) => {
      let $element = $(el),
        $disclaimerText = $element.find('.info-header'),
        $readMoreDisclaimerText = $element.find('#gdpr-rm-terms');

      // Get the first terms and conditions paragraph and hide the others.
      // Check that there is more than 1 paragraph if not, there is no reason to hide anything.
      if (!$element.data('gdprinit') && $disclaimerText.find('p').length > 1) {
        // Duplicate the content and remove the first paragraph
        $readMoreDisclaimerText.html($disclaimerText.html());
        $readMoreDisclaimerText.find('p').first()
          .remove();
        $disclaimerText.html($disclaimerText.find('p').first());
        $element.data('gdprinit', true);
      }

      $element.find('#gdpr-rm-terms').on('hide.bs.collapse show.bs.collapse', (e) => {
        $(e.currentTarget).siblings('.info-header')
          .attr('aria-expanded', $(e.currentTarget).attr('aria-expanded'));
      });
    });
  }
}

export default new GDPR();
