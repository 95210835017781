import { scrollToElement } from './animate.js';
import { EventHandler } from '../../base/utils.js';
import Form from './form.js';
import Modal from '../components/modal.js';
import {
  checkVulnerableUrl,
  getParameterByName
} from '../../base/utils.js';
import Spinner from '../components/spinner.js';
import { requestUrls, templateUrls } from '../../base/vars.js';
import Login from '../../components/login.js';

class Errors {
  constructor() {
    $(document).ajaxComplete((event, xhr, settings) => {
      if (settings.url.match(/WHG/)) {
        this.handleResponse(event, xhr, settings);
      }
    });
  }
  isInformationalError(code) {
    return code ? code.toString().startsWith('1') : false;
  }
  isSuccessError(code) {
    return code ? code.toString().startsWith('2') : false;
  }
  isRedirectionError(code) {
    return code ? code.toString().startsWith('3') : false;
  }
  isClientError(code) {
    return code ? code.toString().startsWith('4') : false;
  }
  isServerError(code) {
    return code ? code.toString().startsWith('5') : false;
  }
  isOlsonAuthenticationError(code) {
    let codes = ['1000', '0004', '0401', '1001', '0003', '1002'];
    return code ? codes.toString().indexOf(code.toString()) > -1 : false;
  }
  isOlsonComponentError(code) {
    let codes = ['0002', '1003', '1004', '1005', '1006', '1007', '1008', '1009', '1010', '1020', '1025', '1142', '2010', '0006', '2011', '2110', '2201', '2202', '2203', '2204', '2205', '2401', '2402', '2403', '2500', '2501', '2502', '2503', '2504', '2505', '2602', '2604', '4000', '4001', '4002', '4003', '4004', '4005', '4006', '4007', '4008', '4009', '4010', '0400', '0007', '2015', '2020', '2025', '2601', '2603', '0402', '2206', '2404', '2506', '2026', '2508', '2509', '2511', '2512', '2513'];
    return code ? codes.indexOf(code.toString()) > -1 : false;
  }
  isOlsonSystemError(code) {
    let codes = ['0001', '3000', '5000', '0008', '0009', '0010', '0010', '0020'];
    return code ? codes.indexOf(code.toString()) > -1 : false;
  }
  showBanner(error) {
    $('.notification-banner').remove();
    Modal.close();
    $(document).trigger('forceCloseDropdowns');
    if ($('.header-offset-element').length > 0) {
      // $('.header-offset-element').after('<div class="notification-banner container-fluid" role="alert">' + error + '</div>');
    } else {
      // $('header').after('<div class="notification-banner container-fluid" role="alert">' + error + '</div>');
    }
    scrollToElement($('body'));
  }
  /* Pass in context to your ajax call to give the error handler options
  context: {
    onFail: {
      ignoreAnalytics: boolean, // optional, ignore calling analytics on the ajax complete (handle specific scenario in component)
      analyticsEvent: string, // optional, name of the analytics event
      ignoreAllCodes: boolean, // optional, will ignore all errors
      ignoreCodes: array, // optional, will ignore rendering errors for codes in array
      scrollToElement: $element, // optional, will scroll the page to a specific element, default uses formElement element if not specified
      scrollOptions: object, // optional, ability to add an options that are passed into scroll function
      formElement: $element, // optional, this will be the form element,
      errorElement: $element, // optional, this will render the error to a specific element, default will look for a flex-form-row element
      removeSpinnerFrom: $element, // optional, will remove the spinner from a specific element, default uses formElement element if not specified
      enableSubmit: $element, // optional, will enable specific submit button, default uses formElement element to find .submit button
      redirect: string, // optional, will redirect if url is present
      closeModal: boolean // optional, wil close any modals open
    }
  }
  */
  handleResponse(event, xhr, settings) {
    let errorCode = null,
      context = settings && settings.context && settings.context.onFail ? settings.context.onFail : false,
      isBWS = settings.url.match(/BWS/) ? true : false;

    if (!context.ignoreAllCodes || (context.ignoreCodes && errorCode && context.ignoreCodes.indexOf(errorCode) == -1)) {
      if (xhr.responseJSON && xhr.responseJSON.ErrorCode) {
        errorCode = xhr.responseJSON && xhr.responseJSON.ErrorCode;
      } else if (this.isClientError(xhr.status) || this.isServerError(xhr.status)) {
        errorCode = xhr.status;
      }
    }

    // Handle error code scenarios
    if (errorCode) {
      console.log('ERROR CODE:', errorCode);
      if (getParameterByName('debug') == 'true') {
        console.log('status:', errorCode, xhr);
      }

      // Handle redirect
      if (context.redirect && checkVulnerableUrl(context.redirect)) {
        window.location.assign(context.redirect);
      }

      if (context.formElement) {
        if (!context.errorElement) {
          if (context.formElement.find('.flex-form-row').length > 0) {
            context.errorElement = context.formElement.find('.flex-form-row').eq(0);
          } else if (context.formElement.find('.form-error').length > 0) {
            context.errorElement = context.formElement.find('.form-error');
          } else {
            context.errorElement = context.formElement;
          }
        }
        if (!context.removeSpinnerFrom) {
          context.removeSpinnerFrom = context.formElement;
        }
        if (!context.enableSubmit) {
          context.enableSubmit = $(context.formElement).find('.submit');
        }
      }

      // Handle analytics event
      if (!context.ignoreAnalytics) {
        let msg = (xhr.responseJSON && xhr.responseJSON.ErrorMessage) ? xhr.responseJSON.ErrorMessage : '';
        if (context.analyticsEvent) {
          EventHandler.triggerEvent(context.analyticsEvent, {
            errorMessage: msg || '',
            errorPath: (settings && settings.url) ? settings.url : ''
          });
        } else {
          EventHandler.triggerEvent('rewards-error', {
            errorMessage: msg || '',
            errorPath: (settings && settings.url) ? settings.url : ''
          });
        }
      } else {
        console.log('ignoring analytics');
      }

      let $errorEl;
      const errorExcepted = xhr && xhr.responseJSON && xhr.responseJSON.ServicePath ? this.olsonAuthenticationErrorException(errorCode, xhr.responseJSON.ServicePath) : false;

      // Authentication Errors
      if (this.isOlsonAuthenticationError(errorCode) && !errorExcepted) {
        if (templateUrls.loginPage) {
          console.log('redirect to login page');
          Login.redirectToOktaLogin();
        } else {
          console.log('no login page authored');
        }
      } else if (xhr && xhr.responseJSON && xhr.responseJSON.ErrorMessage) {
        // Application Errors
        if (this.isOlsonComponentError(errorCode)) {
          if (context.formElement) {
            $errorEl = Form.showFormError(context.errorElement, xhr.responseJSON.ErrorMessage);
          } else if (!isBWS) {
            this.showBanner(xhr.responseJSON.ErrorMessage);
          }
        } else if (!this.isOlsonAuthenticationError(errorCode) && !this.isOlsonComponentError(errorCode) && !this.isOlsonSystemError(errorCode) && !isBWS) {
          this.showBanner(xhr.responseJSON.ErrorMessage);
        }
      } else if (this.isOlsonSystemError(errorCode) || this.isServerError(errorCode) || (this.isClientError(errorCode) && !xhr.responseJSON)) {
        // System Errors
        if (window.genericError) {
          if (context.formElement) {
            $errorEl = Form.showFormError(context.errorElement, window.genericError);
          } else if (!isBWS) {
            this.showBanner(window.genericError);
          }
        } else {
          console.log('no generic error message authored');
        }
      }

      // The Form.showFormError function will render the error message before the closest
      // form input which may be a child of the formElement element so adjusting the
      // scrollToElement to be the actual error message element
      if (!context.scrollToElement && $errorEl && $errorEl.length > 0) {
        context.scrollToElement = $('.form-error:visible').eq(0);
      }

      // Scroll to element
      if (context.scrollToElement && context.formElement && $(context.formElement).parents('header').length == 0 && $(context.formElement).parents('.modal').length == 0) {
        if (!context.scrollOptions) {
          context.scrollOptions = {
            'offset': 10
          };
        }
        scrollToElement(context.scrollToElement, context.scrollOptions);
      }

      // Enable submit button
      if (context.enableSubmit) {
        Form.enableFormField(context.enableSubmit);
      }

      // Remove spinner
      if (context.removeSpinnerFrom) {
        Spinner.remove(context.removeSpinnerFrom);
      }

      // Close modal
      if (context.closeModal) {
        Modal.close();
      }
    } else {
      if (getParameterByName('debug') == 'true') {
        console.info('status:', xhr.status, xhr);
      }
      if (context && context.formElement) {
        Form.removeFormError(context.formElement);
      }
    }
  }

  olsonAuthenticationErrorException(errorCode, errorService) {
    //exceptions can be added as needed
    const errorExceptions = {
      '1001': requestUrls.memberSearch, // incorrect user info provided on the FTSI page
    };

    return errorExceptions[errorCode] == errorService ? true : false;
  }
}

export default new Errors();
