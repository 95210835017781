
class Collapse {
  constructor() {
    this.name = 'collapse';
    $(document).on('click', '.collapse-trigger', (e) => this.clickHandler(e));
  }
  clickHandler(e) {
    let $trigger = $(e.currentTarget);
    let $collapse = $($trigger.data('target'));

    if (!$collapse.hasClass('collapsing')) {
      $collapse.collapse('toggle');

      let expanded = $trigger.attr('aria-expanded');
      expanded = (expanded == 'true');

      $trigger.attr('aria-expanded', expanded ? 'false' : 'true');

      if (expanded) {
        $trigger.addClass('collapsed');
      } else {
        $trigger.removeClass('collapsed');
      }
    }
  }
}

export default new Collapse();
