import { exists } from '../../base/dom-utils.js';
import Handlebars from 'handlebars';
import Spinner from './spinner.js';
import Form from '../base/form.js';
import { ENROLLMENT, FTSI, SESSION_PROFILE, requestUrls } from '../../base/vars.js';
import { CookieHandler, mapBusinessTravelerType } from '../../base/utils.js';
import { getLocalStorage, UserHandler as SessionUserHandler } from '../../base/session-handler.js';
class AccountSetupCarousel {
  constructor() {
    if (exists('.account-setup-carousel')) {
      this.initHandleBar();
      this.initCarousel();
      this.formData = {};
      this.onUserUpdated();
    }
  }

  onUserUpdated() {
    this.disableSliding();
    $('.business-enrollment-wrapper').addClass('no-interaction');

    $(document).one('user:profile:updated', () => {
      this.enableSliding();
      this.formInit(); // form must init after carousel
      this.initButtons();
      this.formData = {};
      Form.displayLockedCountry();
      this.attachAppStyles();
      this.initBusinessEnrollmentListener();
      $('.business-enrollment-wrapper').removeClass('no-interaction');
      $('#addressType0 option[value=""]').remove();
    });
  }

  initCarousel() {
    const carousel = $('.mpc-slick-carousel'),
      profileButtons = $('.profile-buttons'),
      completeButtons = $('.complete-buttons');

    // unhide before slick adaptive height
    $('.business-enrollment-select').removeClass('hide');

    $(carousel).slick({
      arrows: false,
      dots: true,
      infinite: false,
      adaptiveHeight: true,
      rows: 0,
    });

    $(carousel).on('afterChange', (slick, slide) => {
      $('.form-error-carousel').hide();
      if (slide.currentSlide === 0) {
        profileButtons.removeClass('hidden');
        completeButtons.addClass('hidden');
      } else if (slide.currentSlide === 1) {
        profileButtons.addClass('hidden');
        completeButtons.removeClass('hidden');
      }
    });

    $(carousel).on('beforeChange', (slick, slide) => {
      if (slide.currentSlide === 0) {
        $('#accountSetup').data('formValidation').validate();
        if ($('#accountSetup').data('formValidation').isValid()) {
          this.formData = this.createFormData();
        }
      }
    });

    const enrollheadline = $('.enrollheadline');
    if (enrollheadline) {
      enrollheadline.parent().addClass('account-setup-container');
    }
  }

  enableSliding() {
    const carousel = $('.mpc-slick-carousel');
    $(carousel).slick('slickSetOption', {
      draggable: true,
      swipe: true,
      touchMove: true,
      accessibility: true
    }, true);
    $(carousel).find('.slick-dots').removeClass('no-interaction');
    Form.enableFormField($('.nextButton'));
  }

  disableSliding() {
    const carousel = $('.mpc-slick-carousel');
    $(carousel).slick('slickSetOption', {
      draggable: false,
      swipe: false,
      touchMove: false,
      accessibility: false
    }, false);
    $(carousel).find('.slick-dots').addClass('no-interaction');
    Form.disableFormField($('.nextButton'));
  }

  initButtons() {
    const profileButtons = $('.profile-buttons'),
      nextButton = $('.nextButton'),
      completeButtons = $('.complete-buttons'),
      completeButton = $('.completeButton'),
      skipSetupButton = $('.skipSetupButton'),
      skipCompleteButton = $('.skipCompleteButton'),
      statusMatchUrl = $('.statusMatchUrl').attr('href'),
      carousel = $('.mpc-slick-carousel'),
      urlParams = new URLSearchParams(window.location.search),
      isStatusMatch = urlParams.has('status-match');
  
    if (isStatusMatch && statusMatchUrl) {
      skipCompleteButton.attr('href', statusMatchUrl);
      completeButton.attr('href', statusMatchUrl);
      skipSetupButton.attr('href', statusMatchUrl);
    }
    const redirectUrl = completeButton.attr('href');

    nextButton.on('click', () => {
      carousel.slick('slickNext');
      profileButtons.addClass('hidden');
      completeButtons.removeClass('hidden');
    });

    completeButtons.addClass('hidden');

    if (exists($('#accountSetup'))) {
      completeButton.removeAttr('href');
      completeButton.on('click', (e) => {
        e.preventDefault();
        this.postForm(this.formData, redirectUrl);
      });
    }
  }

  initHandleBar() {
    const enrollmentInfo = getLocalStorage(ENROLLMENT),
      ftsiInfo = getLocalStorage(FTSI),
      sessionProfile = getLocalStorage(SESSION_PROFILE),
      userDetails = SessionUserHandler.getOktaUser();
    let userFirstName = 'User'; // default user first name set to
    let userAccountNumber = '0000000000'; // default user account number set to
    // set username
    if (enrollmentInfo && enrollmentInfo.name) {
      userFirstName = enrollmentInfo.name;
    } else if (ftsiInfo && ftsiInfo.name) {
      userFirstName = ftsiInfo.name;
    } else if (ftsiInfo && ftsiInfo.firstName) {
      userFirstName = ftsiInfo.firstName;
    } else if (window.User && window.User.firstName) {
      userFirstName = window.User.firstName;
    } else if (window.User && window.User.Customer && window.User.Customer.PersonName && window.User.Customer.PersonName.GivenName) {
      userFirstName = window.User.Customer.PersonName.GivenName;
    } else if (window.User && window.User.AccountInfo && window.User.AccountInfo.Username) {
      userFirstName = window.User.AccountInfo.Username;
    } else if (sessionProfile && sessionProfile.givenName) {
      userFirstName = sessionProfile.givenName;
    } else if (userDetails && userDetails.nickname) {
      userFirstName = userDetails.nickname;
    } else {
      console.error('Neither name nor nickname is found');
    }
    // set account number
    if (enrollmentInfo && enrollmentInfo.accountNumber) {
      userAccountNumber = enrollmentInfo.accountNumber;
    } else if (ftsiInfo && ftsiInfo.accountNumber) {
      userAccountNumber = ftsiInfo.accountNumber;
    } else if (ftsiInfo && ftsiInfo.memberIdentifier) {
      userAccountNumber = ftsiInfo.memberIdentifier;
    } else if (window.User && window.User.accountNumber) {
      userAccountNumber = window.User.accountNumber;
    } else if (sessionProfile && sessionProfile.membershipId) {
      userAccountNumber = sessionProfile.membershipId;
    } else if (userDetails && userDetails.membership_number) {      
      userAccountNumber = userDetails.membership_number;
    } else {
      console.error('Neither account number nor member identifier is found');
    }
    const userInfo = {
      givenName: userFirstName,
      accountNumber: userAccountNumber,
    };
    const source = $('.main-container').html();
    const template = Handlebars.compile(source);
    const html = template(userInfo);
    $('.main-container').html(html);
  }

  attachAppStyles() {
    const urlParams = new URLSearchParams(window.location.search),
      isPhApp = urlParams.has('platform') && urlParams.has('adobeID');
    if (isPhApp) {
      const formContentWidthElement = document.querySelectorAll('.form-content-width')[1];
      formContentWidthElement.style.paddingTop = '35px';
      formContentWidthElement.style.paddingLeft = 0;
      formContentWidthElement.style.paddingRight = 0;
      document.querySelector('.account-title').style.marginBottom = 0;
    }
  }
  
  checkBusinessEnrollmentSelect() {
    if ($('#businessEnrollmentSelect').val()) {
      this.enableSliding();
    } else {
      this.disableSliding();
    }
  }

  initBusinessEnrollmentListener() {
    const $beCheckbox = $('#businessEnrollmentCheckbox'),
      $beSelect = $('#businessEnrollmentSelect');
    if ($beCheckbox.length > 0) {
      if ($beCheckbox.is(':checked')) {
        $('#businessEnrollmentSelect').removeAttr('disabled');
        this.checkBusinessEnrollmentSelect();
      }
      $(document).on('change', '#businessEnrollmentCheckbox', (e) => {
        if ($beCheckbox.is(':checked')) {
          Form.toggleBussinessEnrollmentDropdown($beCheckbox);
          this.checkBusinessEnrollmentSelect();
        } else {
          $('#accountSetup').formValidation('revalidateField', $beSelect);
          Form.toggleBussinessEnrollmentDropdown($beCheckbox);
          $('.mpc-slick-carousel').slick('setPosition');
        }
      });
    }
  }

  businessEnrollmentValidation(value, validator, $field) {
    let isValid = false;
    if (value) {
      isValid = true;
    } else {
      let $beCheckbox = $field.closest('.business-enrollment-wrapper').find('#businessEnrollmentCheckbox');
      isValid = !$beCheckbox.is(':checked');
    }
    if (!isValid) {
      this.disableSliding();
    } else {
      this.enableSliding();
    }
    $('.mpc-slick-carousel').slick('setPosition');
    return isValid;
  }

  formInit() {
    if (exists($('#accountSetup'))) {
      $('#accountSetup').formValidation({
        live: 'enabled',
        fields: {
          addressType0: {
            validators: {
              notEmpty: {
                enabled: true,
              },
            },
          },
          'address1-0': {
            validators: {
              stringLength: {
                max: 200,
              },
            },
          },
          'address2-0': {
            validators: {
              stringLength: {
                max: 200,
              },
            },
          },
          'address3-0': {
            validators: {
              stringLength: {
                max: 200,
              },
            },
          },
          country0: {
            validators: {
              notEmpty: {
                enabled: true,
              },
            },
          },
          city0: {
            validators: {
              stringLength: {
                max: 100,
              },
            },
          },
          state0: {},
          zipCode0: {
            enabled: true,
            validators: {
              stringLength: {
                min: 5,
                max: 12,
              },
              notEmpty: {
                enabled: true,
              },
            },
          },
          'businessEnrollmentCheckbox':{},
          'businessEnrollmentSelect': {
            trigger: 'blur change',
            validators: {
              callback: {
                callback: (value, validator, $field) => this.businessEnrollmentValidation(value, validator, $field)
              }
            }
          },
        },
      })
      .on('err.field.fv', ()=> {
        $('.mpc-slick-carousel').slick('setPosition');
      });
    }
  }

  createFormData() {
    // reset buttons
    $('.completeButton').addClass('hidden');
    $('.skipCompleteButton').removeClass('hidden');
    // deep clone user object rather than referencing for when user go back to change.
    let data = JSON.parse(JSON.stringify(window.User)),
      inputs = _.union(
        _.toArray($('#accountSetup input')),
        _.toArray($('#accountSetup select'))
      );

    // instantiate update flags
    data = {
      ...data,
      updateCustomer: false,
      updateAddress: false,
      updateAlias: false,
      updatePreferences: false,
      updateEmail: false,
    };

    // rename accountNumber to memberIdentifier
    data.memberIdentifier = data.accountNumber;
    delete data.accountNumber;
    delete data.success;

    $(inputs).each((i, e) => {
      switch ($(e).attr('id')) {
      case 'addressType0':
        if ($(e).find('option:selected').text() && !_.isEqual(data.addresses[0].typeCode, $(e).find('option:selected').text())) {
          data.updateAddress = true;
          let addressTypeCode = $(e).find('option:selected').val();
          data.addresses[0].typeCode = (addressTypeCode == '1') ? 'Home' : 'Business';
        }
        break;
      case 'address1-0':
        if ($(e).val() && !_.isEqual(data.addresses[0].address1, $(e).val())) {
          data.updateAddress = true;
          data.addresses[0].address1 = $(e).val();
        }
        break;
      case 'address2-0':
        if ($(e).val() && !_.isEqual(data.addresses[0].address2, $(e).val())) {
          data.updateAddress = true;
          data.addresses[0].address2 = $(e).val();
        }
        break;
      case 'address3-0':
        if ($(e).val() && !_.isEqual(data.addresses[0].address3, $(e).val())) {
          data.updateAddress = true;
          data.addresses[0].address3 = $(e).val();
        }
        break;
      case 'country0':
        if ($(e).val() && !_.isEqual(data.addresses[0].countryCode, $(e).val())) {
          data.updateAddress = true;
          data.addresses[0].countryCode = $(e).val();
        }
        break;
      case 'city0':
        if ($(e).val() && !_.isEqual(data.addresses[0].city, $(e).val())) {
          data.updateAddress = true;
          data.addresses[0].city = $(e).val();
        }
        break;
      case 'state0':
        if ($(e).val() && !_.isEqual(data.addresses[0].stateCode, $(e).val())) {
          let state = $(e).val();
          if (state.includes('_')) {
            state = state.substring(state.indexOf('_') + 1);
          }
          data.updateAddress = true;
          data.addresses[0].stateCode = state;
        }
        break;
      case 'zipCode0':
        if ($(e).val() && !_.isEqual(data.addresses[0].zip, $(e).val())) {
          data.updateAddress = true;
          data.addresses[0].zip = $(e).val();
        }
        break;
      case 'businessEnrollmentCheckbox': {
        const preference = this.getPreferenceByRuleDetailCode(data.preferences, 'BUSTRAVTYPE')[0];
        const travelingForBusinessChecked = document.querySelector('#businessEnrollmentCheckbox').checked;
        const businessPreferenceValue = mapBusinessTravelerType($('#businessEnrollmentSelect').val());
        if (travelingForBusinessChecked && preference && $(e).prop('checked').toString() && (preference.value != businessPreferenceValue)) {
          data.updatePreferences = true;
          preference.value = businessPreferenceValue;
        } else if (preference.value && !travelingForBusinessChecked) {
          data.updatePreferences = true;
          preference.value = '';
        }
      }
        break;
      default:
        break;
      }
    });

    if (data.updateAddress || data.updatePreferences) {
      $('.completeButton').removeClass('hidden');
      $('.skipCompleteButton').addClass('hidden');
    }
    return data;
  }

  getPreferenceByRuleDetailCode(preferences, code) {
    return preferences.filter(pref => pref.preferenceRuleDetailCode == code);
  }

  postForm(data, redirectUrl) {
    console.trace('profile-setup:postForm is called, redirectUrl:', redirectUrl, 'data:', data);
    this.callUpdateApi(data)
    .then( (callStatus) => {
      if(callStatus == true) {
        window.location.assign(redirectUrl);
      }
      Spinner.remove($('.accountsetupcarousel'));
      console.info('profile-setup:postForm calling update api status:', callStatus);
    })
    .catch((err) => {
      Spinner.remove($('.accountsetupcarousel'));
      console.error('profile-setup:postForm calling update api, Error:', err);
    });
  }

  async callUpdateApi(data) {
    console.trace('callUpdateApi is called, start:', 'data:', data);
    let callStatus = false;
    $('.form-error-carousel').hide();
    Spinner.appendTo($('.accountsetupcarousel'),true, true);
    try{
      const result = await $.ajax({
        type: 'POST',
        contentType: 'application/json',
        url: requestUrls['update'],
        data: JSON.stringify(data),
        context: {
          onFail: {
            scrollToElement: $('.accountsetupcarousel')
          }
        },
      });
      callStatus = true;
      console.info('profile-setup:callUpdateApi is called, success:', 'result:', result);
      CookieHandler.eraseCookie('id_token');
    }catch(e) {
      if (e.responseJSON && e.responseJSON.ErrorMessage) {
        $('.form-error-carousel').html(e.responseJSON.ErrorMessage).show();
      }
      console.error('profile-setup:callUpdateApi is called, failed: result:', e);
    }
    return callStatus;
  }
}


export default new AccountSetupCarousel();
