import Form from '../base/form.js';
import HeaderMobile from './header-mobile.js';
import SSO from './sso.js';
import Modal from './modal.js';
import {
  checkVulnerableUrl,
  getParameterByName,
  EventHandler
} from '../../base/utils.js';
import Redirect from '../base/redirect.js';
import { isMobileWidth } from '../../base/dom-utils.js';
import Spinner from './spinner.js';
import UserHandler from '../base/user.js';
import { requestUrls, templateUrls } from '../../base/vars.js';
import Analytics from '../../base/wyn-analytics-module.js';
import SSODelegate from './sso-delegate.js';

class SecurityVerify {
  constructor() {
    this.redirectUrl = null;
  }
  openSecurityVerify(modal, redirect, options) {
    this.redirectUrl = redirect || templateUrls.redirect;

    if (modal) {
      this.getSecurityVerifyTemplate($('#genericLightbox .modal-content'), options);
    } else {
      this.getSecurityVerifyTemplate($('.centered-container-content .center'), false);
    }
  }
  getSecurityVerifyTemplate(form, options) {
    let context = {
      el: form,
      options: options,
      onFail: {
        formElement: $('#securityVerify')
      }
    };
    $.ajax({
      contentType: 'application/json',
      url: templateUrls['securityVerify'],
      context: context,
      success: (response) => {
        EventHandler.triggerEvent('security_verify', {
          pageName: 'security-verify'
        });
        this.getSecurityVerifyQuestion(context.el, response, options);
      }
    });
  }
  getSecurityVerifyQuestion(form, template, options) {
    let context = {
      el: form,
      template: template,
      options: options,
      onFail: {
        formElement: $(form).hasClass('modal-content') ? $('#securityVerify') : $(form),
        removeSpinnerFrom: $('.sign-in-form')
      }
    };
    Form.disableFormField($(form).find('.submit'));
    Form.removeFormError($(form));
    $.ajax({
      url: requestUrls['twoFormVerificationRetrieve'],
      contentType: 'application/json',
      context: context,
      success: (response) => {
        $(context.el).html(context.template);
        Spinner.remove($('.sign-in-form'));
        Form.enableFormField($('.sign-in-form').find('.submit'));
        $(document).trigger('checkEmptyInput');
        this.addFormValidation();
        this.createQuestion(response);
        if ($(context.el).hasClass('modal-content')) {
          if (isMobileWidth()) {
            HeaderMobile.closeDropdowns();
          }
          Modal.show(context.options);
          Form.enableFormField($('.sign-in-form').find('.submit'));
        }
        Spinner.remove($('.sign-in-form'));
      }
    });
  }
  createQuestion(response) {
    $('#securityVerify .question').eq(0)
      .attr('question-id', response.data.id)
      .html(response.data.question);
    Spinner.remove($('#securityVerify'));
  }
  postSecurityAnswer(form) {
    let data = JSON.stringify({
      'id': $(form).find('.question')
        .attr('question-id'),
      'answer': $(form).find('#answer1')
        .val()
    });
    Spinner.appendTo($(form));
    Form.disableFormField($(form).find('.submit'));
    $.ajax({
      url: requestUrls['twoFormVerificationSubmit'],
      type: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      data: data,
      context: {
        onFail: {
          formElement: $('#securityVerify')
        }
      },
      success: (response) => {
        let omitKeys = ['SecurityInfo.SecurityQuestions.0.question'];

        UserHandler.getProfile((userProfile) => {
          EventHandler.triggerEvent('signin-complete', Analytics.getUserData(userProfile));

          if (getParameterByName('catalogOfOrigin') || getParameterByName('destinationLocation') || getParameterByName('ssoType') === '2') {
            SSO.handleOutbound(window.location.href, false, true);
          } else if(getParameterByName('ssoType') === '1') {
            SSODelegate.getPoints(window.location.href, false, true);
          } else if (checkVulnerableUrl(getParameterByName('redirect'))) {
            Redirect.deeplinkRedirect();
          } else if (this.redirectUrl) {
            window.location.assign(this.redirectUrl);
          } else {
            $(document).trigger('securityquestion:verify:complete');
            if ($('#securityVerify').parents('.modal-content').length > 0) {
              Modal.close();
            }
            Spinner.remove($('#securityVerify'));
          }
        }, omitKeys);
      }
    });
  }
  addFormValidation() {
    let $answer1Error = $($('.answer1').parent()
        .find('.help-block')[0]),
      answer1ErrorMessage = $answer1Error.text();

    $answer1Error.remove();

    $('#securityVerify').formValidation({
      button: {
        selector: '.submit',
        disabled: ''
      },
      live: 'submitted',
      fields: {
        'answer1': {
          validators: {
            notEmpty: {
              enabled: true,
              message: answer1ErrorMessage
            }
          }
        }
      }
    })
      .on('success.form.fv', (e, data) => {
        e.preventDefault();
        this.postSecurityAnswer($(e.target));
      });
  }
}

export default new SecurityVerify();
