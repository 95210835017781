import { isDesktopWidth } from '../../base/dom-utils.js';

class HeaderMobileButton {
  constructor() {
    if ($('.header-mobile-button').length > 0) {
      this.bindOpenPanels();
      this.bindClosePanels();
    }
  }
  bindOpenPanels() {
    $(document).on('click', 'button.header-mobile-button', (e) => {
      let panel = $(e.currentTarget).data('panel');
      if (!isDesktopWidth() && panel) {
        $(e.currentTarget).parents('.dropdown[data-dropdown="main"]')
          .find('.header-mobile-panel[data-panel=' + panel + ']')
          .addClass('panel-current')
          .attr('aria-expanded', true);
        $('header.mobile-nav .dropdown[data-dropdown="main"]').addClass('panel-open');
        $('header.mobile-nav .panel-right').one('webkitTransitionEnd otransitionend msTransitionEnd transitionend', (e) => {
          $('header.mobile-nav .panel-main').hide();
        });
      }
    });
  }
  bindClosePanels() {
    $(document).on('click', '.header-mobile-panel-top .back', (e) => {
      if (!isDesktopWidth()) {
        $('header.mobile-nav .panel-main').show();
        $('header.mobile-nav .dropdown[data-dropdown="main"]').removeClass('panel-open');
        $('header.mobile-nav .panel-right').one('webkitTransitionEnd otransitionend msTransitionEnd transitionend', (e) => {
          if (parseInt($(e.currentTarget).css('transform')
            .split(',')[4]) !== 0) {
            $('.header-mobile-panel.panel-current').removeClass('panel-current')
              .attr('aria-expanded', false);
          }
        });
      }
    });
  }
}

export default new HeaderMobileButton();
