import { exists } from '../../base/dom-utils';
import Handlebars from 'handlebars';
import { getLocalStorage } from '../../base/session-handler';
import { ENROLLMENT, FTSI, REDIRECT_URL } from '../../base/vars';

class OffersFlow {
  constructor() {
    if (exists('.enrollsuccess')) {
      this.initHandlebar();
      this.initMobileButton();
    }
  }

  initHandlebar() {
    let userFirstName = 'User'; // default user first name set to

    if(getLocalStorage(ENROLLMENT) && getLocalStorage(ENROLLMENT).name){
      userFirstName = getLocalStorage(ENROLLMENT).name;
    }else if(window.User && window.User.firstName) {
      userFirstName = window.User.firstName;
    }else if(window.User && window.User.Customer && window.User.Customer.PersonName && window.User.Customer.PersonName.GivenName) {
      userFirstName = window.User.Customer.PersonName.GivenName;
    }else if(window.User && window.User.AccountInfo && window.User.AccountInfo.Username) {
      userFirstName = window.User.AccountInfo.Username;
    }

    const userInfo = {
      Customer: {
        PersonName: {
          GivenName: userFirstName,
        },
      },
    };
    const source = $('.main-container').html();
    const template = Handlebars.compile(source);
    const html = template(userInfo);
    $('.main-container').html(html);
  }

  initMobileButton() {
    const goToAccountButton = $('.go-to-account'),
      mobileButton = $('.mobile-complete-buttons'),
      promoButtom = $('.promo-complete-buttons'),
      ftsiInfo = getLocalStorage(FTSI),
      urlParams = new URLSearchParams(window.location.search),
      enrollmentInfo = getLocalStorage(ENROLLMENT),
      promoRedirectUrl = getLocalStorage(REDIRECT_URL),
      hasPhAppParams = urlParams.has('platform') && urlParams.has('adobeID');
    let hasPhAppFlag = false;

    if (ftsiInfo && ftsiInfo.isPhApp) {
      hasPhAppFlag = true;
    }
    if (enrollmentInfo && enrollmentInfo.isPhApp) {
      hasPhAppFlag = true;
    }
    if (hasPhAppParams || hasPhAppFlag) {
      mobileButton.removeClass('hidden');
      goToAccountButton.addClass('hidden');
    } else if (promoRedirectUrl && promoRedirectUrl.url) {
      promoButtom.removeClass('hidden');
      goToAccountButton.addClass('hidden');
      const promoCompleteButton = promoButtom.find('.promoCompleteButton');
      const sanitizedUrl = DOMPurify.sanitize(promoRedirectUrl.url);
      promoCompleteButton.removeAttr('href');
      promoCompleteButton.on('click', (e) => {
        e.preventDefault();
        localStorage.removeItem(REDIRECT_URL);
        window.location.assign(sanitizedUrl);
      });
    }
  }
}

export default new OffersFlow();
