
import {lazyLoadImage} from '../../base/lazy-load.js';

class MobileHomepageHero {
  constructor() {
    this.$el = $('.mobile-homepage-hero');
    this.$btn = this.$el.find('.search-toggle-btn');

    this.loadImage();
    this.enableButton();
  }

  enableButton() {
    this.$btn.click(() => {
      $('[data-dropdown="book"]').click();
    });
  }

  loadImage() {
    lazyLoadImage(this.$el);
  }
}

export default new MobileHomepageHero();
