
class CustomValidation {
  passwordLengthRule(value) {
    return value.length >= 8;
  }
  passwordLengthCheck(el) {
    let $validatorIcon = $(el).parents('.flex-form-row')
        .find('.validator.pw-length i'),
      valid = true;
    if (this.passwordLengthRule($(el).val())) {
      $validatorIcon.removeClass('validator-circle').addClass('whg-core-icon-check');
    } else {
      $validatorIcon.addClass('validator-circle').removeClass('whg-core-icon-check');
      valid = false;
    }
    return valid;
  }
  passwordNumberRule(value) {
    return value.match(/.*[0-9].*/);
  }
  passwordNumberCheck(el) {
    let $validatorIcon = $(el).parents('.flex-form-row')
        .find('.validator.pw-number i'),
      valid = true;
    if (this.passwordNumberRule($(el).val())) {
      $validatorIcon.removeClass('validator-circle').addClass('whg-core-icon-check');
    } else {
      $validatorIcon.addClass('validator-circle').removeClass('whg-core-icon-check');
      valid = false;
    }
    return valid;
  }
  passwordAlphaRule(value) {
    return value.match(/(?=.*[a-z])/i);
  }
  passwordAlphaCheck(el) {
    let $validatorIcon = $(el).parents('.flex-form-row')
        .find('.validator.pw-alpha i'),
      valid = true;
    if (this.passwordAlphaRule($(el).val())) {
      $validatorIcon.removeClass('validator-circle').addClass('whg-core-icon-check');
    } else {
      $validatorIcon.addClass('validator-circle').removeClass('whg-core-icon-check');
      valid = false;
    }
    return valid;
  }
  passwordValidation(el) {
    let length = this.passwordLengthCheck(el),
      number = this.passwordNumberCheck(el),
      alpha = this.passwordAlphaCheck(el);
    return length && number && alpha;
  }
  usernameLengthRule(value) {
    return value.length >= 8 && value.length <= 16;
  }
  usernameLengthCheck(el) {
    let $validatorIcon = $(el).parents('.flex-form-row')
        .find('.validator.pw-length i'),
      valid = true;
    if (this.usernameLengthRule($(el).val())) {
      $validatorIcon.removeClass('validator-circle').addClass('whg-core-icon-check');
    } else {
      $validatorIcon.addClass('validator-circle').removeClass('whg-core-icon-check');
      valid = false;
    }
    return valid;
  }
  usernameCharactersRule(value) {
    return value.match(/^[^(<">'!%\\)]+$/);
  }
  usernameCharactersCheck(el) {
    let $validatorIcon = $(el).parents('.flex-form-row')
        .find('.validator.pw-alpha i'),
      valid = true;
    if (this.usernameCharactersRule($(el).val())) {
      $validatorIcon.removeClass('validator-circle').addClass('whg-core-icon-check');
    } else {
      $validatorIcon.addClass('validator-circle').removeClass('whg-core-icon-check');
      valid = false;
    }
    return valid;
  }
  usernameValidation(el) {
    let length = this.usernameLengthCheck(el),
      char = this.usernameCharactersCheck(el);
    return length && char;
  }
  passwordMatchRule(value, value1) {
    return value == value1;
  }
  passwordMatchCheck(el, e2) {
    let $validatorIcon = $(el).parents('.flex-form-row')
        .find('.validator.pw-length i'),
      valid = true;
    if (this.passwordMatchRule($(el).val(), e2)) {
      $validatorIcon.removeClass('validator-circle').addClass('whg-core-icon-check');
    } else {
      $validatorIcon.addClass('validator-circle').removeClass('whg-core-icon-check');
      valid = false;
    }
    return valid;
  }
  confirmPasswordValidation(el, e2) {
    return this.passwordMatchCheck(el, e2);
  }
  hidePasswordMatchError(el, e2) {
    let valid = false;
    if (this.passwordMatchRule(el.value, e2.value)) {
      valid = true;
      el.parentElement.querySelector('.validator.pw-length').style.display = 'none';
    } else {
      el.parentElement.querySelector('.validator.pw-length').style.display = '';
    }
    return valid;
  }
}

export default new CustomValidation();
