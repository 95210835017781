
import Spinner from './spinner.js';
import { mapTierCode } from '../../base/utils.js';

class MemberCard {
  constructor() {
    this.memberCard = '.member-card',
    this.$memberCard = $(this.memberCard);

    if (this.$memberCard.length > 0) {
      this.renderMemberCard();
    }
  }

  renderMemberCard() {
    Spinner.appendTo(this.$memberCard);
    $(document).on('user:profile:updated', () => {
      let level = this.getLevelFromUser(),
        imgData = this.$memberCard.find('.img-container').data(),
        $imgEl = this.$memberCard.find('.card-img');

      if (!level) {
        level = this.getLevelFromPage();
      }

      if (level && imgData && imgData[level]) {
        this.$memberCard.removeClass('blue');
        this.$memberCard.addClass(level);
        $imgEl.each((i, el) => {
          $(el).attr('src', imgData[level]);
        });
        $imgEl.attr('src', imgData[level]);
        if (level.toLowerCase() === 'titanium') {
          this.$memberCard.find('.details-nights:not(".titanium-only")').hide();
        } else {
          this.$memberCard.find('.details-nights:not(".titanium-only")').show();
        }
      }
      Spinner.remove(this.$memberCard);
      this.$memberCard.find('.hidden').not('.member-attribute').removeClass('hidden');
      if (window.User && window.User.earningTier && window.User.earningTier.requiredAmount) {
        this.$memberCard.find('[data-binding="nightsToNextLevel"]').text(window.User.earningTier.requiredAmount);
      }
    });
  }

  getLevelFromPage() {
    let matches = document.querySelectorAll('.page')[0].className.match(/member\-level\-(\w+)/);
    return matches[1];
  }

  getLevelFromUser() {
    let tier = window.User && window.User.currentTier && window.User.currentTier.tierCode ? 
      mapTierCode(window.User.currentTier.tierCode) : null;
    return tier ? tier.toLowerCase() : null;
  }
}

export default new MemberCard();
