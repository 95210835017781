import Form from '../base/form.js';
import Spinner from './spinner.js';
import UserHandler from '../base/user.js';
import { requestUrls } from '../../base/vars.js';

// MY PROFILE USERNAME SETUP

class SecurityUsernameForm {
  constructor() {
    this.formSelector = '#securityUpdateUsername';
    this.$form = $(this.formSelector);

    if (this.$form.length > 0) {
      $(document).one('user:profile:updated', (e, userObject) => {
        this.$form.show();
        this.formInit();
      });
    }
  }

  formInit() {
    let $usernameError = $($('#username').parent()
        .find('.help-block')[0]),
      usernameErrorMessage = $usernameError.text();

    $usernameError.remove();

    this.$form
      .on('init.form.fv', (e, data) => {
        Form.removeFormError($(e.target));
        Form.disableFormField(this.$form.find('.submit'));
      })
      .formValidation({
        button: {
          selector: '.submit',
          disabled: ''
        },
        live: 'enabled',
        fields: {
          username: {
            validators: {
              stringLength: {
                min: 8,
                max: 16,
                message: usernameErrorMessage
              },
              regexp: {
                regexp: /^[^(<">'!%\)]+$/,
                message: usernameErrorMessage
              }
            }
          }
        }
      })
      .on('success.form.fv', (e) => {
        e.preventDefault();
        this.postForm();
      })
      .on('err.field.fv', Form.handleMultipleErrorLabels);
  }

  postForm() {
    let $form = this.$form,
      formData = {
        UpdateAccountInfo: true,
        AccountInfo: {
          Username: $('#username').val()
        }
      };

    Form.disableFormField($form.find('.submit'));
    Form.disableFormField($form.find('.cancel'));

    $.ajax({
      type: 'POST',
      contentType: 'application/json',
      url: requestUrls['updateProfile'],
      data: JSON.stringify(formData),
      context: {
        onFail: {
          formElement: $form,
          scrollToElement: $form
        }
      },
      success: () => {
        Spinner.remove($form);
        Form.showSuccessMessage($form);
        UserHandler.getProfile();
      },
      complete: () => {
        Form.enableFormField($form.find('.cancel'));
      }
    });
  }
}

export default new SecurityUsernameForm();
