import {
  addMobileOverflowFix,
  removeMobileOverflowFix,
  isMobileWidth
} from '../../base/dom-utils.js';

class SelectMenuButton {
  constructor() {
    _.each($('.select-menu-dropdown'), (el, i) => {
      this.bindEvents(el);
    });
  }
  bindEvents(el) {
    let $menuDropdown = $(el),
      $dropdownTrigger = $menuDropdown.find('.dropdown-toggle'),
      $dropdownMenu = $menuDropdown.find('.dropdown-container'),
      $mobileCloseButton = $menuDropdown.find('.btn-cancel');

    $menuDropdown.on('set', (e, newValue) => {
      this.setValue(newValue, el);
      e.stopPropagation();
    });

    $menuDropdown.on('reset', (e) => this.resetDropdown(e, el)) // Manually trigger reset
      .closest('form')
      .on('reset', (e) => this.resetDropdown(e, el)); // Reset for button[type="reset"] click

    $menuDropdown.focusout((e) => {
      // At time of event there is no active element yet so need to wait till after event
      e.stopPropagation();
      setTimeout(() => {
        // Need to ignore focusout events from elements within menu
        if (!$menuDropdown.find(document.activeElement).length) {
          this.publicCloseDropdown(el, true);
        }
      }, 300);
    });

    $dropdownTrigger.click((e) => this.toggleDropdown(el))
      .keydown((e) => this.dropdownTriggerKeyHandler(e, el));

    $dropdownMenu.keydown((e) => this.dropDownMenuKeyHandler(e, el));
    $dropdownMenu.find('.category-list').on('click', '.select-menuitem', (e) => this.selectMenuItem(e.currentTarget, el));

    $mobileCloseButton.click((e) => {
      e.preventDefault();
      this.publicCloseDropdown(el);
    });
  }
  dropdownTriggerKeyHandler(e, el) {
    switch (e.key) {
    case 'Up': // IE support
    case 'ArrowUp':
    case 'Down': // IE support
    case 'ArrowDown':
      this.openDropdown(el);
      break;
    default:
      return;
    }
    e.preventDefault();
  }
  dropDownMenuKeyHandler(e, el) {
    switch (e.key) {
    case 'Enter':
    case ' ':
    case 'Spacebar': // IE support
      this.selectMenuItem(e.target, el);
      break;
    case 'Esc': // IE support
    case 'Escape':
      this.publicCloseDropdown(el);
      break;
    case 'Up': // IE support
    case 'ArrowUp':
      this.focusMenuItem(e.target, -1, el);
      break;
    case 'Down': // IE support
    case 'ArrowDown':
      this.focusMenuItem(e.target, 1, el);
      break;
    default:
      return;
    }
    e.preventDefault();
  }
  getSelectedItem(el) {
    return $(el).find('.dropdown-container .select-menuitem[aria-checked="true"]');
  }
  setValue(newValue, el) {
    let $menuDropdown = $(el),
      $dropdownInput = $menuDropdown.find('input'),
      $dropdownTrigger = $menuDropdown.find('.dropdown-toggle'),
      $newItem = $menuDropdown.find('[data-value="' + newValue + '"]'),
      currentValue = $dropdownInput.val();

    if ($newItem.length && newValue !== currentValue) {
      this.getSelectedItem(el).attr('aria-checked', 'false');
      $newItem.attr('aria-checked', 'true');
      $dropdownTrigger.removeClass('empty');
      $dropdownTrigger.find('.select-text').text($newItem.text());
      $dropdownInput.val(newValue);
    }
  }
  focusMenuItem(currentItem, offset, el) {
    let $menuDropdown = $(el),
      $dropdownMenu = $menuDropdown.find('.dropdown-container'),
      $menuItems = $dropdownMenu.find('.select-menuitem'),
      index = _.findIndex($menuItems, currentItem);

    offset = offset || 0;

    let newIndex = index + offset;

    if (newIndex > -1 && newIndex < $menuItems.length) {
      index = newIndex;
    }

    $menuItems.eq(index).focus();
  }
  openDropdown(el) {
    let $menuDropdown = $(el),
      $dropdownTrigger = $menuDropdown.find('.dropdown-toggle'),
      $dropdownMenu = $menuDropdown.find('.dropdown-container'),
      $downArrow = $menuDropdown.find('.caret-down');

    $dropdownMenu.show();
    $dropdownTrigger.addClass('open');
    $downArrow.addClass('arrow-rotate');

    if (isMobileWidth()) {
      addMobileOverflowFix();
    }

    let $menuItems = $dropdownMenu.find('.select-menuitem'),
      $selectedItem = $menuItems.filter('[aria-checked="true"]');

    if ($selectedItem.length) {
      $selectedItem.focus();
    } else {
      $menuItems.first().focus();
    }
  }
  publicCloseDropdown(el, noFocus) {
    let $menuDropdown = $(el),
      $dropdownTrigger = $menuDropdown.find('.dropdown-toggle'),
      $downArrow = $menuDropdown.find('.caret-down'),
      $dropdownMenu = $menuDropdown.find('.dropdown-container');

    $dropdownMenu.hide();
    $dropdownTrigger.removeClass('open');
    $downArrow.removeClass('arrow-rotate');

    if (isMobileWidth()) {
      removeMobileOverflowFix();
    }

    if (!noFocus) {
      $dropdownTrigger.focus();
    }
  }
  toggleDropdown(el) {
    let $menuDropdown = $(el),
      $dropdownTrigger = $menuDropdown.find('.dropdown-toggle');

    if ($dropdownTrigger.hasClass('open')) {
      this.publicCloseDropdown(el);
    } else {
      this.openDropdown(el);
    }
  }
  resetDropdown(e, el) {
    let $menuDropdown = $(el),
      $dropdownInput = $menuDropdown.find('input'),
      $dropdownTrigger = $menuDropdown.find('.dropdown-toggle');

    e.stopPropagation();
    this.getSelectedItem(el).attr('aria-checked', 'false');
    $dropdownTrigger.addClass('empty');
    $dropdownTrigger.find('.select-text').text('');
    $dropdownInput.val('');
  }
  selectMenuItem(menuItem, el) {
    let $menuDropdown = $(el),
      $dropdownInput = $menuDropdown.find('input'),
      event = document.createEvent('Event');

    this.setValue($(menuItem).data('value'), el);
    this.publicCloseDropdown(el);
    event.initEvent('change', true, true);
    $dropdownInput[0].dispatchEvent(event);
  }
}

export default new SelectMenuButton();
