import { isMobileWidth } from '../../base/dom-utils.js';

class FeaturedPropertiesMosaic {
  constructor() {
    if ($('.featured-properties-mosaic-component').length > 0) {
      this.triggerMobileCarousel();
    }
  }

  triggerMobileCarousel() {
    if (isMobileWidth()) {
      $('.mosaic-carousel').each((i, el) => {
        $(el).slick({
          dots: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          rows: 0
        });
      });
    }
  }
}

export default new FeaturedPropertiesMosaic();
