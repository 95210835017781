import { exists } from '../../base/dom-utils.js';
import { CookieHandler, getServiceUrl } from '../../base/utils.js';
import { isPublish, ACCESS_TOKEN, CID_COOKIE, ENROLLMENT, FTSI, UPDATE_PROFILE_PHONE, WR_AUTHENTICATED, _LOCALE_, templateUrls, MFA_PHONE_NUMBER_NEW, MFA_PHONE_NUMBER_NEW_TYPE } from '../../base/vars.js';
import { setLocalStorage, getLocalStorage, getSessionStorage } from '../../base/session-handler.js';
import Login from '../../components/login';
import OktaClient from '../../components/okta-client.js';
import Spinner from '../components/spinner.js';
import Analytics from '../../base/wyn-analytics-module.js';
class VerifyYourIdentity {
  constructor() {
    if (exists('.verifyYourIdentity') && isPublish) {
      this.flow = '';
      const userData = this.getUserDataFromLocalStorage();
      if (this.flow === UPDATE_PROFILE_PHONE) {
        // make ajax call to mfaAssociate
        this.mfaAssociateServiceCall(userData, userData['type']);
      } else {
        this.initSubmitFunction(userData);
      }
      this.initVerificationCodeValidation();
      this.initResendButton(userData);
      this.initAlternativeResendButton(userData);
      this.initStyleClasses();
      this.initLinenumber(userData);
    }
  }

  initVerificationCodeValidation() {
    const verifyCodeInput = $('#verification-code'),
      errorMessage = $('.error-msg-two'),
      submitButton = $('#verifyIdentity button.submit');

    if (!errorMessage.hasClass('hidden')) {
      errorMessage.addClass('hidden');
    }

    verifyCodeInput.on('input', (e) => {
      if (!this.displayValidationError && $('#verification-code').val().length >= 6) {
        this.displayValidationError = true;
      }
      this.codeValidation(e, verifyCodeInput, errorMessage, submitButton);
    });

    verifyCodeInput.on('blur', (e) => {
      if (!this.displayValidationError) {
        this.displayValidationError = true;
      }
      this.codeValidation(e, verifyCodeInput, errorMessage, submitButton);
    });
    submitButton.prop('disabled', true);
  }

  codeValidation(event, verifyCodeInput, errorMessage, submitButton) {
    if (this.displayValidationError) {
      const code = event.currentTarget.value;
      if (!this.hasCharacters(code)) {
        verifyCodeInput.parent().addClass('form-input-error');
        errorMessage.removeClass('hidden');
        submitButton.prop('disabled', true);
      } else {
        errorMessage.addClass('hidden');
        verifyCodeInput.parent().removeClass('form-input-error');
        submitButton.prop('disabled', false);
      }
    }
  }

  hasCharacters(code) {
    return code.length === 6;
  }

  initSubmitFunction(userData) {
    console.trace('initSubmitFunction - userData:', userData);
    const submitButton = $('#verifyIdentity button.submit');
    const verifyCodeInput = $('#verification-code');
    submitButton.off('click');
    submitButton.on('click', () => {
      Spinner.appendTo($('#verifyIdentity'));
      this.validateOktaCode(userData, verifyCodeInput.val());
    });
  }

  initStyleClasses() {
    const parsyContainer = $('.parsy-sm-14');
    const legalForm = $('.form-legal');

    parsyContainer.addClass('verify-container');
    parsyContainer.parent().addClass('flex');
    legalForm.appendTo(parsyContainer);
  }

  initLinenumber(userData) {
    let linenumber = 'xxxx';
    let userDataLinenumber = 'xxxx';
    if (userData && userData.phones.length > 0) {
      userDataLinenumber = userData.phones[0].phoneNumber;
    }
    linenumber = CookieHandler.getCookie(MFA_PHONE_NUMBER_NEW) ? CookieHandler.getCookie(MFA_PHONE_NUMBER_NEW) : userDataLinenumber;
    $('#verify-linenumber').text(`${linenumber.slice(-4)}:`);
  }

  initResendButton(userData) {
    const resendLink = $('#resend-code');
    resendLink.off('click');
    resendLink.on('click', e => {
      e.preventDefault();
      this.resendOktaVerificationCode(userData, userData['type']);
    });
  }

  initMobileMsgText(type) {
    const alternativeLink = $('#alternative-code');
    const alternativeLinkContainer = alternativeLink.parent();
    const viaText = 'sms';
    const viaCall = 'voice';
    const msgSmsText = $('#resend-code #mfa-get-text');
    const msgCallText = $('#resend-code #mfa-get-call');
    const alternativeTextLink = $('#alternative-code #alternative-text');
    const alternativeCallLink = $('#alternative-code #alternative-call');

    if (type === viaText){
      msgSmsText.show();
      msgCallText.hide();
      alternativeTextLink.hide();
      alternativeCallLink.show();
      alternativeLinkContainer.show();
    } else if (type === viaCall){
      msgSmsText.hide();
      msgCallText.show();
      alternativeCallLink.hide();
      alternativeTextLink.show();
      alternativeLinkContainer.show();
    }
  }

  initAlternativeResendButton(userData) {
    const alternativeLink = $('#alternative-code');
    const viaText = 'sms';
    const viaCall = 'voice';
    this.initMobileMsgText(userData['type']);
    alternativeLink.off('click');
    alternativeLink.on('click', e => {
      e.preventDefault();
      const alternativeMedium = userData['type'] === viaText ? viaCall : viaText;
      this.resendOktaVerificationCode(userData, alternativeMedium);
    });
  }

  resendOktaVerificationCode(userData, codeMedium) {
    this.initMobileMsgText(codeMedium);
    userData['type'] = codeMedium;

    if (userData && (this.flow === FTSI || this.flow === ENROLLMENT)) {
      const authenticatorId = `${codeMedium}${userData['authenticatorId'].substring(userData['authenticatorId'].indexOf('|'))}`;
      const data = {
        authenticatorId,
        challengeType: 'oob',
        mfaToken: userData['mfaToken'],
      };
      $.ajax({
        url: getServiceUrl('mfa'),
        contentType: 'application/json',
        data: JSON.stringify(data),
        type: 'POST',
        success: (res) => {},
        error: (res) =>  console.error(res),
        complete: (result) => {
          if (result && result.responseJSON && result.responseJSON.ErrorMessage){
            setTimeout(() => {
              $('.notification-banner').css('display', 'none');
              $('.notification-banner').remove();
            }, 200);
          }
        }
      });
    } else if (userData && this.flow === UPDATE_PROFILE_PHONE) {
      const newProfilePhoneInfo = {
        ...userData,
        type: codeMedium
      };
      setLocalStorage(UPDATE_PROFILE_PHONE, newProfilePhoneInfo);
      this.mfaAssociateServiceCall(newProfilePhoneInfo, newProfilePhoneInfo.type);
    }
  }
  mfaAssociateServiceCall(userData, codeMedium) {
    let mfaPhoneNumberNew =  CookieHandler.getCookie(MFA_PHONE_NUMBER_NEW);

    if (!mfaPhoneNumberNew && userData && userData.phones && userData.phones.length > 0) {
      mfaPhoneNumberNew = userData.phones[userData.phones.length - 1].phoneNumber || null;
    }

    if (!mfaPhoneNumberNew) {
      console.error('There is no record of phone number in local storage.');
      return;
    }

    try {
      const data = {
        authenticatorTypes: [
          'oob'
        ],
        oobChannels: [
          codeMedium
        ],
        phoneNumber: mfaPhoneNumberNew
      };
      // fetch mfa access token from local storage
      const mfaAccessToken = Login.fetchMfaAccessTokenFromLocalStorage();
      const originalAccessToken = Login.fetchAccessTokenFromLocalStorage();
      // replace the access token cookie with mfa access token
      CookieHandler.eraseCookie(ACCESS_TOKEN);
      CookieHandler.createCookie(ACCESS_TOKEN, mfaAccessToken, 1);
      const user = getSessionStorage(CID_COOKIE);
      const mfaHeaders = {
        Cookie: document.cookie,
      };

      $.ajax({
        type: 'POST',
        contentType: 'application/json',
        url: '/WHGServices/loyalty/V3/member/mfaAssociate',
        headers: mfaHeaders,
        data: JSON.stringify(data),
        success: (response) => {
          const updateProfilePhoneDataWithResponse = {
            ...userData,
            ...response,
            mfaToken: mfaAccessToken,
            memberIdentifier: user.accountNumber,
          };
          console.trace('mfaAssociateServiceCall - mfaAssociate api success - ', JSON.stringify(updateProfilePhoneDataWithResponse));
          console.trace(UPDATE_PROFILE_PHONE, JSON.stringify(updateProfilePhoneDataWithResponse));
          setLocalStorage(UPDATE_PROFILE_PHONE, updateProfilePhoneDataWithResponse);
          this.initSubmitFunction(updateProfilePhoneDataWithResponse);
        },
        error: (response) => {
          console.error('mfaAssociateServiceCall mfaAssociate error - ', response);
          console.error('mfaAssociateServiceCall - mfaAssociate api error - ', JSON.stringify(response));
        },
        complete: (result) => {
          
          if (result && result.responseJSON && result.responseJSON.ErrorMessage){
            setTimeout(() => {
              $('.notification-banner').css('display', 'none');
              $('.notification-banner').remove();
            }, 200);
          }
        }
      });
      // revert the access token cookie back to the original access token the moment after AJAX call
      // used timeout 50ms to avoid getting into a situation where ajax process after reverting back the token
      // moved away from complete function to avoid interaction with getProfile from user modal
      setTimeout( () => {
        CookieHandler.eraseCookie(ACCESS_TOKEN);
        CookieHandler.createCookie(ACCESS_TOKEN, originalAccessToken, 1);
      }, 50);
    } catch (error) {
      console.error('mfaAssociateServiceCall error - ', error);
    }
  }

  setValidationData(userData, bindingCode) {
    const updateProfileInfo = getLocalStorage(UPDATE_PROFILE_PHONE);
    const data = {
      oobCode: userData['oobCode'],
      bindingCode: bindingCode,
      mfaToken: userData['mfaToken']
    };

    if (updateProfileInfo) {
      const dataWithProfileInfo = {
        ...data,
        flow: 'updateMfa',
        phoneNumber: CookieHandler.getCookie(MFA_PHONE_NUMBER_NEW) ? CookieHandler.getCookie(MFA_PHONE_NUMBER_NEW) : userData.phones[0].phoneNumber,
        memberIdentifier: userData.memberIdentifier,
        oktaIdentifier: userData.oktaIdentifier,
        memberPhoneId: userData.memberPhoneId,
        typeCode: CookieHandler.getCookie(MFA_PHONE_NUMBER_NEW_TYPE) ? CookieHandler.getCookie(MFA_PHONE_NUMBER_NEW_TYPE) : userData.phones[0].typeCode
      };
      return dataWithProfileInfo;
    } else {
      return data;
    }
  }

  validateOktaCode(userData, code) {
    if (userData) {
      const data = this.setValidationData(userData, code);
      const originalAccessToken = Login.fetchAccessTokenFromLocalStorage();
      const mfaAccessToken = Login.fetchMfaAccessTokenFromLocalStorage();
      if (this.flow === UPDATE_PROFILE_PHONE) {
        CookieHandler.eraseCookie(ACCESS_TOKEN);
        CookieHandler.createCookie(ACCESS_TOKEN, mfaAccessToken, 1);
      }
      const urlParams = new URLSearchParams(window.location.search),
        isStatusMatch = urlParams.has('status-match');
          
      $.ajax({
        url: getServiceUrl('validateOtp'),
        contentType: 'application/json',
        data: JSON.stringify(data),
        type: 'POST',
        success: (res) => {
          Spinner.remove($('#verifyIdentity'));
          if (!CookieHandler.cidAuthenticated()) {
            CookieHandler.createCookie(WR_AUTHENTICATED, 'true', 1);
          }
          const id_token = CookieHandler.readCookie('id_token');
          if (id_token) {
            localStorage.setItem('id_token', id_token);
            CookieHandler.eraseCookie('id_token');
          }
          if (this.flow === ENROLLMENT || this.flow === FTSI) {
            let localMfaPagePage = '/' + _LOCALE_ + templateUrls.mfaPage;
            if (isStatusMatch) {
              localMfaPagePage += '?status-match';
            }
            OktaClient.createAuth0LocalStorageObject();
            window.location.assign(localMfaPagePage);
            try {
              Analytics.satelliteTracker('signInAuthenticated');
            } catch (error) {
              console.error('Event1 error - ', error);
            }  

          } else if (this.flow === UPDATE_PROFILE_PHONE) {
            localStorage.removeItem(UPDATE_PROFILE_PHONE);
            CookieHandler.eraseCookie(ACCESS_TOKEN);
            CookieHandler.createCookie(ACCESS_TOKEN, originalAccessToken, 1);
            Login.deleteMfaAccessTokenFromLocalStorage();
            const localProfilePage = '/' + _LOCALE_ + templateUrls.profilePage;
            window.location.assign(localProfilePage);
          }
        },
        error: (res) => {
          if (this.flow === UPDATE_PROFILE_PHONE) {
            CookieHandler.eraseCookie(ACCESS_TOKEN);
            CookieHandler.createCookie(ACCESS_TOKEN, originalAccessToken, 1);
          }

          const verifyCodeInput = $('#verification-code'),
            errorMessage = $('.error-msg-two'),
            submitButton = $('#verifyIdentity button.submit');

          verifyCodeInput.parent().addClass('form-input-error');
          errorMessage.removeClass('hidden');
          submitButton.prop('disabled', true);

          Spinner.remove($('#verifyIdentity'));
          if (res && res.responseJSON && res.responseJSON.InternalErrorMessage && res.responseJSON.InternalErrorMessage.includes('Too many failed codes')) {
            const localAccountLockedPage = '/' + _LOCALE_ + templateUrls.accountLockedPage;
            window.location.assign(localAccountLockedPage);
          }
        },
        complete: (result) => {
          Spinner.remove($('#verifyIdentity'));
    
          if (result && result.responseJSON && result.responseJSON.ErrorMessage){
            setTimeout(() => {
              $('.notification-banner').css('display', 'none');
              $('.notification-banner').remove();
            }, 200);
          }
          //DAI-36991 - remove blue error message banner
          $('.notification-banner').remove();
        }
      });
    }

  }

  getUserDataFromLocalStorage() {
    const enrollmentInfo = getLocalStorage(ENROLLMENT);
    const ftsiInfo = getLocalStorage(FTSI);
    const updateProfileInfo = getLocalStorage(UPDATE_PROFILE_PHONE);
    if (updateProfileInfo) {
      this.flow = UPDATE_PROFILE_PHONE;
      return updateProfileInfo;
    } else if (ftsiInfo && ftsiInfo.mfaToken) {
      this.flow = FTSI;
      return ftsiInfo;
    } else if (enrollmentInfo && enrollmentInfo.mfaToken) {
      this.flow = ENROLLMENT;
      return enrollmentInfo;
    }
    window.location.assign('/' + _LOCALE_ + '/wyndham-rewards');
  }
}

export default new VerifyYourIdentity();
