import { getUrlQuery } from '../../base/utils.js';

class CreditCardUrl {
  constructor() {
    this.creditCardUrlPrefix = '#creditcard?url=';
    this.handleCreditCardUrls();
  }
  getCreditCardParams() {
    let queryObj = getUrlQuery(window.location.href),
      params = $.param({
        campaignId: queryObj.campaignId || 'unknown',
        cellNumber: queryObj.cellNumber || 'unknown',
        referrerid: queryObj.referrerid || 'unknown'
      });
    return params;
  }
  getTargetUrl(url) {
    let targetUrl = '';
    targetUrl = url.replace(window.location.href, '');
    targetUrl = targetUrl.replace(this.creditCardUrlPrefix, '');
    targetUrl = decodeURIComponent(targetUrl);
    return targetUrl;
  }
  appendQueryToUrl(url, query) {
    let newUrl = '';
    if (url.indexOf('?') !== -1) {
      newUrl = url + '&' + query;
    } else {
      newUrl = url + '?' + query;
    }
    return newUrl;
  }
  handleCreditCardUrls() {
    // Update href of all existent <a> tags with '#creditcard?url='.
    $('a[href^="' + this.creditCardUrlPrefix + '"]').each((i, el) => {
      let oldUrl = $(el).attr('href'),
        newUrl = this.appendQueryToUrl(this.getTargetUrl(oldUrl), this.getCreditCardParams());
      $(el).attr('href', newUrl);
    });
  }
}

export default new CreditCardUrl();
