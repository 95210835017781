import { getLocalStorage } from '../../base/session-handler';
import { FTSI } from '../../base/vars';
class RewardsMemberNumber {
  constructor() {
    this.initMemberNumber();
  }

  initMemberNumber() {
    const memberNoElement = $('#rewards-member-number');
    const ftsiInfo = getLocalStorage(FTSI);
    let memberNo = '';
    if (ftsiInfo) {
      memberNo = ftsiInfo['memberIdentifier'] || '';
    }
    memberNoElement.text(memberNo);
  }
}
export default new RewardsMemberNumber();
