import Cookie from '../base/cookie.js';
import { EventHandler, mapTierCode } from '../../base/utils.js';
import ExpandableItem from './expandable-item.js';
import { isMobileWidth } from '../../base/dom-utils.js';
import Spinner from './spinner.js';
import { templateUrls } from '../../base/vars.js';

class SectionLinks {
  constructor() {
    this.selector = '.section-links';
    this.element = $(this.selector);

    if (this.element.length) {
      this.isTitaniumUser(
        () => this.pullTitaniumContent(),
        () => this.showRegularContent());
      this.onMenuItemClick();
      this.onResize();
    }
  }

  isTitaniumUser(sucessCbk, failCbk) {
    if (window.User && Cookie.isAuthenticated()) {
      const tier = window.User.currentTier.description || mapTierCode(window.User.currentTier.tierCode);
      let level = tier.toLowerCase();
      if (level === 'titanium') {
        sucessCbk();
      } else {
        failCbk();
      }
    } else {
      failCbk();
    }
  }

  pullTitaniumContent() {
    Spinner.appendTo(this.element, true, true);
    $.ajax({
      url: templateUrls.titanium,
      contentType: 'text',
      success: (r) => {
        let $response = $(r),
          $sectionLink = ($response.length) ? $response.find(this.selector) : null;
        if ($sectionLink.length) {
          // Sanitize the HTML content
          let cleanHTML = DOMPurify.sanitize($sectionLink.html());
          this.element.html(cleanHTML);
        }
      },
      complete: () => {
        this.handleDeepLink();
        Spinner.remove(this.element);
      }
    });
  }

  handleDeepLink(urlHash) {
    let hash = urlHash || document.location.hash.split('?')[0],
      hashArray = hash.split('#').filter((n) => n), // removes spaces from array
      sections = this.element.find('.section-holder').parent(),
      sectionFound = false,
      itemFound = false,
      selectedItemTxt;

    sections.addClass('hidden');
    sections.each((i, el) => {
      if ($(el).data('section') === hashArray[0]) {
        sectionFound = true;
        this.selectActiveMenuItem(hashArray[0]);
        $(el).removeClass('hidden');
        if (hashArray && hashArray[1]) {
          selectedItemTxt = this.element.find('.expandable-item[data-id=' + hashArray[1] + '] .primary-content');
          // Open section Item
          ExpandableItem.init(hashArray[1], true);
          // WR Analytics
          EventHandler.triggerEvent('section-link-item', {
            sectionName: hashArray[0],
            sectionItemDetail: (selectedItemTxt.length) ? selectedItemTxt.eq(0).text()
              .toLowerCase() : ''
          });

          // Item found flag
          itemFound = true;
        }
      }
    });

    if (!sectionFound) {
      sections.addClass('hidden');
      sections.eq(0).removeClass('hidden');
      ExpandableItem.init();
      this.selectActiveMenuItem();
    }

    if (!itemFound) {
      ExpandableItem.init();
      // Animate.scrollToElement(sections.eq(0));
    }
  }

  selectActiveMenuItem(id) {
    let links = this.element.find('.quick-links-item a'),
      mobileText = this.element.find('.quick-links-mobile-toggle-container a'),
      selectedItemId,
      selectedItem;
    links.parent().removeClass('quick-links-active-page');
    if (id) {
      selectedItem = links.filter((i, el) => {
        return ($(el).attr('href') === `#${id}`);
      });
      selectedItem.parent().addClass('quick-links-active-page');
    } else {
      selectedItem = links.eq(0);
      selectedItem.parent().addClass('quick-links-active-page');
    }
    mobileText.html(selectedItem.text());
    // Analytics
    selectedItemId = selectedItem.attr('href').replace('#', '');
    EventHandler.triggerEvent('section-link', {
      sectionName: selectedItemId
    });
  }

  showRegularContent() {
    Spinner.appendTo(this.element, true, true);
    this.handleDeepLink();
    Spinner.remove(this.element);
    this.onUserUpdate();
  }

  onUserUpdate() {
    this.offUserUpdate();
    $(document).on('user:profile:updated.sectionLinks', () => {
      this.isTitaniumUser(
        () => this.pullTitaniumContent(),
        () => this.showRegularContent());
    });
  }

  offUserUpdate() {
    $(document).off('user:profile:updated.sectionLinks');
  }

  onMenuItemClick() {
    $(document).on('click', '.quick-links-item a', (e) => {
      let quickLinks = this.element.find('.quick-links-menu');

      quickLinks.find('.quick-links-toggle-arrow').removeClass('arrow-rotate');
      quickLinks.find('#quick-links-menu').hide();
      this.handleDeepLink($(e.currentTarget).attr('href'));

      e.preventDefault();
    });
  }
  onResize() {
    $(window).on('resize.sectionLinks', () => {
      if (isMobileWidth()) {
        let quickLinks = this.element.find('.quick-links-menu');
        quickLinks.find('.quick-links-toggle-arrow').removeClass('arrow-rotate');
        quickLinks.find('#quick-links-menu').hide();
      }
    });
  }
}

export default new SectionLinks();
