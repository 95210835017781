import CustomValidation from '../base/custom-validation.js';
import Form from '../base/form.js';
import HeaderMobile from './header-mobile.js';
import Header from './header.js';
import SSO from './sso.js';
import Modal from './modal.js';
import {
  checkVulnerableUrl,
  getParameterByName,
  EventHandler
} from '../../base/utils.js';
import Redirect from '../base/redirect.js';
import { isMobileWidth } from '../../base/dom-utils.js';
import SecuritySetup from './security-setup.js';
import SecurityVerify from './security-verify.js';
import Spinner from './spinner.js';
import UserHandler from '../base/user.js';
import { requestUrls, templateUrls } from '../../base/vars.js';
import Analytics from '../../base/wyn-analytics-module.js';
import SSODelegate from './sso-delegate.js';

class ResetPassword {
  constructor() {
    this.$form = null;
    this.templateUrl = templateUrls.resetPassword || null;
    this.requestUrl = requestUrls.onlineAccountSetup || null;
    this.redirectUrl = templateUrls.redirect || null;
    this.modal = null;
    this.resetPwToken = null;
    this.username = null;
  }
  openResetPassword(modal, redirect, options) {
    this.modal = modal;
    if (redirect) {
      this.redirectUrl = redirect;
    }
    if (options) {
      this.resetPwToken = options.resetPwToken ? options.resetPwToken : null;
      this.username = options.username ? options.username : null;
    }
    this.getTemplate();
  }
  getTemplate() {
    $.ajax({
      url: this.templateUrl,
      success: (r) => {
        EventHandler.triggerEvent('change-password', {
          pageName: 'change-password'
        });
        this.renderTemplate(r);
      }
    });
  }
  renderTemplate(html) {
    Spinner.remove($('.sign-in-form'));
    Form.enableFormField($('.sign-in-form').find('.submit'));
    if (this.modal) {
      Modal.populateModalContent(html);
      Modal.show();
    } else {
      $('.centered-container-content .center').html(html);
    }
    this.$form = $('#resetPassword');
    this.$form.find('#userName').text(this.username);
    this.initFormValidation();
  }
  initFormValidation() {
    let $passwordError = $($('#password').parent()
        .find('.help-block')[0]),
      passwordErrorMessage = $passwordError.text(),
      $confirmPasswordError = $($('#confirmPassword').parent()
        .find('.help-block')[0]),
      confirmPasswordErrorMessage = $confirmPasswordError.text();

    $passwordError.remove();
    $confirmPasswordError.remove();

    this.$form
      .on('init.form.fv', (e, data) => {
        Form.removeFormError($(e.target));
        Form.disableFormField($(e.target).find('.submit'));
      })
      .formValidation({
        button: {
          selector: '.submit',
          disabled: ''
        },
        live: 'disabled',
        fields: {
          password: {
            validators: {
              notEmpty: {
                message: passwordErrorMessage
              },
              callback: {
                callback: this.passwordValidation,
                message: passwordErrorMessage
              }
            }
          },
          confirmPassword: {
            validators: {
              notEmpty: {
                message: confirmPasswordErrorMessage
              },
              identical: {
                field: 'password',
                message: confirmPasswordErrorMessage
              }
            }
          }
        }
      })
      .on('keyup', '#password', (e) => {
        this.passwordValidation(null, null, $(e.currentTarget));
      })
      .on('success.form.fv', (e, data) => {
        e.preventDefault();
        this.post();
      })
      .on('success.field.fv', (e, data) => {
        if (!data.fv.isValid()) {
          Form.disableFormField($(e.currentTarget).find('.submit'));
        }
      })
      .on('err.field.fv', () => Form.handleMultipleErrorLabels());
  }
  passwordValidation(e, validator, $field) {
    return CustomValidation.passwordValidation($field);
  }
  post() {
    Spinner.appendTo(this.$form);
    Form.disableFormField(this.$form.find('.submit'));
    $.ajax({
      url: this.requestUrl,
      type: 'POST',
      contentType: 'application/json',
      dataType: 'json',
      data: this.getPostData(),
      context: {
        onFail: {
          formElement: this.$form
        }
      },
      success: (r) => {
        if (r['TwoFormActionCode'] === 'verify') {
          SecurityVerify.openSecurityVerify(this.modal, this.redirectUrl);
        } else if (r['TwoFormActionCode'] === 'setup') {
          SecuritySetup.openSecurityQuestions(this.modal, this.redirectUrl);
        } else if (r['TwoFormActionCode'] === '') {
          let url = this.redirectUrl || templateUrls.redirect;

          UserHandler.getProfile((userProfile) => {
            EventHandler.triggerEvent('signin-complete', Analytics.getUserData(userProfile));
            if (getParameterByName('catalogOfOrigin') || getParameterByName('destinationLocation') || getParameterByName('ssoType') === '2') {
              SSO.handleOutbound(window.location.href, false, true);
            } else if(getParameterByName('ssoType') === '1') {
              SSODelegate.getPoints(window.location.href, false, true);
            } else if (checkVulnerableUrl(getParameterByName('redirect'))) {
              Redirect.deeplinkRedirect();
            } else if (url) {
              window.location.assign(url);
            } else {
              if (isMobileWidth()) {
                HeaderMobile.closeDropdowns();
              }
              Spinner.remove(this.$form);
              Form.enableFormField(this.$form.find('.submit'));
              Header.closeDropdowns();
              Modal.close();
            }
          });
        }
      }
    });
  }
  getPostData() {
    return JSON.stringify({
      resetPwToken: this.resetPwToken,
      newPassword: this.$form.find('#password').val(),
      confirmNewPassword: this.$form.find('#confirmPassword').val()
    });
  }
}

export default ResetPassword;
