import { isMemberAttr } from '../../base/utils.js';

class MemberAttribute {
  constructor() {
    $(document).on('user:profile:updated', () => {
      let $memberAttributes = $('.member-attribute[data-condition]');
      this.updateMemberAttributes($memberAttributes, 0);
    });
  }

  updateMemberAttributes($memberAttributes, _node) {
    let $memberAttribute,
      $memberLabel = $('.member-label'),
      memberAttributes = [],
      isFoundedMemberAttr = false;

    $memberAttribute = $memberAttributes.filter(`[data-condition="${$memberAttributes.eq(_node).data('condition')}"]`);

    if($memberAttribute.length == 0){
      memberAttributes = ['none'];
    }

    $memberAttribute.each((_index, elem) => {
      let condition = elem.dataset.condition.split(':'),
        prop = condition[0],
        query = condition[1] ? condition[1] : '';

      if (window.User) {
        if (isMemberAttr(window.User, prop, query)) {
          if (elem.previousElementSibling) {
            elem.previousElementSibling.classList.remove('last-visible-child');
          }
          elem.classList.remove('hidden');
          elem.classList.add('last-visible-child');
          memberAttributes.push($(elem).find('span.member-attribute-label').text().toLowerCase().trim().replace(/\r?\n|\r/g, ''));
          memberAttributes = this.uniqueArrayElem(memberAttributes);

          $memberLabel.each((_i, el) => {
            let memberLabelAttribute = $(el).data('military-attribute-label');

            if($(el)[0].hasAttribute('data-military-attribute-label') && memberLabelAttribute !== '') {
              $(el).text(memberLabelAttribute);
            }
          });

          isFoundedMemberAttr = true;
        } else {
          elem.classList.add('hidden');
        }
      }
    });

    if ($memberAttributes.eq(_node + 1).length > 0 && !isFoundedMemberAttr) {
      this.updateMemberAttributes($memberAttributes, _node + 1);
    } else if (!isFoundedMemberAttr || $('.member-benefits-container .member-attribute:not(.hidden)').length === 0){
      $('.member-benefits-container p[data-default]').removeClass('hidden');
    }

    window.digitalData.user.userInfo.attribute =  memberAttributes.filter(Boolean).join(',');
  }

  uniqueArrayElem(arr) {
    return Array.from(new Set(arr));
  }
}

export default new MemberAttribute();
