import Cookie from '../base/cookie.js';
import { getParameterByName, EventHandler } from '../../base/utils.js';
import Spinner from './spinner.js';
import { _LOCALE_, _OKTA_LOCALE_, requestUrls, _defaultChannelId } from '../../base/vars.js';
import { UserHandler } from '../../base/session-handler.js';
import ConfigsUtils from '../../base/aem-configs/config-utils.js';

class SSO {
  constructor() {
    this.linkClicked = null;
    this.onOutboundClick();
    this.checkMaritzUrl();
  }
  onOutboundClick() {
    $(document).on('click', 'a[href*="#tally"]', (e) => {
      e.preventDefault();
      let href = $(e.currentTarget).attr('href');
      if (href.includes('redirect=')) {
        const apiEndpoint = href.split('redirect=') ? href.split('redirect=')[1] : '';
        const target = e.currentTarget.target || '_blank';
        if (apiEndpoint) {
          this.postRequestSSO(apiEndpoint, target, null);
        } else {
          console.error('Blank redirect url for Partner SSO');
        }
      } else {
        const button = $(e.currentTarget).closest('.card-content').find('a.card-button');
        if (button.length > 0 && button.attr('href')) {
          if(button.data('type') === 'tallyurl') {
            button.click();
          } else {
            const href = this.getPointsLanguageUrl(button.attr('href'));
            const target = button.attr('target') || '_blank';
            window.open(href, target);
          }
        } else {
          console.error('No other button to trigger Partner SSO');
        }
      }
    });

    $(document).on('click', 'a[data-type="tallyurl"]', (e) => {
      e.preventDefault();
      const apiEndpoint = this.getPointsLanguageUrl($(e.currentTarget).attr('href'));
      const params = $(e.currentTarget).attr('data-tally-params');
      const target = e.currentTarget.target || '_blank';
      this.postRequestSSO(apiEndpoint, target, params);
    });

    $(document).on('click', 'a[href*="#maritz"], a[href*="#sso"]', (e) => {
      let href = $(e.currentTarget).attr('href'),
        preHash = href.split('#') ? href.split('#')[1] : '',
        hash = preHash.split('?') ? preHash.split('?')[0] : preHash,
        target = e.currentTarget.target;
      if(!(hash === 'sso' || hash === 'maritz') || (href.match('#sso') && getParameterByName('ssoType', href) !== '2')) {
        // Link is for another SSO type
        return;
      }

      e.preventDefault();
      let redirect = getParameterByName('anonymousURL', href);
      let extension = getParameterByName('extension', href);
      let openInNewTab = target === '_blank';

      if (hash === 'sso') {
        this.postSalesForceToken(openInNewTab, extension);
        e.preventDefault();
        return;
      }

      if (Cookie.isAuthenticated()) {
        this.linkClicked = e.currentTarget;
        this.handleOutbound(href, undefined, undefined, openInNewTab);
      } else if(redirect && redirect.replace(/\s/g,'').length > 0) {
        window.location.assign(redirect);
      } else {
        EventHandler.send('open-login-modal');
        if(this.linkClicked !== e.currentTarget) {
          this.linkClicked = e.currentTarget;
          $(document).one('user:profile:updated', () => this.handleOutbound(href, undefined, undefined, openInNewTab));
        }
      }
    });
  }
  postRequestSSO(apiEndpoint, target, params) {
    const idToken = UserHandler.getIdToken();
    const data = {
      id_token: idToken,
    };
    if (params) {
      const paramsList = params.split('|');
      paramsList.forEach((item) => {
        const [key, value] = item.split('=');
        data[key] = value;
      });
    }
    let form = document.createElement('form');
    form.method = 'POST';
    form.action = apiEndpoint;
    form.target = target;
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = data[key];
        form.appendChild(input);
      }
    }
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }
  checkMaritzUrl() {
    if ((getParameterByName('catalogOfOrigin') || getParameterByName('destinationLocation')) && Cookie.isAuthenticated()) {
      this.handleOutbound(window.location.href, true);
    }
  }
  updateDestinationKey(destinationKey, catalogOfOrigin, selectedCountry) {
    if (!destinationKey) {
      if (catalogOfOrigin && catalogOfOrigin === 'WYNAUCTION') {
        destinationKey = 'WYNAUCTION';
      } else {
        if (selectedCountry === 'US') {
          destinationKey = 'AHQ';
        } else {
          destinationKey = 'GLOBALCATALOG';
        }
      }
    }
    return destinationKey;
  }
  addInput(form, key, value, actualDocument) {
    let inputContainer = actualDocument.createElement('div');

    let input = actualDocument.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = value;

    let sanitizedInputHtml = DOMPurify.sanitize(input.outerHTML);
    inputContainer.innerHTML = sanitizedInputHtml;

    form.appendChild(inputContainer);
  }
  handleOutbound(url, cancelRedirect, fromThirdParty, openInNewTab = false) {
    let destinationKey = getParameterByName('destinationKey', url),
      destinationLocation = getParameterByName('destinationLocation', url),
      catalogOfOrigin = getParameterByName('catalogOfOrigin', url),
      clientId = getParameterByName('clientId', url) ||
        getParameterByName('clientID', url) || getParameterByName('clientid', url);

    if($('.main-login-form').find('.spinner').length == 0){
      Spinner.appendTo($('body'), true, true);
    }

    // opened the new tab right away to avoid pop-up blocker by the browser
    let newWindow, actualDocument;
    if (openInNewTab) {
      newWindow = window.open();
      actualDocument = newWindow.document;
    } else {
      actualDocument = window.document;
    }

    $.ajax({
      type: 'GET',
      url: requestUrls.ssoToken,
      data: {
        'clientID': clientId || 'MTZ'
      },
      contentType: 'application/json',
      context: {
        onFail: {
          removeSpinnerFrom: $('body'),
          redirect: !cancelRedirect ? window.location.href : null
        }
      }
    }).done((r) => {
      if(fromThirdParty || url === $(this.linkClicked).attr('href')) {
        let formContainer = actualDocument.createElement('div');

        var returnURL = r.ReturnURL;
        if ($('.enroll-success .actual-buttonPath').length > 0) {
          returnURL = $('.enroll-success .actual-buttonPath').data('returnurl');
        }
        let form = actualDocument.createElement('form');
        form.action = returnURL;
        form.method = 'post';
        form.classList.add('not-ajax');

        // Update destination key with business logic
        destinationKey = this.updateDestinationKey(destinationKey, catalogOfOrigin, r.data.selectedCountry);

        // Add hidden inputs to form
        this.addInput(form, 'userToken', r.data.userToken, actualDocument);
        this.addInput(form, 'PartnerIdentifier', r.data.PartnerIdentifier, actualDocument);
        this.addInput(form, 'ClientIdentifier', r.data.ClientIdentifier, actualDocument);
        this.addInput(form, 'clientProjectKey', r.data.clientProjectKey, actualDocument);
        this.addInput(form, 'selectedCountry', r.data.selectedCountry, actualDocument);
        this.addInput(form, 'destinationKey', destinationKey, actualDocument);

        // Check for optional params
        if (destinationLocation && destinationKey !== 'GLOBALCATALOG') {
          this.addInput(form, 'destinationLocation', destinationLocation, actualDocument);
        }

        if (catalogOfOrigin) {
          this.addInput(form, 'catalogOfOrigin', catalogOfOrigin, actualDocument);
        }

        let sanitizedFormHtml = DOMPurify.sanitize(form.outerHTML);

        // Set sanitized form HTML
        formContainer.innerHTML = sanitizedFormHtml;

        actualDocument.body.appendChild(formContainer);

        // Remove the Spinner as the form might be submitted in a new tab. Also, removed it before the form submit, so that it get
        // removed even in case of pop-up blocker alert by the browser.
        Spinner.remove();

        // Submit
        formContainer.firstChild.submit();

        // Remove the appended Form as the form might be submitted in a new tab.
        actualDocument.body.removeChild(formContainer);
      }
    })
      .fail(() => {
      // if the request fails, close the window and focus on the previous tab
        if (newWindow) {
          newWindow.close();
        }
      });
  }
  postSalesForceToken(openInNewTab, extension) {
    console.trace('sso-postSalesForceToken called');
    const ssoTokenDomainUrl = this.getSfSsoValue('ssoTokenDomainUrl');
    console.log('ssoTokenDomainUrl: ' + ssoTokenDomainUrl);
    const grantType = this.getSfSsoValue('ssoTokenGrantType');
    const clientId = this.getSfSsoValue('ssoTokenClientId');
    const clientSecret = this.getSfSsoValue('ssoTokenClientSecret');
    const sfData = {
      'grant_type': grantType,
      'client_id': clientId,
      'client_secret': clientSecret
    };   
    console.log({'ssoTokenDomainUrl': ssoTokenDomainUrl, 'sfData': sfData} );
    let newWindow;
    if (openInNewTab) {
      newWindow = window.open('', '_blank');
    } else {
      newWindow = window;
    }
    $.ajax({
      url: ssoTokenDomainUrl,
      type: 'POST',
      data: sfData,
      success: (res) => {
        console.log('sso-postSalesForceToken >>>  success got sf access token', res);
        this.postSalesForceOktaToken(extension, res.access_token, newWindow);
      },
      error: (error) => {
        console.error('sso-postSalesForceToken >>> error in getting salesforce access', error);
        if (newWindow) {
          newWindow.close();
        }
      },
      complete: () => {
        console.log('sso-postSalesForceToken()>>> complete:::' + ssoTokenDomainUrl);
      }
    });
    console.trace('sso-postSalesForceToken Ended');
  }
  postSalesForceOktaToken(extension, sftoken, newWindow) {
    console.log('sso-postSalesForceOktaToken is called');
    const postOktaToken = encodeURI(this.getSfSsoValue('ssoPostTokenURI'));
    const oktaAccessToken = UserHandler.getOktaAccessToken();
    const sfLocale = this.getSFLocale();
    const data = {
      'OktaAccessToken': oktaAccessToken,
      'DestinationLocation': '',
      'Language': sfLocale,
      'Channel': 'responsive'
    };
    console.trace({postOktaToken, data});
    $.ajax({
      url: postOktaToken,
      data: JSON.stringify(data),
      type: 'POST',
      contentType: 'application/json',
      headers: {
        Authorization: `Bearer ${sftoken}`
      },
      success: (res) => {
        console.log('sso-postSalesForceOktaToken >>> success got sf okta access token', res);
        console.log(res);
        const parsedResponse = JSON.parse(res);
        console.log(parsedResponse);
        let salesforceHelpRedirectUrl = encodeURI(this.getSfSsoValue('ssoHelpRedirectUrl'));
        if (extension) {
          salesforceHelpRedirectUrl += extension;
        }
        console.log('sforceHelpRedirectUrl=====', salesforceHelpRedirectUrl);
        if (parsedResponse.code) {
          const helpUrl = `${salesforceHelpRedirectUrl}?language=${sfLocale}&destinationLocation=&otc=${parsedResponse.code}`; 
          console.log('sso-postSalesForceOktaToken >>> helpUrl:::', helpUrl);
          newWindow.location.href = helpUrl;
        }
      },
      error: (error) => {
        console.error('sso-postSalesForceOktaToken >>> error in getting sf okta access',error);
        console.error(error);
        if (newWindow) {
          newWindow.close();
        }
      },
      complete: () => {
        console.log('sso-postSalesForceOktaToken()>>> complete:::' + postOktaToken);
      }
    });
  }

  WyndhamLangToPointsSSOLang(languageCode) {
    // Available languages in Points.com
    const languageMap = {
      'en-us': 'en-US',
      'en-ap': 'en-AU',
      'en-gb': 'en-GB',
      'es-xl': 'es-ES',
      'pt-br': 'pt-BR',
      'zh-cn': 'zh-CN',
      'de-de': 'de-DE'
    };
    return languageMap[languageCode];
  }

  getPointsLanguageUrl(href) {
    let newLink = href;
    const ssoLang = this.WyndhamLangToPointsSSOLang(_LOCALE_);
    if (newLink && ssoLang) {
      const language = getParameterByName('language', href);
      // currently Points.com only have language for product Buy and Gift
      const conditions = ['ssoProduct=buy', 'ssoProduct=gift'];
      const hasCond = conditions.some(cond => href.includes(cond));
      if (hasCond && !language) {
        newLink += '&language=' + ssoLang;
      }
    }
    return newLink;
  }

  getSFLocale() {
    if (_OKTA_LOCALE_ === 'en') {
      // get original with split
      const splitLocale = _LOCALE_.split('-');
      if (splitLocale[1]) {
        return `${splitLocale[0]}_${splitLocale[1].toUpperCase()}`;
      }
      // default en value
      return 'en_US';
    }
    return _OKTA_LOCALE_.replace('-', '_');
  }

  getSalesforceHelpForceTokenDomainUrl() {
    return ConfigsUtils.getConfigs('oktaSignInConfigInfo', _defaultChannelId, [_LOCALE_], ConfigsUtils.Any).reduce(
      (result, item) => {
        return item.name === 'salesforce-help-force-token-domain-url' && item.value ? item.value : result;
      },
      null
    );
  }

  getSalesforceHelpRedirectUrl() {
    return ConfigsUtils.getConfigs('oktaSignInConfigInfo', _defaultChannelId, [_LOCALE_], ConfigsUtils.Any).reduce(
      (result, item) => {
        return item.name === 'salesforce-help-redirect-url' && item.value ? item.value : result;
      },
      null
    );
  }

  getSfSsoValue(key) {
    const salesforceConfigServiceSso = document.querySelector('#salesforceConfigServiceSso');
    var sfSsoValue = salesforceConfigServiceSso.getAttribute(key);
    return sfSsoValue ? sfSsoValue.replace(/\\\/|\/\\/g, '/').replaceAll("\\u002D", "-") : '';
  }
}

export default new SSO();
