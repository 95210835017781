import Form from '../base/form.js';
import Spinner from './spinner.js';
import {
  formatNumber,
  formatDateForPrinting,
  getCurrencyMapping,
  getDateFromDateString,
  getHotelLocaleUrlToken,
  getToday,
  toMoney,
  EventHandler
} from '../../base/utils.js';
import { activityToolTipsMap, requestUrls } from '../../base/vars.js';

class FullActivityComponent {
  constructor() {
    this.name = 'full-activity-component';

    this.$component = $('.full-activity-component');
    this.$filterForm = null;
    this.tableRowTemplate = null;
    this.mobileTableRowTemplate = null;
    this.allActivity = null;
    this.toolTipsMap = null;
    this.showMiles = true;

    if (this.$component.length > 0) {
      this.init();
    }
  }

  init() {
    Spinner.appendTo(this.$component);

    // activityToolTipsMap is a global value set inside a script tag by AEM (see tooltipscript.html)
    this.toolTipsMap = activityToolTipsMap;

    this.$filterForm = this.$component.find('form.full-activity-filters');

    this.tableRowTemplate = this.initTemplate(this.$component.find('#fullActivityRowTemplate'));
    this.mobileTableRowTemplate = this.initTemplate(this.$component.find('#mobileFullActivityRowTemplate'));

    let fetchProfile = new $.Deferred();
    $(document).on('user:profile:updated', fetchProfile.resolve);

    $.when(fetchProfile, this.fetchActivityData())
      .done(() => this.pageReady())
      .always(() => {
        Spinner.remove(this.$component);
      });
  }

  initTemplate($templateEl) {
    let templateString = $templateEl.text();
    $templateEl.remove();
    return _.template(templateString);
  }

  fetchActivityData() {
    return $.get({
      url: requestUrls.activity,
      data: {
        memberIdentifier: window.User.Channel.WR.MembershipID
      }
    }).then((res) => this.parseData(res));
  }

  parseData(response) {
    console.log('** activities:', response);
    this.allActivity = response.data || [];
    _.each(this.allActivity, (activity) => {
 
      let activityDescription = activity.transactionGroupDescription || '';
      if (activity && activity.stay && activity.stay[0] && activity.stay[0].ace03Description) {
        activityDescription = activity.stay[0].ace03Description;
      }
  
      activity.description = activityDescription;
      activity.date = getDateFromDateString(activity.transactionGroupDateTime);
      activity.children = activity.transactions;
      if (activity && activity.children) {
        for (var i = 0; i < activity.children.length; i++) {
          if (activity && activity.children && activity.children[i] && activity.children[i].tooltipKey) {
            let toolTipKey = activity.children[i].tooltipKey;
            if (activity.children[i].tooltipKey) {
              activity.children[i].toolTip = this.toolTipsMap[toolTipKey];
            }
          }
          if (activity && activity.children && activity.children[i] && activity.children[i].typeDescription) {
            if (activity.children[i].typeDescription === 'Stay') {
              activity.children[i].typeDescriptionStay = activity.children[i].typeDescription;
            }
            if (activity.children[i].typeDescription === 'Promotion') {
              activity.children[i].typeDescription = activity.children[i].description;
            }
          }
        } 
      }

      activity.points = formatNumber(activity.transactionGroupEarn[0].amount || 0);
      activity.miles = formatNumber(activity.transactionGroupEarn[0].amount || 0);
      activity.nights = 0;
      activity.quantity = 0;

      if (activity && activity.transactions && activity.transactions[0] && activity.transactions[0].spend && activity.transactions[0].spend.quantity) {
        activity.quantity = activity.transactions[0].spend.quantity;
      }
      if (activity.stay) {
        if (activity.stay[0] && activity.stay[0].eligibleNights) {
          activity.nights = activity.stay[0].eligibleNights;
        }
        
        if (activity.stay[0].checkInDate) {
          activity.checkInDate = getDateFromDateString(activity.stay[0].checkInDate);
        }

        if (activity.stay[0].checkOutDate) {
          activity.checkOutDate = getDateFromDateString(activity.stay[0].checkOutDate);
        }

        activity.stay.currencySymbol = (activity.stay[0].isoCurrencyCode ? getCurrencyMapping(activity.stay[0].isoCurrencyCode) : '');
        activity.stay.roomRate = toMoney(activity.stay[0].totalRevenueAmount);
        activity.stay.isoCurrencyCode = activity.stay[0].isoCurrencyCode;
      }

      if (activity.typeDescription) {
        activity.type = activity.typeDescription;
      } 
      /* if (activity.redemption && activity.redemption[0].eligibleNights) {
        activity.nights = activity.redemption[0].eligibleNights;
      } */
    });
    return this.allActivity;
  }

  pageReady() {
    if (window.User.aliases && window.User.aliases.filter(alias => alias.typeCode === 'PartnerPreferred').length > 0) {
      this.$component.removeClass('pref-miles');
      this.$component.find('th.miles').remove();
      this.showMiles = false;
    }
    this.updateActivityResults(true);
    this.$filterForm.on('change', () => this.updateActivityResults());
  }
  updateActivityResults(preventAnalytics) {
    let filterValues = Form.serialize(this.$filterForm, true);
    let filteredActivies = this.filterActivities(filterValues);
    this.renderActivityData(filteredActivies, this.showMiles);

    let $period = (filterValues.period !== '') ? this.$component.find('select[name=period] option[value=' + filterValues.period + ']') : [],
      periodTxt = ($period.length && $period.eq(0).text() !== '') ? $period.eq(0).text()
        .toLowerCase() : 'all',
      typeTxt = (filterValues.activityType !== '') ? filterValues.activityType : 'all';
    if (!preventAnalytics) {
      EventHandler.triggerEvent('my-activity-filter', {
        filter: 'activity:' + typeTxt + '|date:' + periodTxt
      });
    }
  }
  renderActivityData(activityData, showMiles) {
    let $noActivity = this.$component.find('.no-activity');
    let $tables = this.$component.find('.activity-tables');

    if (activityData.length) {
      let data = {
        allActivity: activityData,
        showMiles: false,
        localePath: getHotelLocaleUrlToken(),
        formatDateForPrinting: formatDateForPrinting
      };

      this.$component.find('.mobile-table-body').html(this.mobileTableRowTemplate(data));
      this.$component.find('tbody').html(this.tableRowTemplate(data));

      this.$component.find('table tr:not(.details-row), .mobile-table-row-container').click((e) => {
        let desc = $(e.currentTarget).find('td.description');
        if((desc && desc.length > 0 && desc.find('a')) || ($(e.currentTarget).find('a.details-control') && $(e.currentTarget).find('a.details-control').length > 0)) {
          e.preventDefault();
          let $btn = desc.find('a').length > 0 ? desc.find('a') : $(e.currentTarget).find('a.details-control');
          let expanded = $btn.attr('aria-expanded');
          // update both mobile and desktop control
          this.$component.find($btn.data('control'))
            .attr('aria-expanded', expanded === 'true' ? 'false' : 'true');
          this.$component.find($btn.data('row'))
            .toggleClass('hidden');
        }
      }); 

      this.$component.find('.details-row .close').click((e) => {
        let $close = $(e.currentTarget);
        let $detailsRow = this.$component.find($close.data('row'));
        $detailsRow.addClass('hidden');

        let $expandBtns = this.$component.find($close.data('control'));
        $expandBtns.attr('aria-expanded', 'false');
      });

      $tables.removeClass('hidden');
      $noActivity.addClass('hidden');

    } else {
      $tables.addClass('hidden');
      $noActivity.removeClass('hidden');
    }
  }

  filterActivities(filterValues) {
    return _.filter(this.allActivity, (activity) => {
      let match = true;
      if (filterValues.activityType !== 'all') {
        match = activity.typeDescription.toLowerCase() === filterValues.activityType.toLowerCase();
      }

      if (filterValues.period !== 'all') {
        let periodStartDate = this.getPeriodStartDate(filterValues.period);
        match = match && (activity.date >= periodStartDate);
      }

      return match;
    });
  }

  getPeriodStartDate(datePeriodStr) {
    let periodStart = getToday();
    let dateUnit = datePeriodStr.charAt(0);
    let offsetNum = parseInt(datePeriodStr.substring(1), 10);

    switch (dateUnit) {
    case 'd': // days
      periodStart.setDate(periodStart.getDate() - offsetNum);
      break;
    case 'm': // months
      periodStart.setMonth(periodStart.getMonth() - offsetNum);
      break;
    }

    return periodStart;
  }
}

export default new FullActivityComponent();
