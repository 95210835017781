import { UPDATE_PROFILE_PHONE, CID_COOKIE, MFA_PHONE_NUMBER_NEW_TYPE } from '../../base/vars';
import { setLocalStorage, getSessionStorage } from '../../base/session-handler.js';
import { UserHandler as SessionUserHandler } from '../../base/session-handler.js';
import Login from '../../components/login';
import PhoneInput from './phone-input';
import { CookieHandler } from '../../base/utils.js';

class MemberInformation {
  constructor() {
    if (window.location.href.includes('wyndham-rewards/my-account/profile')) {
      this.hrLineSpacing();
      this.initializeVerificationButtons();
      if($('#panel-1 .locked-phone-input #phoneNumber0').is(':visible')) {
        this.initializePhoneFieldEditButton();
        this.initViewPassword();
        this.makePhoneFieldReadOnly(true);
        this.updateVerificationOptions();
      }
    }
  }

  hrLineSpacing() {
    let formContainers = document.querySelectorAll('#panel-1 .form-content-width');

    for (let formContainer = 1; formContainer < formContainers.length; formContainer++) {
      formContainers[formContainer].style.paddingTop = 0;
      formContainers[formContainer].style.paddingBottom = 0;
    }
  }

  initializeVerificationButtons() {
    let accountVerificationOptions = document.querySelector('#panel-1 .verification-options');
    let accountVerificationButtons = document.querySelector('#panel-1 .verification-options .verification-buttons');
    let accountVerificatioRadioButtons = document.querySelector('#panel-1 .verification-options .radio-buttons');
    let sendCodeButton = document.querySelector('#panel-1 .verification-options .btn-primary');
    let cancelVerifcationButton = document.querySelector('#panel-1 .verification-options .btn-secondary');

    this.verificationRadioButtonSpacing(accountVerificatioRadioButtons);
    this.hideVerificationButtons(sendCodeButton, cancelVerifcationButton, accountVerificationButtons, accountVerificationOptions);
  }

  makePhoneFieldReadOnly(option) {
    const phoneField = document.querySelectorAll('#panel-1 #phoneNumber0')[0];
    if (phoneField) {
      phoneField.readOnly = option;
    }
  }

  hideVerificationButtons(sendButton, cancelButton, verificationButtons, verificationOptions) {
    [sendButton, cancelButton].forEach((verificationButton) => {
      if (verificationButton) {
        verificationButton.addEventListener('click', e => {
          e.preventDefault();
          if (verificationButton.classList.contains('btn-primary')) {
            $('#profileUpdate').data('formValidation')
              .validate('#phoneNumber0');

            const newPhoneNumber = document.querySelectorAll('#profileUpdate #phoneNumber0')[0].value;
            const isValidPhoneNumber = $('#profileUpdate').data('formValidation')
              .isValidField('#phoneNumber0');
            const verificationType = document.querySelector('[name=verificationChoice]:checked').value == 'Text' ? 'sms' : 'voice';
            const user = getSessionStorage(CID_COOKIE);
            if (isValidPhoneNumber) {
              const updatedPhonNumber = PhoneInput.getPhoneNumberWithCountryCode(newPhoneNumber, 0);
              const updateProfilePhoneData = {
                phones: [{
                  phoneNumber: updatedPhonNumber,
                  typeCode: this.getSelectedPhoneTypeValue(),
                }],
                memberIdentifier: window.User.accountNumber,
                oktaIdentifier: SessionUserHandler.getOktaUser().sub,
                memberPhoneId: user.phones[0].memberPhoneId,
                type: verificationType
              };
              CookieHandler.createCookie(MFA_PHONE_NUMBER_NEW_TYPE, this.getSelectedPhoneTypeValue(), 1);
              setLocalStorage(UPDATE_PROFILE_PHONE, updateProfilePhoneData);
              Login.mfaOktaLogin(); 
            }
          }
          
          verificationButtons.classList.add('hidden');
          verificationOptions.classList.remove('padding-bottom');
          this.lockPhoneNumberField();
        });
      }
    });
  }

  getSelectedPhoneTypeValue() {
    const phoneTypeIndex = document.querySelector('#phoneType0').selectedIndex;
    const phoneTypes = ['Work', 'Home', 'Mobile', 'Fax', 'None'];
    return phoneTypes[phoneTypeIndex];
  }

  verificationRadioButtonSpacing(radioButtons) {
    radioButtons && radioButtons.classList.add('padding-bottom');
  }

  lockPhoneNumberField() {
    let accountInformationPhoneNumberField = document.querySelector('#panel-1 .locked-phone-input');
    if(accountInformationPhoneNumberField) {
      accountInformationPhoneNumberField.classList.add('isReadOnly');
      this.makePhoneFieldReadOnly(true);
      // trigger country dropdown reset after inputfield is reset
      setTimeout(() => {
        PhoneInput.refreshInputField(0);
        $('#phoneType0').prop("disabled", true);
      }, 100);
    }
  }

  initializePhoneFieldEditButton() {
    let editPhoneNumberField = document.querySelector('#panel-1 .edit-phone-number');
    let accountInformationPhoneNumberField = document.querySelector('#panel-1 .locked-phone-input');
    let accountVerificationOptions = document.querySelector('#panel-1 .verification-options');
    let accountVerificationButtons = document.querySelector('#panel-1 .verification-options .verification-buttons');

    editPhoneNumberField.addEventListener('click', () => {
      accountInformationPhoneNumberField.classList.remove('isReadOnly');
      accountVerificationButtons.classList.remove('hidden');
      accountVerificationOptions.classList.add('padding-bottom');
      $('#phoneType0').prop("disabled", false);
      this.makePhoneFieldReadOnly(false);
      PhoneInput.refreshInputField(0);
      this.initializeRadioButtons();
    });
  }

  initViewPassword() {
    const passwordInput = $('#password'),
      confirmPasswordInput = $('#confirmPassword'),
      passwordEye = passwordInput.siblings('.eye'),
      passwordDashEye = passwordInput.siblings('.dash-eye'),
      confirmPasswordEye = confirmPasswordInput.siblings('.eye'),
      confirmPasswordDashEye = confirmPasswordInput.siblings('.dash-eye');

    this.addToggleViewEventListener(passwordInput, passwordEye, passwordDashEye);
    this.addToggleViewEventListener(confirmPasswordInput, confirmPasswordEye, confirmPasswordDashEye);
  }

  addToggleViewEventListener(input, eye, dashEye) {
    eye.on('click', () => {
      input.attr('type', 'text');
      eye.addClass('hidden');
      dashEye.removeClass('hidden');
    });
    dashEye.on('click', () => {
      input.attr('type', 'password');
      eye.removeClass('hidden');
      dashEye.addClass('hidden');
    });
  }

  initializeRadioButtons() {
    const phoneType = $('#phoneType0'),
      textRadio = $('#textVerificationRadio'),
      voiceRadio = $('#voiceVerificationRadio');
    switch (phoneType.val()) {
    case 'business':
      voiceRadio.prop('checked', true);
      break;
    case 'home':
      voiceRadio.prop('checked', true);
      break;
    case 'mobile':
      textRadio.prop('checked', true);
      break;
    default:
      return;
    }
  }

  updateVerificationOptions() {
    let phoneTypeDropdown = document.getElementById('phoneType0');
    if (phoneTypeDropdown) {
      phoneTypeDropdown.addEventListener('change', () => {
        if (phoneTypeDropdown.value == 'mobile') {
          document.getElementById('textVerificationRadio').click();
        } else if (phoneTypeDropdown.value == 'home' || phoneTypeDropdown.value == 'business') {
          document.getElementById('voiceVerificationRadio').click();
        }
      });
    }
  }

}

export default new MemberInformation();
