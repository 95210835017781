import GoogleAPIHandler from '../../base/google-api-handler.js';
import { getParameterByName, EventHandler } from '../../base/utils.js';

class GeolocationSelection {
  constructor() {
    this.name = 'visa-search-component';
    this.$component = $('.' + this.name);
    this.$locationInput = this.$component.find('.location-input');
    this.$locationButton = this.$component.find('.geolocation-select');
    this.$locationRemove = this.$component.find('.location-remove');
    this.$locationText = this.$locationButton.find('.location-text');
    this.$locationError = this.$locationButton.find('.location-error');
    this.$selectionProperty = this.$component.find('.select-property');
    this.$manualState = this.$component.find('[name="destination-manual"]');
    this.$manualProperty = this.$component.find('.manual-property');
    this.$selectionPropertyButton = this.$selectionProperty.find('.destination-select');
    this.$browseButton = this.$component.find('.browse-locations');
    this.$associateCode = this.$component.find('.associate-code-container');
    this.$associateCodeField = this.$associateCode.find('.associate-field');
    this.$sendButton = this.$component.find('.send-property');
    this.$listContainer = this.$selectionProperty.find('.category-property');
    this.CREDIT_CARD_URL = this.$component.data('credit-url');
    this.CARD_TAG_PATH = this.$component.data('tag-path');

    if (this.$component.length > 0) {
      this.bindGetPosition();
      this.bindClearPosition();
      this.bindBrowseLocation();
      this.bindSelectProperty();
      this.bindFieldCode();
      this.bindFormSubmit();
    }
  }
  bindGetPosition() {
    this.$locationButton.on('click', (e) => {
      e.preventDefault();

      this.clearProperty();
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((location) => this.getUserLocation(location), () => this.showBrowseLocation());
      } else {
        // Go to browser search
        this.showBrowseLocation();
      }
    });
  }
  bindClearPosition() {
    this.$locationRemove.on('click', (e) => {
      e.preventDefault();

      this.clearProperty();
    });
  }
  bindBrowseLocation() {
    let location;

    this.$browseButton.on('click', (e) => {
      e.preventDefault();

      this.showBrowseLocation();
    });

    this.$manualProperty.on('click', '.select-menuitem', (e) => {
      e.preventDefault();

      location = {
        'country': 'US', // Browse location go to US properties
        'state': this.$manualProperty.find('.browse-state').val(),
        'city': ''
      };

      this.$sendButton.attr('disabled', 'disabled');
      this.$selectionPropertyButton.attr('disabled', 'disabled');
      this.$selectionPropertyButton.addClass('empty');
      this.$selectionPropertyButton.find('.select-text').empty();
      this.$listContainer.empty();
      this.populatePropertyData(location);
    });
  }
  bindSelectProperty() {
    this.$selectionProperty.on('click', '.select-menuitem', (e) => {
      e.preventDefault();

      this.$sendButton.removeAttr('disabled');
    });
  }
  bindFieldCode() {
    this.$associateCodeField.on('focus', (e) => {
      e.preventDefault();
      this.limitMaxLength(e);
      if (this.validateAlpha(e.target.value)) {
        this.$associateCodeField.removeClass('has-error');
        if (!(this.$selectionProperty.find('input').val() === '')) {
          this.$sendButton.removeAttr('disabled');
        }
      } else {
        this.$associateCodeField.addClass('has-error');
        this.$sendButton.attr('disabled', 'disabled');
      }
    });

    this.$associateCodeField.on('keyup', (e) => {
      e.preventDefault();
      this.limitMaxLength(e);
      if (this.validateAlpha(e.target.value)) {
        this.$associateCodeField.removeClass('has-error');
        if (!(this.$selectionProperty.find('input').val() === '')) {
          this.$sendButton.removeAttr('disabled');
        }
      } else {
        this.$associateCodeField.addClass('has-error');
        this.$sendButton.attr('disabled', 'disabled');
      }
    });
  }
  limitMaxLength(element) {
    let max_Length = 9;
    if (element.target.value.length > max_Length) {
      element.target.value = element.target.value.substr(0, max_Length);
    }
  }
  bindFormSubmit() {
    let attr,
      cdURL,
      associateCode,
      propertyCode,
      referrerid,
      creditObject,
      propertyCodeModified;

    this.$sendButton.on('click', (e) => {
      e.preventDefault();

      attr = this.$sendButton.attr('disabled');
      if (typeof attr !== typeof undefined && attr !== false) {
        return;
      }

      propertyCode = this.$selectionProperty.find('input').val();
      associateCode = this.$associateCodeField.find('input').val();
      if (this.validateAlpha(associateCode)) {
        referrerid = getParameterByName('cid', window.location.href) || getParameterByName('CID', window.location.href);
        cdURL = this.CREDIT_CARD_URL + '&storenumber=' + propertyCode;
        cdURL += associateCode ? '&employeenumber=' + associateCode : '';
        cdURL += referrerid ? '&referrerid=' + referrerid : '';
        propertyCodeModified = /^[a-zA-Z]+$/.test(propertyCode.substring(2, 0)) ? propertyCode.substring(2) : propertyCode;

        creditObject = {
          'campaignId': getParameterByName('campaignId', this.CREDIT_CARD_URL),
          'cellNumber': getParameterByName('cellNumber', this.CREDIT_CARD_URL),
          'referrerid': getParameterByName('referrerid', this.CREDIT_CARD_URL),
          'propertyCode': propertyCodeModified,
          'associateCode': associateCode,
          'cid': referrerid,
          'city': this.$locationInput.attr('city'),
          'state': this.$locationInput.attr('state-code') ? this.$locationInput.attr('state-code') : this.$manualState.val(),
          'country': this.$locationInput.attr('country-code'),
          'location': this.$locationInput.attr('location'),
          'data': (this.$locationInput.attr('location') ? this.$locationInput.attr('location') : '') + '|' + this.$manualState.val() + '|' + propertyCodeModified + '|' + associateCode
        };

        window.digitalData.event.apply.creditForm = creditObject.data;
        EventHandler.triggerEvent('CC Pre-Application', creditObject);

        $(e.target).attr('href', cdURL);
        window.location.assign(cdURL);

      } else {
        this.$associateCodeField.addClass('has-error');
        this.$associateCodeField.find('input').focus();
      }
    });
  }
  // Populate property dropdown according user location
  populatePropertyData(location) {
    $.ajax({
      url: '/bin/tagInfoHirarchy.json',
      type: 'GET',
      dataType: 'json',
      data: {
        'tagPath': this.CARD_TAG_PATH,
        'country': location.country || 'US',
        'state': location.state,
        'city': location.city
      },
      success: (result) => {
        this.showProperties();
        this.setProperties(result.location);
        if (result.properties) {
          _.each(result.properties, (item, index) => {
            this.$listContainer.append('<li role="menuitemradio" aria-checked="false" tabindex="0" class="select-menuitem" data-value="' + item.name + '">' + item.title + '</li>');
          });
        } else {
          _.each(result.cities, (city) => {
            _.each(city.properties, (item) => {
              this.$listContainer.append('<li role="menuitemradio" aria-checked="false" tabindex="0" class="select-menuitem" data-value="' + item.name + '">' + item.title + '</li>');
            });
          });
        }
      },
      error: (error) => {
        this.$locationError.removeClass('hidden').attr('aria-hidden', 'false');
        this.$locationButton.addClass('has-error');
        console.log('Could not get user location - ' + error.status);
      }
    });
  }
  getUserLocation(position) {
    let location;
    GoogleAPIHandler.getLocationDetailsFromReverseGeoCoding(position.coords.latitude, position.coords.longitude).then((l) => {
      this.$listContainer.empty();
      this.showLocation(l);
      location = {
        'country': l.countryCode || 'US',
        'state': l.countryCode + '_' + l.stateCode,
        'city': '' //result.location.city only state is needed at this moment
      };
      this.populatePropertyData(location);
      $('.select-menu-dropdown:eq(1) input').val('');
    }, () => {
      this.$locationError.removeClass('hidden').attr('aria-hidden', 'false');
      this.$locationButton.addClass('has-error');
      console.log('Could not get user location');
    });
  }
  showLocation(l) {
    this.$locationInput.removeClass('empty');
    this.$locationInput.attr('location', l.location);
    this.$locationInput.attr('city', l.city);
    this.$locationInput.attr('state', l.state);
    this.$locationInput.attr('state-code', l.stateCode);
    this.$locationInput.attr('country', l.country);
    this.$locationInput.attr('country-code', l.countryCode);
    this.$locationInput.val(l.location);
    this.$locationText.text(l.location);
    this.$locationButton.addClass('geolocation-selected');
    this.$locationText.removeClass('hidden').attr('aria-hidden', 'false');
    this.$locationRemove.removeClass('hidden').attr('aria-hidden', 'false');
  }
  showBrowseLocation() {
    this.$component.find('.geolocation-button').addClass('hidden')
      .attr('aria-hidden', 'true');
    this.$component.find('.manual-property').removeClass('hidden')
      .attr('aria-hidden', 'false');
    this.clearProperty();
    this.showProperties();
  }
  setProperties() {
    this.$selectionPropertyButton.removeAttr('disabled');
  }
  showProperties() {
    this.$selectionProperty.removeClass('hidden').attr('aria-hidden', 'false');
    this.$associateCode.removeClass('hidden').attr('aria-hidden', 'false');
    this.$sendButton.removeClass('hidden').attr('aria-hidden', 'false');
    this.$selectionPropertyButton.attr('disabled', 'disabled');
    this.$browseButton.addClass('hidden').attr('aria-hidden', 'true');
  }
  clearProperty() {
    this.$locationInput.val('').addClass('empty');
    this.$locationInput.removeAttr('location city state state-code country country-code');
    this.$locationButton.removeClass('geolocation-selected');
    this.$locationButton.removeClass('has-error');
    this.$locationText.text('');
    this.$locationText.addClass('hidden').attr('aria-hidden', 'true');
    this.$locationError.addClass('hidden').attr('aria-hidden', 'true');
    this.$locationRemove.addClass('hidden').attr('aria-hidden', 'true');
    this.$selectionProperty.addClass('hidden').attr('aria-hidden', 'true');
    this.$associateCode.addClass('hidden').attr('aria-hidden', 'true');
    this.$sendButton.addClass('hidden').attr('aria-hidden', 'true');
    this.$listContainer.empty();
    this.$selectionPropertyButton.addClass('empty');
    this.$selectionPropertyButton.find('.select-text').empty();
    this.$selectionPropertyButton.removeAttr('disabled');
    this.$browseButton.removeClass('hidden').attr('aria-hidden', 'false');
    this.$associateCodeField.removeClass('has-error');
    this.$sendButton.attr('disabled', 'disabled');
  }
  validateAlpha(text) {
    let Exp = /^[a-z0-9]+$/i;

    if (text.length > 0) {
      return text.match(Exp);
    }

    return true;
  }
}

export default new GeolocationSelection();
