import { getUrlQuery } from '../../base/utils.js';

class DeepLink {
  constructor() {
    this.handleDeepLink(document.location.hash);
    this.handleSuperDeepLink();
  }
  handleDeepLink(urlHash) {
    if (urlHash) {
      let hash = urlHash.split('?')[0];
      if ($(hash).length > 0) {
        $('.nav-tabs a[href="' + hash + '"]').tab('show');
      }
    }
  }
  handleSuperDeepLink() {
    let queryObj = getUrlQuery();
    for (let key in queryObj) {
      if (key !== '') {
        let $el = $('[sdl=' + key + ']');
        $el.val(queryObj[key]).change();
      }
    }
  }
}

export default new DeepLink();
