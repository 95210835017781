import Cookie from '../base/cookie.js';
import DataBinding from '../base/data-binding.js';
import Form from '../base/form.js';
import Spinner from './spinner.js';
import { _REGION_, _SEO_LOCALE_, isPublish } from '../../base/vars.js';
import { getLevel, hasMilitaryStatus } from '../base/status.js';
import { isMemberAttr } from '../../base/utils.js';

class TalkToUs {
  constructor() {
    this.name = 'talk-to-us';
    this.level = this.getUpdatedLevel();
    this.isMilitaryAttr;
    this._component = $('.talk-to-us');
    this._dropdown = $('#regionSelect');
    this._memberServices = new Map();
    if (this._dropdown.length && typeof isPublish !== 'undefined' && isPublish) {
      this.bindEvents();
      if (!Cookie.isAuthenticated()) {
        let country = this.getCountryFromLocale();
        this._dropdown.val(country).change();
      }
      this.getMemberAttributeMilitary();
    }
  }
  bindEvents() {
    this._dropdown.change(() => this.updateMemberServicesInfo(this._dropdown.val()));
    $(document).on('user:profile:update', () => this.updateMemberServicesWithUserInfo());
  }
  updateMemberServicesWithUserInfo() {
    if (typeof window.User !== 'undefined' && window.User && window.User.Customer && window.User.addresses) {
      for (let address of window.User.addresses) {
        if (address.DefaultInd && $('option[value="' + address.CountryCode + '"]').length) {
          this._dropdown.val(address.CountryCode).change();
          return;
        }
      }

      let country = this.getCountryFromLocale();
      this._dropdown.val(country).change();
    }
  }
  updateMemberServicesInfo(country) {
    let selected = this._dropdown.find('option[value="' + country + '"]');
    if (!selected.length) {
      selected = this._dropdown.find('option[value="US"]');
    }
    const hours = selected.data('hours');
    $('.service-hours').html(hours);
    // re-integrate 8/15/23
    this.getPhoneNumber(country);
  }
  getPhoneNumber(country) {
    let memberLevel = 'Blue',
      military = '';
    if (typeof window.User !== 'undefined' && window.User && window.User.CustLoyalty) {
      memberLevel = window.DataBinding.UserLoyalty.Channel.WR.LoyaltyLevel;
    }
    if(typeof window.User !== 'undefined' && window.User && window.User.CustLoyalty){
      military = hasMilitaryStatus() ? 'military' : '';
    }
    if (this._memberServices.has(country)) {
      this.populateMemberServicesNumber(this._memberServices.get(country));
    } else {
      Spinner.appendTo(this._component);
      Form.disableFormField(this._dropdown);
      DataBinding.getMemberServicesNumber(memberLevel, country, military)
        .done((data) => {
          this._memberServices.set(country, data.data);
          this.populateMemberServicesNumber(data.data);
        })
        .always(() => {
          Form.enableFormField(this._dropdown);
          Spinner.remove(this._component);
        });
    }
  }
  populateMemberServicesNumber(telephones) {
    const anchors = this._component.find('.member-services-tel');
    if(telephones.length > 0 && $(anchors).length > 0){
      let tag = '';
      for(let i = 0; i < telephones.length;i++){
        let telNum = 'tel:' + telephones[i].phoneNumber;
        tag += "<a href=" + telNum + ">" + (telephones[i].description || telephones[i].phoneNumber);
        tag += '</a>';
      }
      $(anchors).replaceWith('<div class=\'member-services-tel\'>' + tag + '</div>');
    }
  }

  handleMemberLevelNotification() {
    let snMemberLevel = this._component.find('.user-memberlevel'),
      snMemberLevelTitle = this._component.find('.member-label-title'),
      snMemberLevelLabel = snMemberLevel.text(),
      militaryInfoLabel = snMemberLevelTitle.data('military-attribute-label');

    switch(this.level && this.level.toLowerCase()) {
    case 'blue':
      if (this.isMilitaryAttr) {
        snMemberLevel.text(militaryInfoLabel);
        snMemberLevelTitle.toggleClass('member-level-color').toggleClass('member-level-color-military');
      }
      break;
    case 'gold':
      if (this.isMilitaryAttr) {
        snMemberLevel.text(militaryInfoLabel);
        snMemberLevelTitle.toggleClass('member-level-color').toggleClass('member-level-color-military');
      }
      break;
    case 'platinum':
      snMemberLevel.text(snMemberLevelLabel);
      snMemberLevelTitle.toggleClass('member-level-color-military').toggleClass('member-level-color');
      break;
    case 'diamond':
      snMemberLevel.text(snMemberLevelLabel);
      snMemberLevelTitle.toggleClass('member-level-color-military').toggleClass('member-level-color');
      break;
    case 'titanium':
      snMemberLevel.text(snMemberLevelLabel);
      snMemberLevelTitle.toggleClass('member-level-color-military').toggleClass('member-level-color');
      break;
    default:
      snMemberLevel.text(snMemberLevelLabel);
      snMemberLevelTitle.toggleClass('member-level-color-military').toggleClass('member-level-color');
    }
  }

  getUpdatedLevel() {
    return window.User && getLevel().toLowerCase();
  }

  getMemberAttributeMilitary() {
    $(document).on('user:profile:updated', (e) => {
      this.level = this.getUpdatedLevel();
      this._memberServices = new Map();
      let $memberAttribute = $('.member-attribute[data-condition]');

      $memberAttribute.each((_index, elem) => {
        let condition = elem.dataset.condition.split(':'),
        prop = condition[0],
        query = condition[1] ? condition[1] : '';

        if (window.User && query === 'MIL') {
          if (isMemberAttr(window.User, prop, query)) {
            this.isMilitaryAttr = true;
            this.handleMemberLevelNotification();
          } else {
            this.isMilitaryAttr = false;
          }
        }
      });
    });
  }

  getCountryFromLocale() {
    // Fallback for country not found in dropdown
    let country = 'US';
    if (typeof _SEO_LOCALE_ !== 'undefined' && _SEO_LOCALE_) {
      if (_SEO_LOCALE_ === 'es-xl' && $('option[value="MX"]').length) {
        country = 'MX';
      } else if (_SEO_LOCALE_ === 'en-ap' && $('option[value="AU"]').length) {
        country = 'AU';
      } else if (_SEO_LOCALE_ === 'en-uk' && $('option[value="GB"]').length) {
        country = 'GB';
      } else if (typeof _REGION_ !== 'undefined' && _REGION_ && $('option[value="' + _REGION_.toUpperCase() + '"]').length) {
        country = _REGION_.toUpperCase();
      }
    }
    return country;
  }
}

export default new TalkToUs();
