import ContactUsForm from './contact-us-form.js';
import DateFields from './date-fields.js';
import { isPublish } from '../../base/vars.js';

class SelectDropdownSearch {
  constructor() {
    this._dropdown = $('#contact-us-select');
    if (this._dropdown.length) {
      this._formFragmentContainer = $('#contact-us-part-two');
      this._contactUsForm = new ContactUsForm($('#contactUs'));
      this.registerEvents();
      this.updateFormFragment(this._dropdown.val());
    }
  }
  registerEvents() {
    this._dropdown.change(() => {
      this.updateFormFragment(this._dropdown.val());
      this._contactUsForm.resetFormView();
      this._contactUsForm.scrollToDropdown();
    });
  }
  updateFormFragment(path) {
    if (typeof isPublish === 'undefined' || !isPublish) {
      return;
    }
    this.disableDropdown();
    this._contactUsForm.destroyForm();
    this._contactUsForm.disableForm();
    $.get(path + '.main.html')
      .done((data) => {
        this._formFragmentContainer.html(data);
      })
      .fail(() => {
        console.log('Request failed');
      })
      .always(() => {
        this.enableDropdown();
        this._contactUsForm.refreshForm();
        this._contactUsForm.enableForm();
        DateFields.init();
      });
  }
  disableDropdown() {
    this._dropdown.prop('disabled', true);
  }
  enableDropdown() {
    this._dropdown.prop('disabled', false);
  }
}

export default new SelectDropdownSearch();
