import {
  exists
} from '../../base/dom-utils.js';
class Wifi {
  constructor() {
    this.appendQueryParams();
  }
  appendQueryParams() {
	if (exists('.wifi-component')) {
		$(".wifi-component a.btn-secondary, .wifi-source").each(function() {
			let href = $(this).attr("href");
			let siteId = $(".wifi-component").data("siteid");
			
			if(href.indexOf("?") > -1){
				$(this).attr("href", href + "&enrollmentSource=WIFI&siteId=" + siteId);
			} else {
				$(this).attr("href", href + "?enrollmentSource=WIFI&siteId=" + siteId);
			}
		});
	}
  }
  
}

export default new Wifi();
