
import { mapTierCode } from '../../base/utils.js';
import Spinner from './spinner.js';

class MemberInformationIntroduction {
  constructor() {
    this.$component = $('.member-information-introduction-container');

    if (this.$component.length > 0) {
      this.onUserUpdate();
    }

  }

  onUserUpdate() {
    let $wrapper = this.$component.find('.member-information-wrapper'),
      $notificationTxt = this.$component.find('.member-information-introduction-message'),
      $acceleratorMsg = this.$component.find('.member-information-accelerator-message'),
      $accExpDate = $acceleratorMsg.find('.accelerator-expiration-date'),
      expirationFormatted;

    if ($accExpDate.length > 0 &&
      expirationFormatted && expirationFormatted.length) {
      $accExpDate.text(expirationFormatted);
    }

    Spinner.appendTo($wrapper);

    $(document).on('user:profile:updated', function() {
      const tier = window.User.currentTier.description || mapTierCode(window.User.currentTier.tierCode);
      if (window.User.loyaltyLevelProgress &&
        window.User.loyaltyLevelProgress.accelerators &&
        window.User.loyaltyLevelProgress.accelerators.length > 0 &&
        window.User.loyaltyLevelProgress.accelerators[0].expirationDate != '') {
        $notificationTxt.addClass('hidden');
        $acceleratorMsg.removeClass('hidden');
      } else {
        $notificationTxt.removeClass('hidden');
        $acceleratorMsg.addClass('hidden');
      }

      if (tier.toLowerCase() === 'diamond') {
        $notificationTxt.addClass('hidden');
      }
      Spinner.remove($wrapper);
    });
  }
}

export default new MemberInformationIntroduction();
