import { scrollToElement } from '../base/animate.js';
import Cookie from '../base/cookie.js';
import Form from '../base/form.js';
import Modal from './modal.js';
import { getParameterByName, EventHandler } from '../../base/utils.js';
import Spinner from './spinner.js';
import { requestUrls } from '../../base/vars.js';
import { formatDateForPrinting } from '../../base/utils.js';

class OffersFlow {
  constructor() {
    this.name = 'offers-flow';
    if ($('.offers-flow').length) {
      this._hasOneclicked = false;
      this._spinnerElm = $('.cards-container');
      this._errorElm = $('.form-error');
      this._successOpts = {
        fadeOutDuration: 0,
        fadeInDuration: 0
      };
      if (Cookie.isAuthenticated()) {
        this.checkPromoStatus();
      } else {
        this.oneClickEnroll();
      }
      this.bindEvents();
    }
  }

  checkPromoStatus() {
    this._errorElm.hide();
    Spinner.appendTo(this._spinnerElm, true, true);
    $.ajax({
      type: 'get',
      url: requestUrls.getPromotions,
      contentType: 'application/json'
    }).done((data) => {
      var promotionCodes = _.pluck(data.data, 'promotionCode') || []; // DAI-35744: code replaced with promotionCode
      let useStartwith = $('.offers-flow').filter((index, element) => { 
        return $(element).attr('data-startwith') == 'true'; 
      }).length > 0;
      // DAI-37830
      if (useStartwith && promotionCodes.length > 0) {
        let promoCode = $('a[href="#register"]').data('promo-code');
        let result = promotionCodes.filter(str => str.startsWith(promoCode));
        let now = new Date();
        now.setDate(now.getDate() + 1);
        let closestMatch = data.data
          .filter(item => result.includes(item.promotionCode) && new Date(item.registerStartDateTime) <= now)
          .sort((a, b) => {
            let regA = a.registered === true,
              regB = b.registered === true;
            if (regA !== regB) {
              return regA ? -1 : 1;
            }
            let dateA = new Date(a.registerStartDateTime), 
              dateB = new Date(b.registerStartDateTime);
            if (dateA.getTime() !== dateB.getTime()) {
              return dateB - dateA;
            }
            return a.promotionCode.localeCompare(b.promotionCode);
          })[0];
        if (closestMatch && closestMatch.promotionCode) {
          $('a[href="#register"]').attr('data-promo-code', closestMatch.promotionCode);
          $( "span[class^='endDate']" ).each( function(i,e) {
            if(closestMatch.registerEndDateTime) { 
              let endDate = formatDateForPrinting(closestMatch.registerEndDateTime, 'weekdayCompact'); 
              $(e).text(endDate.split(", ")[1]);
            }
          });
          $('.title-2.headline-a').text(closestMatch.promotionCode);
        }
      } else {
        $( "span[class^='endDate']" ).each( function(i,e) {
          let promoCode = $(e).attr('class').split("endDate");
          if (_.contains(promotionCodes,promoCode[1])){
            let promotion = _.first(_.where(data.data, {'promotionCode': promoCode[1]})); // DAI-35744: code replaced with promotionCode
            if(promotion.registerEndDateTime) { // DAI-35744: endDate replaced with registerEndDateTime
              let endDate = formatDateForPrinting(promotion.registerEndDateTime, 'weekdayCompact'); // DAI-35744: endDate replaced with registerEndDateTime
              $(e).text(endDate.split(", ")[1]);
            }
          }
        });
      }
      if (this.isRegistered(data.data)) {
        Form.handleSuccessContainer(this._successOpts);
      } else {
        this.oneClickEnroll();
      }
    })
      .always(() => {
        Spinner.remove(this._spinnerElm);
      });
  }

  oneClickEnroll() {
    if (!this._hasOneclicked) {
      this._hasOneclicked = true;
      const token = this.getOneClickToken();
      if (typeof token !== 'undefined' && token) {
        this.enroll(this._spinnerElm, this._errorElm, token, this._successOpts, $('.offers-flow .text-offer-card'), requestUrls.registerPromotionToken);
      }
    }
  }

  getOneClickToken() {
    const token = getParameterByName('token');
    if (typeof token !== 'undefined' && token) {
      return {
        token: token
      };
    }
  }

  isRegistered(promotions) {
    const promoCode = $('a[href="#register"]').attr('data-promo-code');
    for (let promotion of promotions) {
      // DAI-35744: code replaced with promotionCode and memberRegistered to registered
      if (promotion.promotionCode === promoCode && promotion.registered) {
        return true;
      }
    }
    return false;
  }

  reset() {
    this._errorElm.hide();
    $('.successful-action-container').hide();
    $('.standard-state-container').show();
    $(document).one('user:profile:updated', () => this.checkPromoStatus());
  }

  bindEvents() {
    $(document).on('click', 'a[disabled]', (e) => e.preventDefault())
      .one('user:profile:updated', () => this.checkPromoStatus())
      .on('user:profile:logout', () => this.reset());
    $(document).on('click', 'a[href*="#register"]:not([disabled])', (e) => this.submitEnrollment(e));
  }

  submitEnrollment(e) {
    e.preventDefault();
    $(e.target).attr('disabled', true);
    // DAI-35745 : Tally BWS - Register for Promotion
    let accountNumber = '';
    if(window.User.accountNumber) {
      accountNumber = window.User.accountNumber;
    } else if (window.User && window.User.membershipId) {
      accountNumber = window.User.membershipId;
    } else {
      console.error('AccountNumber not found in user or user sessionInfo while calling registerPromotion api');
    }

    const data = {
      promotionCode: $(e.target).attr('data-promo-code'),
      memberIdentifier: accountNumber  
    };
    this._successOpts.isMember = true;
    if($(e.target).attr('data-promo-code')) {
      this.enroll(this._spinnerElm, this._errorElm, data, this._successOpts, $('.offers-flow .text-offer-card'), requestUrls.registerPromotion);
    }
  }

  enroll(el, errElm, data, opts, formEl, enrollUrl) {
    if (errElm) {
      errElm.hide();
    }
    if (!enrollUrl) {
      enrollUrl = requestUrls.registerPromotion;
    }
    Spinner.appendTo(el, true, true);
    $.ajax({
      type: 'post',
      url: enrollUrl,
      data: JSON.stringify(data),
      contentType: 'application/json',
      dataType: 'json',
      context: {
        onFail: {
          formElement: formEl,
          errorElement: errElm,
          scrollToElement: $('.text-offer-card:visible').parents('.offers-flow'),
          scrollOptions: {
            'offset': 0
          }
        }
      }
    }).done(() => {
      EventHandler.triggerEvent('rewards-offer_success', {
        wyndhamRewardsID: $('#memberNumber').val()
      });
      scrollToElement($('.text-offer-card:visible').parents('.offers-flow'));
      Modal.close();
      Form.handleSuccessContainer(opts);
    })
      .always(() => {
        Spinner.remove(el);
      });
  }
}

export default new OffersFlow();
