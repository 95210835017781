
import { getLevel } from '../base/status.js';

class MemberLevelPerks {
  constructor() {
    this.$container = $('.member-level-perk-container');
    if (this.$container.length > 0) {
      this.level = this.getUpdatedLevel();
      this.handleAltBgColors();
      this.$container.removeClass('hidden');
      this.handleProfileUpdate();
    }
  }
  handleProfileUpdate() {
    $(document).on('user:profile:updated', () => {
      if (this.level !== 'titanium') {
        this.level = this.getUpdatedLevel();
        if (this.level === 'titanium') {
          this.handleAltBgColors();
        }
      }
    });
  }
  handleAltBgColors() {
    let $listings = this.level === 'titanium' ? this.$container.find('.member-level-perk-listing') : this.$container.find('.member-level-perk-listing:not(".titanium-only-listing")');

    $listings.removeClass('bg-tr-alt');
    $.each($listings, (i, v) => {
      if (i % 2 == 0) {
        $(v).addClass('bg-tr-alt');
      }
    });
  }
  getUpdatedLevel() {
    return window.User && getLevel().toLowerCase();
  }
}

export default new MemberLevelPerks();
