import {
  formatNumber
} from '../../base/utils.js';
import Analytics from '../../base/wyn-analytics-module.js';

class GoFastGoFreeAccrued {
  constructor() {
    if ($('.gofast-gofree-accrued-component').length > 0) {
      $(document).on('user:profile:updated', () => {
        if (window.User.currencies && window.User.currencies.available) {
          this.memberPointBalance = window.User.currencies.available || 0;
          this.populateAccruedRewards();
        } else {
          Analytics.updateRewardLockStatus('go fast:locked|go free:locked');
        }
      });
    }
  }

  populateAccruedRewards() {
    let goFreeStatus = $('.gofree-accrued'),
      goFastStatus = $('.gofast-accrued'),
      freeVal = $('.gofast-gofree-accrued-component').data('go-free'),
      fastVal = $('.gofast-gofree-accrued-component').data('go-fast'),
      freePts = typeof freeVal === 'string' ? parseFloat(freeVal.replace(/,/g, '')) : freeVal || 15000,
      fastPts = typeof fastVal === 'string' ? parseFloat(fastVal.replace(/,/g, '')) : fastVal || 3000,
      freeStatus = 'locked',
      fastStatus = 'locked';

    // GO FREE LOGIC
    if (this.memberPointBalance >= freePts) {
      this.unlockRewards(goFreeStatus, freePts);
      freeStatus = 'unlocked';
    } else if (this.memberPointBalance > 0) {
      goFreeStatus.find('.standard-message').hide();
      goFreeStatus.find('.points-away-message .points-away').html(formatNumber(freePts - this.memberPointBalance));
      goFreeStatus.find('.points-away-message').show();
    }
    // GO FAST LOGIC
    if (this.memberPointBalance >= fastPts) {
      this.unlockRewards(goFastStatus, fastPts);
      fastStatus = 'unlocked';
    } else {
      goFastStatus.find('.standard-message .points-away').html(formatNumber(fastPts - this.memberPointBalance));
    }

    Analytics.updateRewardLockStatus('go fast:' + fastStatus + '|' + 'go free:' + freeStatus);
  }

  unlockRewards($container, awardBasis) {
    let awardCount = Math.floor(this.memberPointBalance / awardBasis);
    $container.find('.standard-message').hide();
    $container.find('.unlocked-message .free-awards').html(formatNumber(awardCount));
    $container.find('.unlocked-message').show();
    $container.find('.reward-lock-unlock-icon').addClass('unlocked');
    $container.find('.reward-lock-unlock-icon .status-icon').html(formatNumber(awardCount));
  }
}

export default new GoFastGoFreeAccrued();
