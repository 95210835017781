import Form from '../base/form.js';
import SecuritySetup from './security-setup.js';
import Spinner from './spinner.js';
import UserHandler from '../base/user.js';
import { MASKED_PASSWORD_PLACEHOLDER, requestUrls } from '../../base/vars.js';

// MY PROFILE USERNAME/PASSWORD AND SECURITY QUESTIONS SETUP

class SecurityQuestionsForm {
  constructor() {
    this.formSelector = '#securityUpdateQuestion';
    this.$form = $(this.formSelector);

    if (this.$form.length > 0) {
      this.$form.show();
      this.init();
    }
  }

  init() {
    this.reset();
    this.onQuestionChange();
    this.onUserUpdated();
    this.getSecurityQuestions();
    this.formInit();
  }

  reset() {
    this.$form.find('.security-answer').val(MASKED_PASSWORD_PLACEHOLDER)
      .removeClass('empty');
    Form.disableFormField(this.$form.find('.submit'));
  }

  onQuestionChange() {
    $(document).on('change', '#securityUpdateQuestion .security-question', (e) => {
      let questionId = $(e.target).attr('id'),
        $answer = this.$form.find('#answer' + questionId.split('question')[1]);

      $answer.val(MASKED_PASSWORD_PLACEHOLDER).trigger('input');
      SecuritySetup.updateAvailableOptions(this.$form);
    });
  }

  onUserUpdated() {
    $(document).on('user:profile:updated', () => {
      this.selectSecurityQuestions();
    });
  }

  getSecurityQuestions() {
    if (requestUrls['getSecurityQuestions']) {
      $.ajax({
        url: requestUrls['getSecurityQuestions'],
        contentType: 'application/json',
        context: {
          onFail: {
            formElement: this.$form
          }
        },
        success: (response) => {
          if (response.data) {
            SecuritySetup.createSelectOptions(this.$form, response.data);
            this.selectSecurityQuestions();
            Form.disableFormField(this.$form.find('.submit'));
          }
        }
      });
    } else {
      console.log('no security question url authored');
    }
  }

  selectSecurityQuestions() {
    if (window.User && window.User.SecurityInfo && window.User.SecurityInfo.SecurityQuestions) {
      let questions = window.User.SecurityInfo.SecurityQuestions;

      $.each(questions, (i, e) => {
        if ($('#question' + (i)).val() != e.id.toString()) {
          $('#question' + (i)).val(e.id)
            .change();
        }
      });
    }
  }

  formInit() {
    this.$form
      .on('init.form.fv', (e, data) => {
        Form.removeFormError($(e.target));
      })
      .formValidation({
        button: {
          selector: '.submit',
          disabled: ''
        },
        live: 'submitted',
        fields: {
          question0: {
            validators: {
              notEmpty: {
                enabled: true
              }
            }
          },
          question1: {
            validators: {
              notEmpty: {
                enabled: true
              }
            }
          },
          question2: {
            validators: {
              notEmpty: {
                enabled: true
              }
            }
          },
          answer0: {
            validators: {
              stringLength: {
                max: 200
              },
              notEmpty: {
                enabled: false
              },
              callback: {
                callback: (val, validator, $field) => this.securityAnswerValidation(val, validator, $field)
              }
            }
          },
          answer1: {
            validators: {
              stringLength: {
                max: 200
              },
              notEmpty: {
                enabled: false
              },
              callback: {
                callback: (val, validator, $field) => this.securityAnswerValidation(val, validator, $field)
              }
            }
          },
          answer2: {
            validators: {
              stringLength: {
                max: 200
              },
              notEmpty: {
                enabled: false
              },
              callback: {
                callback: (val, validator, $field) => this.securityAnswerValidation(val, validator, $field)
              }
            }
          }
        }
      })
      .on('success.form.fv', (e) => {
        e.preventDefault();
        let questions = this.createQuestionsData();
        this.postForm(questions);
      })
      .on('err.field.fv', Form.handleMultipleErrorLabels);
  }

  securityAnswerValidation(value, validator, $field) {
    let answerId = $field.attr('id'),
      index = answerId.split('answer')[1],
      questionId = this.$form.find('#question' + index).val(),
      isDifferentQuestion = window.User.SecurityInfo.SecurityQuestions[index].id !== parseInt(questionId),
      valid = true;

    if ((value === MASKED_PASSWORD_PLACEHOLDER && isDifferentQuestion) || (value == null || value === '')) {
      valid = false;
    }

    return valid;
  }

  createQuestionsData() {
    let questions = [],
      data = {},
      selects = this.$form.find('select');

    $.each(selects, (i, e) => {
      let question = {},
        answerIndex = i,
        answer = $(`#answer${answerIndex}`),
        answerVal = $(answer).val();

      question['id'] = e.value;
      question['question'] = e.options[e.selectedIndex].text;
      question['answer'] = answerVal === MASKED_PASSWORD_PLACEHOLDER ? null : answerVal;

      questions.push(question);
    });

    data = {
      'UpdateSecurityQuestion': true,
      'SecurityInfo': {
        'SecurityQuestions': questions
      }
    };

    return data;
  }

  postForm(questions) {
    if (questions) {
      Spinner.appendTo(this.$form);
      Form.disableFormField(this.$form.find('.submit'));
      Form.disableFormField(this.$form.find('.cancel'));

      let data = JSON.stringify(questions);

      $.ajax({
        type: 'POST',
        contentType: 'application/json',
        url: requestUrls['updateProfile'],
        data: data,
        context: {
          onFail: {
            scrollToElement: this.$form,
            formElement: this.$form
          }
        },
        success: () => {
          Spinner.remove(this.$form);
          Form.showSuccessMessage(this.$form);
          UserHandler.getProfile();
          this.reset();
        },
        complete: () => {
          Form.enableFormField(this.$form.find('.cancel'));
        }
      });
    }
  }
}

export default new SecurityQuestionsForm();
